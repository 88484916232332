/**************************************************************************************************
    FileName  : GroupSpaceHomePage.tsx
    Description

    Update History      
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./GroupSpaceHomePage.scss";
// Import for React
import { useEffect, useState } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Box, Typography, Tabs, Tab, Grid
} from "@mui/material";
// Import for Project Types
import { EnumOpenSpace, SpaceInfo, EnumGroupSpaceSpecialTabName, PostListItemData, EnumSpaceType } from "types";
// Import for Project Base
import { api, appNavigator, DOMAIN, pathTracker, shsAssert } from "libs/stdlib";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PostList from "components/PostList";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpaceHome Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface GroupSpaceHomePageProps  {
    spaceName? : string;
}


function GroupSpaceHomePage(props : GroupSpaceHomePageProps) {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const urlParams = useParams();
    let spaceName : string = EnumOpenSpace.spacehub;
    if( props.spaceName ) {
        spaceName = props.spaceName;
    } else if( urlParams.spaceName ) {
        spaceName = urlParams.spaceName;
    }
    const [spaceInfo, setSpaceInfo] = useState<SpaceInfo | null>(null);
    const [activeTab, ] = useState<string>(EnumGroupSpaceSpecialTabName.home);
    
    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_getSpaceInfo() {        
        await api.get(
            `/api/spaces/${spaceName}`, 
            (data : SpaceInfo)=>{  
                console.info("space info>>>", data);
                setSpaceInfo(data);                                
                pathTracker.buildStart().add(data.displayName).build();
            }
        )
    }

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        (async ()=>{
            await api_getSpaceInfo();
        })();        
        //eslint-disable-next-line
    }, [spaceName])

    if( spaceInfo === null )
        return <></>;

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function onTabChange (_event: React.SyntheticEvent, newValue: string) {
        if( isSpecialTab(newValue) === true ) {        
            switch(newValue) {
                case EnumGroupSpaceSpecialTabName.diablo4Trade:
                    appNavigator.diablo4.goTradeHome();
                    break;
                default:
                    shsAssert(false);
                    break;
            }
        } else {            
            shsAssert(spaceInfo!==undefined);
            goPostThread(spaceName, spaceInfo!.displayName, newValue);            
        }
    };

    function isSpecialTab(name : string) : boolean {
        const values = Object.values(EnumGroupSpaceSpecialTabName) as string[];        
        return values.includes(name);
    }


    function onNotifyPostDetailClick(post : PostListItemData) {
        console.info("Post>>>>", post);
        shsAssert(post.threadInfo!==undefined);        
        goPostThread(spaceName, post.threadInfo!.spaceDisplayName, post.threadInfo!.name );
    }

    function goPostThread(spaceName : string, spaceDisplayName : string, threadName : string) {
        pathTracker.buildStart()
                .add(spaceDisplayName, ()=> appNavigator.goGroupSpaceHome(spaceName) )
                .add(threadName === spaceName ? "일반게시판" : threadName)
                .build();
        appNavigator.goPostThread(EnumSpaceType.group, spaceName,  threadName);
    }

    

    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="groupSpaceHomePage-230619">
            <Box className="space-info-container">
                <img className="banner" src={DOMAIN + spaceInfo.banner.md} alt="banner"></img>
                <Box className="banner-overlay"></Box>
                <Stack className="space-name-panel">
                    <img className="space-thumbnail" src={DOMAIN  + spaceInfo.thumbnail.md} alt="thumbnail"></img>
                    <Typography className="space-name" variant="h3">{spaceInfo.displayName}</Typography>
                </Stack>                
            </Box>
            <Tabs value={activeTab} onChange={onTabChange}>
                <Tab label="홈" value={EnumGroupSpaceSpecialTabName.home} />
                <Tab label="일반게시판" value={spaceName} />
            </Tabs>
            

            {/* Begin content-container *************************************/}
            <Grid className="content-container" container spacing={2}>
                {/* <Grid className="contents-column introduce-column" item xs={4}>
                    <Card className="contents-card introduce" variant="outlined">
                        <CardContent className="introduce-card">
                            <Typography className="section-category" color="text.secondary" gutterBottom> 소개 </Typography>
                            <pre className="section-content">{spaceInfo.introduce}</pre>
                        </CardContent>

                        <CardContent>
                            <Typography className="section-category" color="text.secondary" gutterBottom> 공지 </Typography>                            
                            <Stack className="html-contents">                                
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid className="contents-column list-column" item xs={4}>
                    <Card className="contents-card" variant="outlined">
                        <CardContent className="home-section-card-contents">
                            <Typography className="section-category" color="text.secondary" gutterBottom> 일반게시판 </Typography>
                            <Box className="thread-container">
                                <PostList threadName={spaceName} notifyGoDetailPageClick={onNotifyPostDetailClick}/>
                            </Box>
                        </CardContent>
                    </Card>                    
                </Grid>
                <Grid className="contents-column" item xs={4}>                    
                </Grid>
            </Grid>
            {/* End  content-container **************************************/}


        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default GroupSpaceHomePage;
  