// [[%!!SHS>{"messageType":"DIABLO4_BUY_TRADE_ITEM","buyerBadgeId":1,"priceCurrencyAmount":777777}<SHS!!%]]
// [[%!!SHS>{'type' : 'inline-url', 'url' : 'www.daum.net', 'text' : '다음'}<SHS!!%]]

const REGULAR_PREFIX = /\[\[%!!SHS>/;
const REGULAR_POSTFIX = /<SHS!!%]]/;

const START_SYMBOL = '[[%!!SHS>';
const END_SYMBOL = '<SHS!!%]';

export enum SHSParserType {
	plainText = 'plain-text',
	image = 'image',
	images = 'images',
	linkPreview = 'link-preview',
	linkInline = 'link-inline',
	file = 'file',
	files = 'files',
	sharedMessage = 'shared-message',
	video = 'video',
	videoUrl = 'video-url',
	youtubeEmbed = 'youtube-embed',
	system = "system"
}

export enum SHSParserSubTypeDiablo4 {
	buyTradeItem = "DIABLO4_BUY_TRADE_ITEM"
}

export type SHSParserSubType = SHSParserSubTypeDiablo4;
export interface SHSParserData {
	type: SHSParserType;
	subType? : SHSParserSubType;
	content: object | string;	
}


class SHSParser {
	stringify(parserable: SHSParserData): string {
		return `${START_SYMBOL}${JSON.stringify(parserable)}${END_SYMBOL}`;
	}

	parse(origin: string): SHSParserData[] {
		function splitComponentAndPlainText( org: string, out: SHSParserData[] ) {
		const parts = org.split(REGULAR_POSTFIX);

		// type check
		out.push(JSON.parse(parts[0]));

		if (parts.length === 2) {
			addPlainText(parts[1], out);
		}
		}

		function addPlainText(text: string, out: SHSParserData[]) {
		if (!text) return;

		out.push({
			type: SHSParserType.plainText,
			content: text
		});
		}

		const result: SHSParserData[] = [];

		const strings = origin.split(REGULAR_PREFIX);

		if (strings.length === 0) return result;

		// first sentence is start plain text or not
		if (strings[0].match(REGULAR_POSTFIX)) {
		splitComponentAndPlainText(strings[0], result);
		} else {
		addPlainText(strings[0], result);
		}

		// from second sentence, it compose to spacehub text componet and planin text
		for(let i = 1; i < strings.length; ++i ) {
		splitComponentAndPlainText(strings[i], result);
		}

		return result;
	}
}

const _instance = new SHSParser();
export default _instance;