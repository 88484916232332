/**************************************************************************************************
    FileName  : postRouter.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import PostEditPage from "./PostEditPage";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////  
const routes = [
    {
        path: "/posts",        
        children : [
            {
                path: ":postId/edit",
                element: <PostEditPage/>,
            },
        ]
    }, 
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;