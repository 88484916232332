/**************************************************************************************************
    FileName  : apiCommon.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import UserAPI from "./UserAPI";
import PostAPI from "./PostAPI";
import Diablo4API from "./Diablo4API";

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
const apiCommon = {
    user : new UserAPI(),
    post : new PostAPI(),
    diablo4 : new Diablo4API()
};


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default apiCommon;
