/**************************************************************************************************
    FileName  : InfinityList.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

// 해당 컴포넌트는 라이브러리이므로 중복제거를 위해 css를 사용하지 않는다. 
import { CSSProperties } from "react";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

// No Optimization
export interface InfinityListData<T> {
    lastItemIdInList: number,
    isLast: boolean,
    list: T[]
}

interface InfinityListProp<T> {
    listData?: InfinityListData<T>;
    item: (item: T) => JSX.Element;
    listStyle?: CSSProperties;
    className?: string;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

// TODO type check
// eslint-disable-next-line
export default function InfinityList(props: InfinityListProp<any>) {    
    if (props.listData === undefined)
        return <></>;
    
    return (
        <div style={props.listStyle} className={props.className}>
            {props.listData.list.map(props.item)}            
        </div>
    );
}
