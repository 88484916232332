/**************************************************************************************************
    FileName  : SpacePostWritePage.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./SpacePostWritePage.scss";
// Import for React
import { useState, useRef } from "react";
// Import for Material UI
import {
    Stack, Button, TextField, Typography
} from "@mui/material";
// Import for Project Types
import { EnumOpenThread } from "types";
// Import for Project Base
import { api, appToast, utils, appNavigator } from "libs/stdlib";

import SHSTextEditor, {TextEditorRefType} from "components/SHSText/SHSTextEditor";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpacePostWritePage Implementation                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface SpacePostWritePageProps {
    spaceName? : string;

}


function SpacePostWritePage(props : SpacePostWritePageProps) {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const [title, setTitle] = useState<string>("");    
    const { pathSpaceName, pathThreadName } = utils.getSpaceAndThreadInfo();
    const refTextEditor : TextEditorRefType = useRef(null);
    
    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_writePost(threadName : string, title : string, description? : string, relativeFiles? : number[]) {                
        await api.post(
          `/api/threads/${threadName}/post`,
          {title, description, relativeFiles},
          ()=>{ appNavigator.goBack(); }
        );
    }


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onWritePostClick() {        
        const titleParam = title.trim();        
        if( titleParam.length === 0 )
            return appToast.error("제목을 입력해 주십시오.");
        
        let threadName : string = pathThreadName ? pathThreadName : "";
        if( threadName.length === 0 ) {
            if( pathSpaceName === null ) 
                threadName = EnumOpenThread.spacehub;
            else
                threadName = pathSpaceName;
        }

        const encodeResult = await refTextEditor.current?.encode();        
        const description = encodeResult?.encodedText
        
        await api_writePost(threadName, titleParam, description, encodeResult?.relativeFiles);
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="postWritePage">
            <Typography className="title-label">제목 *</Typography>
            <TextField className="title-text" value={title} onChange={(e)=>setTitle(e.target.value)  }/>

            <Typography className="description-label">내용</Typography>
            <SHSTextEditor refEditor={refTextEditor} />
                        
            <Button className="btn-write" variant="outlined" onClick={onWritePostClick}>글쓰기</Button>
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default SpacePostWritePage;
  