import { MyInfoData } from 'types';
import { UPDATE_MY_INFO } from '../action-types';

const INIT_STATE: MyInfoData | null = null;

export default function UserReducer(
  state = INIT_STATE,
  action: {type: string, payload: MyInfoData | null}
) {
  switch (action.type) {
    case UPDATE_MY_INFO: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}