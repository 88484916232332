/**************************************************************************************************
    FileName  : App.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { Outlet, useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { appNavigator } from 'libs/stdlib';
import { Fragment } from "react";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Class Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////
function App() {
    const navigate = useNavigate();
    appNavigator.init(navigate);

    return (
        <Fragment>
            <CssBaseline />
            <Outlet/>
        </Fragment>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default App;
