/**************************************************************************************************
    FileName  : PostAPI.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import apiRequester, { HttpMethod, DOMAIN } from "./APIRequester";
import appToast from 'components/AppToast';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Type Definitions                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface APIError {
    httpErrorCode : number;
    apiErrorCode : number;
    apiErrorMessage : string;
}

type CallbackSuccess = (data : any)=>void;
type CallbackError = (e:APIError)=>void;



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Main Interface Function                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// error :
//   CallbackError : callback with funcation
//   string : show error message
//   undefined : show default error message
//   null : no display error
async function _request( 
    method : HttpMethod, 
    url : string, 
    reqData : any, 
    fnCallbackSuccess : CallbackSuccess | undefined | null,
    error : CallbackError | string | undefined | null
) : Promise<any> {
    try {
        const res =  await apiRequester.request(method, url, reqData)
        if( fnCallbackSuccess ) {
            let data : any = null;
            if( res ) {
                data =  res.item ? res.item : res.items;                    
            }            
            return fnCallbackSuccess(data);
        }
    } catch(e:any){        
        if( error === undefined || typeof error === "string" ) {
            const errorMessage = e.detailMessage ? e.detailMessage : e.message;
            appToast.error(errorMessage);
            console.error(e);            
        } else if( error === null ){
            return null; // Do nothing
        }else {
            error(e);         
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          API Instance                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _defaultInstace = {    
    get : async function (url: string, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) : Promise<any>{
        return await _request( HttpMethod.get, url, null, fnCallbackSuccess, errorMessage);
    },
    post : async function (url: string, reqData: any, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) : Promise<any>{
        return await _request( HttpMethod.post, url, reqData, fnCallbackSuccess, errorMessage);
    },
    put : async function (url: string, reqData: any, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) : Promise<any> {
        return await _request( HttpMethod.put, url, reqData, fnCallbackSuccess, errorMessage);
    },
    delete : async function (url: string, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) : Promise<any> {
        return _request( HttpMethod.delete, url, null, fnCallbackSuccess, errorMessage );
    },
    request : _request,
};


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default _defaultInstace;
export { HttpMethod, DOMAIN }
export type { APIError, CallbackSuccess }
