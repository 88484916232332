/**************************************************************************************************
    FileName  : diablo4Router.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import {EnumOpenSpace, EnumOpenThread} from "types";
import SpaceHomePage from "../GroupSpaceHomePage";
import ThreadPostAndDetail from "pages/ThreadPostAndDetail";
import SpacePostWritePage from "../SpacePostWritePage";
import CreateAndEditStorePage from "./CreateAndEditStorePage";
import StorePage from "./StorePage";
import RegisterOrEditItem from "./RegisterOrEditItem";
import D4ItemDatabase from "./D4ItemDatabase";
import TradeHome from "./TradeHome";
import TradeChattingRoom from "./TradeChattingRoom";

import {api} from "libs/stdlib";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////  

const routes = [    
    {        
        path: "open-spaces/diablo4",
        loader: async () => {
            await D4ItemDatabase.getInstance().waitInitialize();
            return null;
        },
        children : [
            {
                index : true,
                element: <SpaceHomePage spaceName="diablo4"/>,
            },
            {
                path: "threads/:threadName",
                element: <ThreadPostAndDetail spaceName={EnumOpenSpace.diablo4}  threadName={EnumOpenThread.diablo4} />,
            },
            {
                path: "threads/:threadName/write",
                element: <SpacePostWritePage spaceName={EnumOpenSpace.diablo4}/>,
            },
            {
                path: "store",
                element: <CreateAndEditStorePage/>,
            },
            {
                path: "stores/:storeName",
                children : [
                    {
                        index : true,
                        element: <StorePage/>,
                    },
                    {
                        path: "edit",
                        element: <CreateAndEditStorePage/>,
                    },
                    {
                        path: "trade",
                        element: <RegisterOrEditItem/>,
                    },
                    {
                        path: "trades/:tradeId/edit",
                        element: <RegisterOrEditItem/>,
                    }
                ]
            },
            {
                path: "trades",
                element: <TradeHome/>,
            },
            {
                path: "trades/:tradeId/rooms/:roomId",
                element: <TradeChattingRoom/>,
                loader: async ({params} : any) => {                    
                    return await api.get(
                        `/api/diablo4/trades/${params.tradeId}/rooms/${params.roomId}`,
                        (data)=> data
                    );         
                },
            },
        ]
    },    
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;