/**************************************************************************************************
    FileName  : spaceRouter.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import OpenSpaceHomePage from "./OpenSpaceHomePage";
import GroupSpaceHomePage from "./GroupSpaceHomePage";
import GroupSpaceThreadPage from "./GroupSpaceThreadPage";
import InstanceSpacePage from "./InstanceSpacePage";
import GuestOrderInquery from "./GuestOrderInquery";
import SpacePostWritePage from "./SpacePostWritePage";
import diablo4Routes from "./diablo4/diablo4Router";
import bgprogrammingRoutes from "./bgprogramming/bgprogrammingRouter";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////  


const routes = [    
    ...diablo4Routes,
    ...bgprogrammingRoutes,
    {
        path: "open-spaces/:spaceName",
        children : [
            {
                index : true,
                element: <OpenSpaceHomePage/>,
            },            
            {
                path: "threads/:threadName/write",
                element: <SpacePostWritePage/>,
            }
        ]
    },
    {
        path: "group-spaces/:spaceName",
        children : [
            {
                index : true,
                element: <GroupSpaceHomePage/>,
            },
            {
                path: "threads/:threadName",
                element: <GroupSpaceThreadPage />,
            },
            {
                path: "threads/:threadName/write",
                element: <SpacePostWritePage/>,
            }
        ]
    },
    {                              
        path: "instance-space/guest-order-inquiry",
        element: <GuestOrderInquery/>,
    },
    {
        path: "instance-spaces/:spaceName",
        children : [
            {
                index : true,
                element: <InstanceSpacePage/>,
            },            
        ]
    },
    
];

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default routes;