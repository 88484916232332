/**************************************************************************************************
    FileName  : SignupRequest.tsx
    Description

    Update History 
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./SignupRequest.scss";

import {useState} from 'react';

import { Stack, TextField, Typography, Button } from "@mui/material";
import { appNavigator } from 'libs/stdlib';
import {appToast, utils, api} from "libs/stdlib";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Home Implementation                                          //
///////////////////////////////////////////////////////////////////////////////////////////////////
function Signup() {
    const [email, setEmail] = useState<string>("");

    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_requestSignupEmail(email : string) {        
        await api.post(
            "/api/user/sign-up/email/reqeust",
            {email},
            ()=>{
                appToast.success("회원 가입 이메일을 요청했습니다.\n이메일을 확인해 주십시오.");
                setEmail("");
            }
        ) 
    }



    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function onLogoClick() {
        appNavigator.goHome();
    }

    async function onRequestSignupEmailClick() {
        const emailParam = email.trim();
        if( emailParam.length === 0 ) {
            return appToast.error("이메일을 입력해 주십시오.");
        }

        if( utils.isEmailFormat(emailParam) === false ) {
            return appToast.error("이메일 형식이 올바르지 않습니다.");
        }

        await api_requestSignupEmail(emailParam);
    }

    function onLoginPageClick() {
        appNavigator.goLoginPage();
    }

    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="signupPage-230601">
            <Typography variant="h3" className="site-title" onClick={onLogoClick}>SpaceHub Society</Typography>

            <Stack className="signup-panel">
                <Typography variant="h6">회원가입 이메일</Typography>
                <Typography variant="body2">입력한 주소로 회원가입 이메일이 전송됩니다.</Typography>                

                <TextField 
                    className="input-email"
                    variant="standard" 
                    label="이메일"
                    value={email}        
                    onChange={(e)=>setEmail(e.target.value)}
                />
                
                <Button variant="contained" onClick={onRequestSignupEmailClick}>회원가입 이메일 전송</Button>
            </Stack>
            <Stack className="login-panel">
                <Typography variant="body2" className="btn-loginpage" onClick={onLoginPageClick}>로그인</Typography>
            </Stack>            
        </Stack>        
    );
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default Signup;