export const utils = {

    isUrl : function(url : string) {
        const protocol = url.split("://")[0];
        return protocol === "http" || protocol === "https";
    },
    isUrlYoutube(url : string) {
        const arUrl = url.split("://");
        if( arUrl.length === 1)
            return false;

        const urlAddress = arUrl[1];
        const domain = urlAddress.split("/")[0];
        return domain === 'youtube.com' ||  domain === 'www.youtube.com';
    },

    getYoutubeId(url : string) {
        const urlAddress = url.split("://")[1];
        const domain = urlAddress.split("/")[0];
        return domain === 'youtube.com' ||  domain === 'www.youtube.com';
    }

    
}