/**************************************************************************************************
    FileName  : TradeChattingRoom.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./TradeChattingRoom.scss";
// Import for React
import { Fragment, useEffect, useState } from "react";
// Import for Material UI
import {
    Stack, Typography, Chip, Avatar, TextField,
    IconButton,
    Button
} from "@mui/material";
// Import for Project Base
import { appNavigator, api, utils, shsAssert, myInfo, appToast, appAlert } from "libs/stdlib";
import PathTracker from "modules/PathTracker";
import { 
    EnumOpenSpace, Diablo4TradeChattingRoomInfo, ChattingRoomMessage, UserInfo
} from "types";
import D4ItemDatabase from "./D4ItemDatabase";

import StorefrontIcon from '@mui/icons-material/Storefront';
import SocketManager, { SocketEvent } from "modules/SocketManager";
import { useParams, useLoaderData } from "react-router-dom";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import shsParser, {SHSParserType, SHSParserData, SHSParserSubTypeDiablo4} from "modules/SHSParser";

interface ChattingEventData {
    fromUser : UserInfo;
    messageId : number;
    message : string;
    roomId : number;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
function TradeChattingRoom() {
    /////////////////////////////////////////////////////////////////////////////
    // Const
    const urlParams = useParams();
    shsAssert(urlParams.roomId!==undefined);
    const roomId = parseInt(urlParams.roomId!);

    const d4RoomInfo : Diablo4TradeChattingRoomInfo = useLoaderData() as Diablo4TradeChattingRoomInfo;    
    const myInfoData = myInfo.get()!;
    shsAssert( myInfoData !== null );
    const otherUserName = myInfoData.badgeInfo.badgeId === d4RoomInfo.chattingRoomInfo.buyerInfo.badgeId ? 
        d4RoomInfo.chattingRoomInfo.sellerInfo.displayName : d4RoomInfo.chattingRoomInfo.buyerInfo.displayName;        
    const tradeInfo = d4RoomInfo.chattingRoomInfo.tradeInfo;
    const itemInfo = D4ItemDatabase.getInstance().getItem(tradeInfo.itemId);    
    const pathTracker = PathTracker.getInstance();

    const [newMessage, setNewMessage] = useState<string>("");
    const [chatMessages, setChatMessages] = useState<ChattingRoomMessage[]>(d4RoomInfo.messages);


    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_cancelTrade(tradeId : number, roomId : number) {
        await api.post(
            `/api/diablo4/trades/${tradeId}/rooms/${roomId}/cancel`,
            {},
            ()=>{
                appToast.success("거래가 취소 되었습니다.");
                appNavigator.goBack();
            }
        );
    }

    async function api_completeTrade(tradeId : number, roomId : number) {
        await api.post(
            `/api/diablo4/trades/${tradeId}/rooms/${roomId}/sold-out`,
            {},
            ()=>{
                appToast.success("거래가 완료 되었습니다.");
                appNavigator.goBack();
            }
        );
    }
    
    
    


    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{        
        pathTracker.buildStart()
            .add("다이블로4", ()=>{ appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) })
            .add("거래", ()=>appNavigator.diablo4.goTradeHome()  )
            .add("채팅")
            .build();
        
        (async ()=>{
        
        })();
        
        //eslint-disable-next-line
    },[]);


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function getCssClassMessageContainer(badgeId : number) : string {
        return  myInfoData.badgeInfo.badgeId === badgeId ? "message-right" : "message-left";     
    }

    function isSeller() : boolean {
        return myInfoData.badgeInfo.badgeId === d4RoomInfo.chattingRoomInfo.sellerInfo.badgeId;
    }

    function getMessage(data : SHSParserData) : string {
        if( data.type === SHSParserType.plainText ) {
            return (data as SHSParserData).content as string;            
        } else if( data.type === SHSParserType.system ) {
            switch(data.subType) {
                case SHSParserSubTypeDiablo4.buyTradeItem:
                    return `${utils.numberWithCommas( (data.content as any).priceCurrencyAmount  )} 골드에 구매를 희망합니다.`;
                default:
                    shsAssert(false);
                    return "";
            }
        }
        else {
            shsAssert(false);
            return "";
        }
    }

    function getMessageComponents(chatMessage : ChattingRoomMessage) {
        const isRight = myInfoData.badgeInfo.badgeId === chatMessage.badgeId;
        const parseMessage = shsParser.parse(chatMessage.message);        
        if( isRight ) {
            return (
                <Fragment>
                    <Typography className="time-label" variant="body2" color="text.secondary">{utils.getDateString(chatMessage.createTime)}</Typography>
                    <Stack className="message-content-container">
                        <pre className="speech-bubble">
                            {getMessage(parseMessage[0])}
                        </pre>
                    </Stack>
                </Fragment>
            );

        } else {
            return (
                <Fragment>
                    <Avatar></Avatar>
                    <Stack className="message-content-container">
                        <Typography className="name-label" variant="body2">{otherUserName}</Typography>
                        <pre className="speech-bubble">
                            {getMessage(parseMessage[0])}
                        </pre>
                    </Stack>
                    <Typography className="time-label" variant="body2" color="text.secondary">{utils.getDateString(chatMessage.createTime)}</Typography>
                </Fragment>
            );

        }
    }

    async function onSendMessage(msg : string) {
        
        const strMessageId = await SocketManager.getInstance().chatD4TradeRoom(roomId, msg);
        let newChatMessage : ChattingRoomMessage = {
            id : parseInt(strMessageId),
            badgeId : myInfoData.badgeInfo.badgeId,
            message : msg,
            createTime : Date.now()/1000
        };
        setChatMessages([
            ...chatMessages,
            newChatMessage
        ])
        setNewMessage("");
        scrollToBottom();
    }

    SocketManager.getInstance().setCallbackEvent(SocketEvent.chatting, (data : ChattingEventData)=>{
        console.info("socket chatting event >>> ", data);
        if( data.roomId === roomId) {
            let newChatMessage : ChattingRoomMessage = {
                id : data.messageId,
                badgeId : data.fromUser.badgeInfo.badgeId,
                message : data.message,
                createTime : Date.now()/1000
            };
            setChatMessages([
                ...chatMessages,
                newChatMessage
            ])
            scrollToBottom();
        }
    });

    async function scrollToBottom() {
        setTimeout(()=>{
            window.scrollTo(0, document.body.scrollHeight);
        }, 100);
    }

    async function onTradeCancelClick() {
        const res = await appAlert.showQuestionDialog(
            "거래 취소",
            "거래를 취소하시겠습니까?"
        );
        if( res.isConfirmed ) {
            shsAssert(urlParams.tradeId !== undefined);
            const tradeId = parseInt(urlParams.tradeId!);
            await api_cancelTrade(tradeId, roomId);
        }
    }

    async function onTradeCompleteClick() {
        const res = await appAlert.showQuestionDialog(
            "판매 완료",
            "판매 완료를 처리할까요?"
        );
        if( res.isConfirmed ) {
            shsAssert(urlParams.tradeId !== undefined);
            const tradeId = parseInt(urlParams.tradeId!);
            await api_completeTrade(tradeId, roomId);
        }
    }

    


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="tradeChattingRoom-230528">            
            {/* BEGIN trade-info-container **********************************/}
            <Stack className="trade-info-container">
                <Stack>
                    <Stack className="store-info-container">
                        <Stack className="store-name-panel">
                            <StorefrontIcon />
                            <Typography className="store-name" ml={1} >{tradeInfo.storeInfo.displayName}</Typography>
                        </Stack>
                        <Typography>{tradeInfo.storeInfo.introduce}</Typography>                    
                    </Stack>

                    <Stack className="action-panel">
                        <Button className="btn-action btn-cancel" variant="contained" onClick={onTradeCancelClick}>
                            거래 취소
                        </Button>
                        {isSeller() &&
                            <Button className="btn-action btn-complete" variant="contained" color="secondary" onClick={onTradeCompleteClick}>
                                거래 완료
                            </Button>
                        }                        
                    </Stack>
                </Stack>
                
                
                <Stack className="trade-item-container">                
                    <Stack>
                        <Stack className="item-info-panel">
                            {tradeInfo.isHardcore && <Chip label={"하드코어"} size="small"/>}
                            {tradeInfo.isLadder && <Chip label={"레더"} size="small"/>}
                            <Chip label={itemInfo?.rarity} size="small"/>
                            <Chip className="chip-equipment-type" label={itemInfo?.equipmentType} size="small"/>                                    
                        </Stack>
                        <Typography variant="h5">{itemInfo?.localName}</Typography>

                        <Typography variant="body2" mt={1} color="text.secondary">가격</Typography>
                        <Stack className="price-info-panel">
                            <Typography variant="h5">{utils.numberWithCommas(tradeInfo.priceAmount!)}</Typography>
                            <Typography variant="body1" ml={1}>Gold</Typography>
                        </Stack>
                    </Stack>

                    <Stack className="item-option-container">
                        <Typography variant="h6" mt={1}>아이템 옵션</Typography>
                        <Stack className="item-option-list">
                            {tradeInfo.itemOptions && tradeInfo.itemOptions.map((option, index)=>{
                                return (
                                    <Stack className="option-container" key={index}>
                                        <Typography>{option.optionIdTxt}</Typography>
                                        <Typography className="value-label">{option.val}</Typography>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {/************************************ End trade-info-container */}

            <Stack className="message-list-container">
                {chatMessages.map((message)=>{
                    return (
                        <Stack className={`message-container ${getCssClassMessageContainer(message.badgeId)}`} key={message.id}>
                            {getMessageComponents(message)}
                        </Stack>
                    );
                })}                
            </Stack>


            <Stack className="message-input-panel">
                <TextField 
                    className="message-input" 
                    multiline 
                    maxRows="4"
                    value={newMessage}
                    onChange={(e)=>setNewMessage(e.target.value)}
                ></TextField>
                <IconButton onClick={()=>onSendMessage(newMessage)}>
                    <SendOutlinedIcon/>
                </IconButton>
            </Stack>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default TradeChattingRoom;
  