/**************************************************************************************************
    FileName  : SHSSuggestion.ts
    Description : 
	  

    Update History 	  
      2023.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React  from 'react';
import './Editor.scss';

import { Typography } from '@mui/material';
import {Rect, KeyCode} from "../Define";
import { BadgeInfo } from 'types';



interface SHSSuggestionProps {
	inputScreenRect : Rect | null;
	userList : any[];
	specialKey : number;
	open : boolean;
	siteUrl : string;
	initSpecialKey : ()=>void;
	onResult : (badgeInfo : BadgeInfo | null)=>void;
	onCancel : ()=>void;	
}

export default class SHSSuggestion extends React.Component<SHSSuggestionProps> {
	refContainer : any;

	state : {
		idxSelectedSuggestion : number;
	};

    constructor(props : SHSSuggestionProps) {
		super(props);
		this.state = {
			idxSelectedSuggestion : 0
		};

		this.isSelectedUser = this.isSelectedUser.bind(this);
		this.refContainer = React.createRef();
    }

	onCloseClick() {

	}

	onUserClick() {

	}

	getTop() {
		if( this.props.inputScreenRect === null || this.props.inputScreenRect === undefined )
			return 0;

		const SUGGESTION_WINDOW_HEIGHT = 216;
		const MARGIN = 8;
		const inputScreenRect = this.props.inputScreenRect;
		const screenHeight = window.innerHeight;
		//console.info("clientScreenRect>>>", inputScreenRect);

		// for decide to suggestion window rect
		// check upper space and lower space with current element rect
		const upperHeight = inputScreenRect.y;
		const lowerHeight = screenHeight - (inputScreenRect.y + inputScreenRect.height);

		if(  lowerHeight < upperHeight )
			return inputScreenRect.y  - MARGIN - SUGGESTION_WINDOW_HEIGHT;
		else
			return inputScreenRect.y + inputScreenRect.height + MARGIN;
	}

	

	isSelectedUser(index : number) {
		return this.state.idxSelectedSuggestion === index ? "selected-user" : "";
	}


	isInRect(containerRect : Rect, rect : Rect) : boolean {
		return (
			containerRect.x <= rect.x && rect.x + rect.width <= containerRect.x + containerRect.width &&
			containerRect.y <= rect.y && rect.y + rect.height<= containerRect.y + containerRect.height
		);
	}
	focusSelectecElement(selectedElement : HTMLElement) {		
		const containerRect = this.refContainer.current.getBoundingClientRect();
		const selectedUserRect = selectedElement.getBoundingClientRect();
		const isInRect = this.isInRect(containerRect, selectedUserRect);
		if(  isInRect === false )
			this.refContainer.current.scrollTop = selectedUserRect.y - containerRect.y;
	}

	goDownSelectedUser() {
		let idxSelecteduser = -1;
		if( this.state.idxSelectedSuggestion + 1 < this.props.userList.length )
			idxSelecteduser = this.state.idxSelectedSuggestion + 1;
		else
			idxSelecteduser = 0;

		this.setState( {
			idxSelectedSuggestion : idxSelecteduser
		});

		this.focusSelectecElement(this.refContainer.current.children[idxSelecteduser]);
	}
	goUpSelectedUser() {
		let idxSelecteduser = -1;
		if( 0 <= this.state.idxSelectedSuggestion - 1  )
			idxSelecteduser = this.state.idxSelectedSuggestion - 1;
		else
			idxSelecteduser = this.props.userList.length-1;

		this.setState( {
			idxSelectedSuggestion : idxSelecteduser
		});

		this.focusSelectecElement(this.refContainer.current.children[idxSelecteduser]);
	}

	componentWillUpdate(willProps : any) {
		if( willProps.specialKey !== this.props.specialKey )  {
			if( this.props.specialKey === KeyCode.Up  )
				this.goUpSelectedUser();
			else if( this.props.specialKey === KeyCode.Down )
				this.goDownSelectedUser();
			else if( this.props.specialKey === KeyCode.Enter ) {
                if( this.props.userList === undefined || this.props.userList === null || this.props.userList.length === 0 ) {
                    this.props.onResult( null );
                } // nothing to suggestion
                else {
                    if( this.props.userList.length === 0 )
    					this.props.onCancel();
    				else
    					this.props.onResult( this.props.userList[this.state.idxSelectedSuggestion] );
                }
			}

		} else if ( willProps.open !== this.props.open  )  {
			if( willProps.open === true ) {
				document.body.classList.add("modal-open");
				this.setState({ idxSelectedSuggestion : 0 });
			} else {
				document.body.classList.remove("modal-open");
			}

		}
	}

	componentDidUpdate() {
		if( 0 < this.props.specialKey )
			this.props.initSpecialKey();
	}


    render() {
		if( !this.props.open || !this.props.userList )
			return <></>;

        return (
			<div className="suggestion-layout" onClick={this.props.onCancel}>
				<div className="suggestion-container" style={{display : this.props.open ? "block" : "none", top : this.getTop(), width : this.props.inputScreenRect?.width }} ref={this.refContainer} >
					{this.props.userList.map((user, index)=>{
						return (
							<div className={`user-container ${this.isSelectedUser(index)}`} key={user.username} onClick={()=>this.props.onResult(user)}>
								<img className="user-image" alt="user" src={this.props.siteUrl + user.profileImages.sm}/>
								<div className="username-container">									
									<Typography className="display-name">{user.displayName}</Typography>
								</div>
							</div>
						);
					})}
				</div>
			</div>
        );
    }
}
