/**************************************************************************************************
    FileName  : GuestOrderInquery.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./GuestOrderInquery.scss";
// Import for Material UI
import {
    Stack, Typography, TextField, Button,
} from "@mui/material";
// Import for Project Base
import SHSTextViewer from "components/SHSText/SHSTextViewer";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

function GuestOrderInqueryPage() {
    /////////////////////////////////////////////////////////////////////////////
    // const



    const testDescription = `Hello World! [[%!!SHS>{"type":"inline-mention", "userName":"bg"}<SHS!!%]] How are you?Fine Thanyou and you?
[[%!!SHS>{"type":"image", "domain":"https://image.genie.co.kr", "url" : "/Y/IMAGE/IMG_ALBUM/083/873/973/83873973_1685440304593_1_600x600.JPG/dims/resize/Q_80,0"}<SHS!!%]]
아래는 링크임[[%!!SHS>{"type":"link", "preview": { "url" : "https://www.naver.com", "image" : "https://image.genie.co.kr/Y/IMAGE/IMG_ALBUM/083/859/513/83859513_1685077683972_1_600x600.JPG/dims/resize/Q_80,0", "title" : "변함없이" } }<SHS!!%]]
[[%!!SHS>{"type":"youtube", "id":"2MhRGfOjtE0"}<SHS!!%]]
    `;



    /////////////////////////////////////////////////////////////////////////////
    // API

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    


    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="guestOrderInqueryPage-230624">
            <SHSTextViewer description={testDescription}/>
            
            <Typography className="title-label" variant="h4">비회원 주문조회 </Typography>
            <Stack className="inquery-panel">
                <TextField label="Paypal Transaction ID" variant="standard"/>
                <Button className="btn-submit" variant="outlined">Submit</Button>                                
            </Stack>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default GuestOrderInqueryPage;
  