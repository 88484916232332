/**************************************************************************************************
    FileName  : SHSImage.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import {ComponentType} from "../Define";
import shsAssert from 'libs/shsAssert';




///////////////////////////////////////////////////////////////////////////////////////////////////
// This is abstract static class function
export const getImageType = function () {
    return ComponentType.image;
};
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface FileInfo {
    md : string;
    url : string;
    info? : {
        imageNames : {
            md : string;
        }
    }
}

interface SHSImageProps {
    onProvideImageData? : (id:number)=>FileInfo;
    id? : number;
    domain : string;
    url? : string;
    viewerStyle? : {
        imageBorderRadius : number;
        imageMargin : number;
    }
    onImageClick? : (src : string)=>void
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class SHSImage extends React.Component<SHSImageProps> {
    state = {
        src : ""
    };

    
    async componentDidMount() {
        const props = this.props;        
        if( props.onProvideImageData ) {
            shsAssert(props.id !== undefined);
            const imageInfo = await props.onProvideImageData(props.id!);
            
            let imageSrc = "";
            if( imageInfo.md)
                imageSrc = props.domain + imageInfo.md;
            else if( imageInfo.url )
                imageSrc = props.domain + imageInfo.url;
            else if( imageInfo.info && imageInfo.info.imageNames )
                imageSrc = props.domain + imageInfo.info.imageNames.md;

            this.setState((state, props) => ({
                  src: imageSrc
            }));
        } else if ( props.url ) {             
            this.setState({src : props.domain + props.url});
        }
    }



    render() {        
        return (
            <img                
                className="shs-image"
                src={this.state.src}
                alt="pic"
                style={{
                    borderRadius : this.props.viewerStyle ? this.props.viewerStyle.imageBorderRadius : 0,
                    margin : this.props.viewerStyle?.imageMargin,
                }}
                onClick={()=>{
                    if( this.props.onImageClick ) {
                        this.props.onImageClick(this.state.src);
                    }
                }}
            />
        )
    }

}
