/**************************************************************************************************
    FileName  : PostEditPage.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import {
	Stack,
	Typography
} from "@mui/material";
import "./Sidebar.scss";
import {appNavigator} from "libs/stdlib";
import { EnumOpenSpace, EnumSpecialGroupSpace } from "types";

// Import for Redux
import ReduxStore from 'redux/store';
import { useSelector } from 'react-redux'
import { showSideBar } from 'redux/actions';

import { Sidebar, Menu, MenuItem, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import Logo from "assets/images/logo_wide.png";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Sidebar Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////


export default function AppSidebar() {
	const sidebarToggled = useSelector((state: any) => state.misc.isShowSideBar);
		
	const hexToRgba = (hex: string, alpha: number) => {
		const r = parseInt(hex.slice(1, 3), 16);
		const g = parseInt(hex.slice(3, 5), 16);
		const b = parseInt(hex.slice(5, 7), 16);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	};
	  

	const menuItemStyles: MenuItemStyles = {
		root: {		  
		  fontWeight: 400,
		},
		icon: {
		  color: "#59d0ff",
		  [`&.${menuClasses.disabled}`]: {
			color: "#3e5e7e",
		  },
		},
		SubMenuExpandIcon: {
		  color: '#b6b7b9',
		},
		subMenuContent: ({ level }) => ({
		  backgroundColor:
			level === 0
			  ? hexToRgba("#082440", 0.4)
			  : 'transparent',
		}),
		button: {
		  [`&.${menuClasses.disabled}`]: {
			color: "#3e5e7e",
		  },
		  '&:hover': {
			backgroundColor: hexToRgba("#00458b", 1),
			color: "#b6c8d9",
		  },
		},
		label: ({ open }) => ({
		  fontWeight: open ? 600 : undefined,
		}),
	};


	function onClickHome() {
		appNavigator.goHome();
		hideSidebar()
	}

	function onClickOpenSpaceHome(spaceName : string) {
		appNavigator.goOpenSpaceHome(spaceName);
		hideSidebar()
	}

	function hideSidebar() {
		ReduxStore.dispatch(showSideBar(false));
	}

	function onUserAgreementClick() {
		appNavigator.goUserAgreement();

	}

	function onPrivatePolicyClick() {
		appNavigator.goPrivatePolicy();
	}

	function onClickGroupSpaceHome(spaceName : string) {
		appNavigator.goGroupSpaceHome(spaceName);
	}

	// function onGuestOrderInqueryClick() {
	// 	appNavigator.goGuestOrderInqueryPage();
	// }

	// function goInstanceSpacePageClick(spaceName : string) {
	// 	appNavigator.goInstanceSpacePage(spaceName);
	// }


	return (
		<Sidebar
			id="sidebar"
			breakPoint="md" 
			backgroundColor="black"
			toggled={sidebarToggled}
			onBackdropClick={()=>hideSidebar() }
		>			
			<img className="logo" src={Logo} alt="logo" onClick={onClickHome}></img>


			{/* <Typography
                variant="body2"
                fontWeight={600}
				className="section-label"
                style={{ opacity: 0.7, letterSpacing: '0.5px', marginTop : "8px" }}
              >
                INSTANCE SPACE
            </Typography>
			<Menu menuItemStyles={menuItemStyles}>
				<MenuItem onClick={onGuestOrderInqueryClick}> 비회원 주문조회 </MenuItem>
				<MenuItem onClick={()=>goInstanceSpacePageClick("event-14102") }> 올리브영 판매 이벤트 </MenuItem>
			</Menu> */}


			<Typography
				variant="body2"
				fontWeight={600}
				className="section-label"
				style={{ opacity: 0.7, letterSpacing: '0.5px', marginTop : "24px" }}				
			>
				OPEN SPACE
			</Typography>            
			<Menu menuItemStyles={menuItemStyles}>
				<MenuItem onClick={onClickHome}> SpaceHub Society </MenuItem>				
				<MenuItem onClick={()=>onClickOpenSpaceHome(EnumOpenSpace.programming)}> Programming </MenuItem>
			</Menu>


			<Typography
                variant="body2"
                fontWeight={600}
				className="section-label"
                style={{ opacity: 0.7, letterSpacing: '0.5px', marginTop : "24px" }}
              >
                GROUP SPACE
            </Typography>
			<Menu menuItemStyles={menuItemStyles}>
				<MenuItem onClick={()=>onClickGroupSpaceHome(EnumSpecialGroupSpace.bgProgramming)}> BG Programming </MenuItem>				
			</Menu>
			<Stack className="terms-panel">
				<Typography className="terms" variant="body2" onClick={onUserAgreementClick}>이용약관</Typography>
				<Typography variant="body2" ml={1} mr={1}>|</Typography>
				<Typography className="terms" variant="body2" onClick={onPrivatePolicyClick}>개인정보 처리방침</Typography>
			</Stack>
		</Sidebar>
	)
}
