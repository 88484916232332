/**************************************************************************************************
    FileName  : PostAPI.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import api, {CallbackSuccess} from "./index";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostAPI Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class PostAPI {
    public async getPostDetail(postId : number, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) {
        await api.get(
            `/api/posts/${postId}`, fnCallbackSuccess, errorMessage
        );        
    }
}