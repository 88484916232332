/**************************************************************************************************
    FileName  : PostDetail.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./PostDetail.scss";
// Import for React
import {
    Stack, Typography, Box
} from "@mui/material";
// Import for Project Types
import { PostDetailData } from "types";
// Import for Project Base
import {utils, DOMAIN} from "libs/stdlib";
import SHSTextViewer from "./SHSText/SHSTextViewer";

  
///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostDetail Implementation                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface PostDetailPros{
    post : PostDetailData;    
}

export default function PostDetail( props : PostDetailPros) {    
    return (
      <Stack className='post-detail-component'>
        <Stack className="writer-info-panel">
            <img className="user-profile-image" src={DOMAIN + props.post.writerInfo.profileImage.sm!} alt="writer-profile"/>
            <Typography ml={2}>{props.post.writerInfo.displayName}</Typography>
            <Typography ml={1} variant="body2" color="text.secondary">{utils.getDateString(props.post.createTime)}</Typography>
        </Stack>
        <Typography variant="h5" mt={3}>{props.post.title}</Typography>
        <Box className="post-description">
		    <SHSTextViewer description={props.post.description}/>
		</Box>        
      </Stack>
    );
}



