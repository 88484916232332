/**************************************************************************************************
    FileName  : SHSImage.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import Youtube from 'react-youtube';



///////////////////////////////////////////////////////////////////////////////////////////////////
// This is abstract static class function
export const getYoutubeType = function () {
    return "youtube";
};
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSYoutubeProps {
    width? : number;
    id : string;
}


export default class SHSYoutube extends React.Component<SHSYoutubeProps> {

    render() {
        const props = this.props;
        function getHeight() {
            if( props.width ) {                
                // Target Rate : 640x360                               
                // x = screenWidth * 360 / 640;
                return props.width * 360 / 640;
            } else
                return 0;

        }

        return(
            <div className="youtube-player-container" style={{height:getHeight()}}>
                <Youtube
                    className="spacehub-youtube"                    
                    videoId={props.id}
                    opts={{
                        playerVars: {
                            // youtube iframe을 로드하면 브라우저 콘솔에 아래와 같은 에러메시지 출력됨.
                            // Failed to execute 'postMessage' on 'DOMWindow':
                            // The target origin provided ('https://www.youtube.com') does not match the recipient window's origin (${window.origin}).
                            // 검색 결과 유튜브 iframe의 오래된 버그(?) 이고, 프로그램 동작에 영향이 없다고 함.
                            // https://developers.google.com/youtube/iframe_api_reference 구글 공식 문서에 들어간 후 콘솔에 출력된 내용을 보면, 동일한 에러메시지 출력되어 있음.

                            // 아래 속성 1로 설정하지 않으면 iOS에서 인라인 재생이 안됨.
                            playsinline: 1,
                        }
                    }}
                />
            </div>
        );

    }
}
