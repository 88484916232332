import Swal, {SweetAlertIcon, SweetAlertResult} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';


export enum EnumAlertIcon {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  question = 'question'
}


class AppAlert {
  private _alert : typeof Swal;

  constructor(){
    this._alert = withReactContent(Swal).mixin({
      customClass: {
        confirmButton: 'btn btn-app-alert-primary',
        cancelButton: 'btn btn-app-alert-default'
      },
      buttonsStyling: false
    });	

	// this._alert = withReactContent(Swal);
  }

  public async showDeleteQuestionDialog() : Promise<SweetAlertResult> {
    return await this.showQuestionDialog('정말로 삭제하시겠습니까?', "삭제하면 복구할 수 없습니다.", undefined, "삭제");
  }


  public showQuestionDialog(title : string, text : string, icon? : SweetAlertIcon, confirmButtonText? : string, cancelButtonText? : string  ) : Promise<SweetAlertResult> {
    if( icon === undefined ) icon = "question";
    if( confirmButtonText === undefined ) confirmButtonText = "예";
    if( cancelButtonText === undefined ) cancelButtonText = "아니오";
    
    return this._alert.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons : true,
    });    
  }

  public showConfirmDialog(title : string, text : string, icon? : SweetAlertIcon, confirmButtonText? : string) : Promise<SweetAlertResult> {
    if( icon === undefined ) icon = "warning";
    if( confirmButtonText === undefined ) confirmButtonText = "확인";    
    
    return this._alert.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText,
      reverseButtons : true
    });
  }

  public async showInputDialog(title : string, text? : string, inputValue? : string, confirmButtonText? : string, cancelButtonText? : string) : Promise<SweetAlertResult> {    
    if( confirmButtonText === undefined ) confirmButtonText = "확인";
    if( cancelButtonText === undefined ) cancelButtonText = "취소";
    
    return await this._alert.fire({
      title: title,
      text: text,
      confirmButtonText,
      cancelButtonText,
      input: 'text',
      inputValue :  inputValue ? inputValue : "",
      showCancelButton : true,
      reverseButtons : true
    });
  }
  

}

const _instance= new AppAlert();
export default _instance;