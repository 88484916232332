/**************************************************************************************************
    FileName  : TradeHome.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./TradeHome.scss";
// Import for React
import { useEffect, useState } from "react";
// Import for Material UI
import {
    Stack, Typography, Button, Grid
} from "@mui/material";
// Import for Project Base
import { appNavigator, api, myInfo, appAlert, apiCommon } from "libs/stdlib";
import { Diablo4Store } from "types/diablo4";
import PathTracker from "modules/PathTracker";
import { EnumOpenSpace, TradeInfo } from "types";

import TradeItem from "./components/TradeItem";

import StorefrontIcon from '@mui/icons-material/Storefront';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
function TradeHome() {    
    const [tradeList, setTradeList] = useState<TradeInfo[] | null>(null);
    const pathTracker = PathTracker.getInstance();
    
    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_getTradeHomeInfo() {
        await api.get(
            "/api/diablo4/trades?lastItemId=-1&numOfPage=100",
            (data)=>{                
                setTradeList(data.list);
            }
        )
    }



    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{        
        pathTracker.buildStart()
            .add("다이블로4", ()=>{ appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) })
            .add("거래")
            .build();
        

        (async()=>{
            await api_getTradeHomeInfo();
        })();

        //eslint-disable-next-line
    },[]);


    /////////////////////////////////////////////////////////////////////////////
    // Functions    
    function onStoreNameClick(storeInfo : Diablo4Store) {
        appNavigator.diablo4.goStore(storeInfo.name);
    }

    function isMyItem(trade : TradeInfo) : boolean {        
        return myInfo.get() !== null && trade.storeInfo.badgeId === myInfo.get()!.badgeInfo.badgeId;
    }

    async function onRequestTradeClick(tradeInfo : TradeInfo) {
        const result = await appAlert.showQuestionDialog("거래요청", "아이템 거래를 요청하시겠습니까?");
        if( result.isConfirmed === true ) {
            await apiCommon.diablo4.createChattingRoom(tradeInfo.tradeId, (data)=>{
                appNavigator.diablo4.goTradeChattingRoom(tradeInfo.tradeId, data.id);                
            })
        }
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="tradeHome-230526">                                    
            <Grid container className="item-list" spacing={1} >
                {tradeList && tradeList.map((trade)=>{                    
                    return (
                        <Grid xs={12} md={6} lg={3} item key={trade.itemId} >
                            <Stack className="trade-container">
                                <Stack className="store-info-panel">
                                    <StorefrontIcon />
                                    <Typography className="store-name" ml={1} onClick={()=>onStoreNameClick(trade.storeInfo)}>{trade.storeInfo.displayName}</Typography>
                                </Stack>

                                <TradeItem trade={trade}/>
                                
                                <Stack className="action-panel">
                                    <Button variant="outlined" disabled={isMyItem(trade)} onClick={()=>onRequestTradeClick(trade)}>거래 요청</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    );
                })}             
            </Grid>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default TradeHome;
  