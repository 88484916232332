/**************************************************************************************************
    FileName  : SHSTextEditor.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import Editor from "./EditorComponents/Editor";
import { MetaTagInfo } from "./Define"
import { DOMAIN, api } from 'libs/stdlib';
import { FileInfo } from "types";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSTextEditorProps {
    refEditor : React.RefObject<Editor>;
    description? : string;
}

export type TextEditorRefType = React.RefObject<Editor>;



export default function SHSTextEditor(props : SHSTextEditorProps) {
    async function requestUploadImage(file : File) : Promise<FileInfo> {
        const formData = new FormData();
        formData.append("image", file);
        const imageInfo = await api.post(
            "/api/file/post/tmp/image", 
            formData,
            (data)=> data
        );        
        return imageInfo;
    }


    async function api_getPreviewMetaTagInfo(url : string) : Promise<MetaTagInfo> {
        return await api.post(
            "/api/misc/preview/info",
            {url},
            (data : MetaTagInfo) => data
        );
    }


    async function requestLinkMetaTagInfo(url : string, cb : (info : any)=>void) {
        const previewInfo = await api_getPreviewMetaTagInfo(url);        
        cb(previewInfo);
    }

    function requestMentionUserInfo(suggestionText : string, cb : (suggestionUserList : any[])=>void ) {
        console.info("suggestionText>>>>", suggestionText);
        cb([
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "멘션의_경우_테스트용_데이터입니다."
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user1"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user2"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user3"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user4"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user5"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user6"
            },
            {
                profileImages : {
                    sm : "/assets/images/guest_sm.png",                    
                },
                displayName : "user7"
            },
            

        ])
    }

    async function onProvideImageData(fileId : number) {
        const res = await api.get(`/api/files/${fileId}`, (data)=>data );        
        return res;
    }



    return(
        <Editor
            ref={props.refEditor}
            description={props.description}
            isShowToolbar={true}
            requestUploadImage={requestUploadImage}
            requestLinkMetaTagInfo={requestLinkMetaTagInfo}
            requestMentionUserInfo={requestMentionUserInfo}
            onProvideImageData={onProvideImageData}
            siteUrl={DOMAIN}
            

        />
    );
}
