/**************************************************************************************************
    FileName  : InstanceSpacePage.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./InstanceSpacePage.scss";
// Import for Material UI
import {
    Stack, Typography, IconButton,
} from "@mui/material";
// Import for Project Base

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpaceHome Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface OpenSpaceHomePageProps  {
    spaceName? : string;
}


function OpenSpaceHomePage(props : OpenSpaceHomePageProps) {
    /////////////////////////////////////////////////////////////////////////////
    // const
        

    /////////////////////////////////////////////////////////////////////////////
    // API

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    

    /////////////////////////////////////////////////////////////////////////////
    // Functions


    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="instanceSpaceHomePage-230624">
            <Typography className="title-label" variant="h4">Jenny's 한국 여행 : 올리브영 이벤트</Typography>
            <Stack className="instance-image-panel">
                <img className="img-influencer" src="https://people.com/thmb/xR5O1T5d7sf7wP5UmHMCGh_VK6M=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2)/jenny-mccarthy-the-masked-singer-060923-1-00dc9102c3c141369dd2e02e7cbce798.jpg" alt="influencer"></img>
                <Typography variant="h3" ml={4} mr={4}>X</Typography>
                <img className="img-company" src="https://blog.kakaocdn.net/dn/bqDcKW/btqwO6vv4to/7FPyH3g06HP63Pek7sw14k/img.jpg" alt="company"></img>
            </Stack>
            <Typography variant="h5" mt={2}>Product List</Typography>
            <Stack className="product-list">
                <Stack className="product-container">
                    <img className="product-img" src="https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016584535ko.jpg?l=ko" alt="product"></img>
                    <Stack className="add-cart-panel">
                        <Typography>Add Cart</Typography>
                        <IconButton><AddShoppingCartOutlinedIcon/></IconButton>
                    </Stack>
                </Stack>
                <Stack className="product-container">
                    <img className="product-img" src="https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0018/A00000018667201ko.jpg?l=ko" alt="product"></img>
                    <Stack className="add-cart-panel">
                        <Typography>Add Cart</Typography>
                        <IconButton><AddShoppingCartOutlinedIcon/></IconButton>
                    </Stack>
                </Stack>
                <Stack className="product-container">
                    <img className="product-img" src="https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0018/A00000018429310ko.jpg?l=ko" alt="product"></img>
                    <Stack className="add-cart-panel">
                        <Typography>Add Cart</Typography>
                        <IconButton><AddShoppingCartOutlinedIcon/></IconButton>
                    </Stack>
                </Stack>
                <Stack className="product-container">
                    <img className="product-img" src="https://image.oliveyoung.co.kr/uploads/images/goods/550/10/0000/0016/A00000016952415ko.jpg?l=ko" alt="product"></img>
                    <Stack className="add-cart-panel">
                        <Typography>Add Cart</Typography>
                        <IconButton><AddShoppingCartOutlinedIcon/></IconButton>
                    </Stack>
                </Stack>
            </Stack>
            
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default OpenSpaceHomePage;
  