/**************************************************************************************************
    FileName  : PostEditPage.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./PostEditPage.scss";
// Import for React
import { useEffect, useState, useRef } from "react";
// Import for Route
import { useNavigate, useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Button, TextField, Typography
} from "@mui/material";
// Import for Project Types
import { PostDetailData } from "types";
// Import for Project Base
import { api, apiCommon, appToast } from "libs/stdlib";

import SHSTextEditor, {TextEditorRefType} from "components/SHSText/SHSTextEditor";



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostWritePage Implementation                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
function PostWritePage() {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const { postId } = useParams();
    const nPostId = postId ? parseInt(postId) : -1;
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const navigate = useNavigate();
    const refTextEditor : TextEditorRefType = useRef(null);
    const [isDataLoad, setDataLoad] = useState<boolean>(false);

    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_updatePost(title? : string, description? : string, relativeFiles? : number[]) {
        await api.put(
          `/api/posts/${nPostId}`,
          {title, description, relativeFiles},
          ()=>{ navigate("/"); }
        );
    }

    useEffect(()=>{
        apiCommon.post.getPostDetail(nPostId, (post : PostDetailData)=>{            
            setTitle(post.title);
            setDescription(post.description);
            setDataLoad(true);
        });
        //eslint-disable-next-line
    }, []);

    // Editor가 생성될 때 파싱 및 데이터 로드가 시작되므로
    // 데이터가 로드된 후 Editor가 작동되도록 한다.     
    if( isDataLoad === false )
        return <></>;


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function onWritePostClick() {        
        const titleParam = title.trim();
        console.info("titleParam>>>", titleParam);
        if( titleParam.length === 0 )
            return appToast.error("제목을 입력해 주십시오.");
        
        const encodeData = await refTextEditor.current?.encode();
        
        await api_updatePost(titleParam, encodeData?.encodedText, encodeData?.relativeFiles);
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="postEditPage">
            <Typography className="title-label">제목 *</Typography>
            <TextField className="title-text" value={title} onChange={(e)=>setTitle(e.target.value)  }/>

            <Typography className="description-label">내용</Typography>
            <SHSTextEditor refEditor={refTextEditor} description={description} />            

            <Button className="btn-write" variant="outlined" onClick={onWritePostClick}>글수정</Button>
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default PostWritePage;
  