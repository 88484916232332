/**************************************************************************************************
    FileName  : SHSParser.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React, {useState, useEffect} from 'react';
import {parse, build} from '../SHSParser';
import { ComponentType, ViewerStyle, BuildItem } from "../Define";
import "../Components.scss";



import SHSCompositeText, {getCompositeTextType} from './SHSCompositeText';
import SHSImage, {getImageType} from './SHSImage';
import SHSYoutube, {getYoutubeType} from './SHSYoutube';
import { withResizeDetector } from 'react-resize-detector';
import SHSLink, {getLinkType} from './SHSLink';





const _componentFactory = new Map<string, any >();
function registerComponents() {
    _componentFactory.set( getCompositeTextType(), SHSCompositeText );
    _componentFactory.set( getImageType(), SHSImage );
    _componentFactory.set( getYoutubeType(), withResizeDetector(SHSYoutube) );
    _componentFactory.set( getLinkType(), SHSLink );

}
registerComponents();


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface ComponentData extends BuildItem {
    elementKey : number;
    viewerStyle? : ViewerStyle;
    onProvideImageData? : (data:any)=>void;
    domain? : string;
    onImageClick? : ()=>void;
    onMentionClick? : ()=>void;
    onLinkClick? : ()=>void;
}


interface TextViewerProps {
    description : string;
    viewerStyle? : ViewerStyle;
    onProvideImageData? : (data:any)=>void;
    domain? : string;
    onImageClick? : ()=>void;
    onMentionClick? : ()=>void;
    onLinkClick? : ()=>void;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

// onLinkClick
// viewerStyle {textColor, bodyMargin, imageBorderRadius, cardBackgroundColor, textFontFamily}
let __elementKey = 0;
export default function TextViewer(props : TextViewerProps) {

    const [components, setComponents] = useState<React.FunctionComponentElement<ComponentData>[]>([]);
    useEffect(()=>{
        // console.info("props>>>", props);
        const parsedItems = parse(props.description);
        // console.info("parsedItems>>>", parsedItems);
        const buildedItems = build(parsedItems);
        // console.info("buildedItems>>>", buildedItems);
        

        const newComponents : React.FunctionComponentElement<ComponentData>[] = [];        
        for( let i = 0;    i < buildedItems.length;    ++i ) {
            let data : ComponentData = {
                ...buildedItems[i],
                viewerStyle  : props.viewerStyle,
                elementKey : __elementKey++
            };
            

            switch( data.componentType ) {
                case ComponentType.image:                    
                    data.onProvideImageData = props.onProvideImageData;
                    data.domain = props.domain;
                    data.onImageClick = props.onImageClick;
                    break;
                case ComponentType.compositeText:
                    data.onMentionClick = props.onMentionClick;
                    data.onLinkClick = props.onLinkClick;
                    break;
                case ComponentType.link:
                    data.onLinkClick = props.onLinkClick;
                    break;                
                default:
                    break;
            }

            const componentTemplate = _componentFactory.get(data.componentType);            
            if( componentTemplate ) {
                let element = React.createElement(componentTemplate, data);
                newComponents.push(element);
            }
        }

        setComponents(newComponents);
        
    }, [props.description, props.domain, props.onImageClick, props.onLinkClick, props.onMentionClick, props.onProvideImageData, props.viewerStyle ]);

    return(
        <div className="shs-text-viewer-container">
        {
            components.map((element, index) => {                
                return <div key={element.props.elementKey}>{element}</div>
            })
        }
        </div>
    );
}
