import { FileInfo } from "./common";
import { BadgeInfo, BadgeType } from "./user";

export enum EnumAlarmImageShape {
    rectangle = "rectangle",
    circle = "circle"
}


export enum MessageEventName {
    response = "response",
    chatting = "chatting",
    sponsorship = "sponsorship",
    eventCategory = "event-category"
}
export enum SystemEventCategory {
    diabloItemTrade = "diablo-item-trade",    
}

export enum SystemEventSubCategoryDiabloItemTrade {
    tradeChattingRoomOpen = "trade-chatting-room-open",
    tradeComplete = "trade-complete",
    tradeOffer = "trade-offer",
    tradeFail = "trade-fail",
    tradeCancel = "trade-cancel",
    tradeItemUnregister = "trade-item-unregister"
}
export enum EnumTradeCategory {
    diablo2Item = 'd2-item'
}
export enum SystemEventSubCategoryPost {
    reply = 'post-reply',
    mention = 'post-mention-user',
    tag = 'post-tag-user',
}

export type SystemEventSubCategory = SystemEventSubCategoryDiabloItemTrade | SystemEventSubCategoryPost;

export interface EventCategoryData {
    eventCategory: SystemEventCategory;
    eventSubCategory: SystemEventSubCategory;
    fromUser: {
        badgeId: number;
        badgeType: BadgeType;
        userSdeId: number;
        displayName: string;
        uuid: string;
        profileImage: FileInfo;
    };
    isFromWithGroup: boolean;
    message: string;
    info?: unknown;
    imageUrl?: string;
    imageShape?: EnumAlarmImageShape;
}
export interface ResSocketChatData {
    fromUser: ResSocketFromUserInfo;
    roomId: number;
    messageId: number;
    message: string;
}
interface ResSocketFromUserInfo {
    email: string;
    sdeId: number;
    type: string;
    badgeInfo: BadgeInfo;    
    lastAccessTime?: number;    
    ip?: string;
}



export enum TradeChattingRoomState {
    open = "open",
    tradeComplete = "trade-complete",
    cancelBySeller = "cancel-by-seller",
    cancelByBuyer = "cancel-by-buyer",
    unregister = "unregister",
    close = "close",
}

export interface ChattingRoomMessage {
    id : number;
    badgeId : number;
    message : string;
    createTime : number;
}