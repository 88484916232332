/**************************************************************************************************
    FileName  : SHSParser.ts
    Description

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { ElementType, ComponentType, ParseElement, BuildItem, TextElement} from './Define';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
// [[%!!SHS>{'type' : 'inline-url', 'url' : 'www.daum.net', 'text' : '다음'}<SHS!!%]
const REGULAR_PREFIX =  /\[\[%!!SHS>/;
const REGULAR_POSTFIX =  /<SHS!!%]]/;

export const TagStartSymbol = '[[%!!SHS>';
export const TagEndSymbol = '<SHS!!%]]';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     parse Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////

function _parse(origin : string | null | undefined) : ParseElement[] {
    if(origin === null || origin === undefined)
        return [];        

    function splitComponentAndPlainText( org : string, out : ParseElement[] ) {
        let parts = org.split(REGULAR_POSTFIX);
        out.push( JSON.parse(parts[0]) );
        if( parts.length === 2 )
            addPlainText(parts[1], out);
    }

    function addPlainText(text : string, out : ParseElement[]) {
        if( !text ) return ;
        out.push(  {
            type : ElementType.text,
            text : text
        } as TextElement);
    }

    let result : ParseElement[] = [];
    const strings = origin.split(REGULAR_PREFIX);
    if( strings.length === 0 )
        return result;

    // first sentence is start plain text or not
    if( strings[0].match(REGULAR_POSTFIX) )
        splitComponentAndPlainText(strings[0], result);
    else
        addPlainText(strings[0], result);

    // from second sentence, it compose to SHS text component and planin text
    for(let i = 1;     i < strings.length;     ++i )
        splitComponentAndPlainText(strings[i], result);

    return result;
}

export const parse = _parse;


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     build Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////
export function build(parsedList : ParseElement[]) : BuildItem[] {
    const componentList : BuildItem[] = [];
    let compositeTextElements : ParseElement[] = [];

    function addPrevAndNewComponent(component : BuildItem | null) {
    	if( 0 < compositeTextElements.length  ) {
    		const newComponent = {                
    			items : compositeTextElements,
    			componentType : ComponentType.compositeText
    		};
			componentList.push(newComponent);
    		compositeTextElements = [];
    	}
    	if( component )
    		componentList.push(component);
    }

    for( let i = 0;    i < parsedList.length;    ++i ) {
        switch(parsedList[i].type) {
            case ElementType.text:
            case ElementType.inlineMention:            
                compositeTextElements.push(parsedList[i]);
            	break;

            case ElementType.image:                
            	addPrevAndNewComponent({
                    componentType : ComponentType.image,
                    ...parsedList[i]
                });
                break;
            case ElementType.link:            	
                addPrevAndNewComponent({
                    componentType : ComponentType.link,
                    ...parsedList[i]
                });
                break;
            case ElementType.gif:            	
                addPrevAndNewComponent({
                    componentType : ComponentType.gif,
                    ...parsedList[i]
                });
                break;
            case ElementType.youtube:
                addPrevAndNewComponent({
                    componentType : ComponentType.youtube,
                    ...parsedList[i]
                });
                break;
            default:
                break;
    	}
    }
    if( 0 < compositeTextElements.length )
    	addPrevAndNewComponent(null);

    return componentList;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     ETC Implementation                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
export function filterSHSText(encodedText : string, type : ElementType) {
    const parsedItems = _parse(encodedText);
    const filterList = [];
    for(let i = 0;  i < parsedItems.length; ++i  ) {
        if( parsedItems[i].type === type )
            filterList.push(parsedItems[i]);
    }
    return filterList;
}

export function encodeElement(type : ElementType, json : any) : string {
    if( json === null || json === undefined)
        return "";

    json.type = type;
    return TagStartSymbol + JSON.stringify(json)  + TagEndSymbol;
}

// Link Type 을 기존 데이터가 preview 로 다시 감싸서 저장해서 해당 포맷을 지킨다.
// 저장하는 방식에 일관선을 지키자.
// 만들 당시에는 아무 생각없이 사소한것 같지만 데이터가 많이 저장되었을때는 해당 규칙이 중요하다.
export function encodeLinkElement(json : JSON) : string {
    if( json === null || json === undefined)
        return "";

    return TagStartSymbol + JSON.stringify({type : ElementType.link, preview : json})  + TagEndSymbol;
}


export function encodePlainText(text : string) : string{
    return text;
}

