/**************************************************************************************************
    FileName  : MyInfo.ts
    Description
      My Info Singleton Class

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import DataStorage from "./DataStorage";
import ReduxStore from 'redux/store';
import { updateMyInfo } from 'redux/actions';
import { MyInfoData, MyBadgeInfo } from 'types';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Constants                                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
const PACKAGE_NAME = 'MyInfo';
const MY_INFO_KEY = 'MyInfo';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
class MyInfo {
    private _myInfo: MyInfoData | null;

    constructor() {
        this._myInfo = DataStorage.getInstance().get(PACKAGE_NAME, MY_INFO_KEY, null);
        if( this._myInfo !== null ) 
            ReduxStore.dispatch(updateMyInfo(this._myInfo));
    }

    public setMyInfo(myInfo: MyInfoData) {
        this._myInfo = myInfo;
        console.info("myInfo>>>>", myInfo);
        DataStorage.getInstance().set(PACKAGE_NAME, MY_INFO_KEY, myInfo);
        ReduxStore.dispatch(updateMyInfo(myInfo));
    }
    public deleteMyInfo() {
        this._myInfo = null;
        DataStorage.getInstance().delete(PACKAGE_NAME, MY_INFO_KEY);
        ReduxStore.dispatch(updateMyInfo(null));
    }
    
    public get(): MyInfoData | null{
        return this._myInfo;
    }
    

    public isLogin(): boolean {
        return this._myInfo !== null;
    }

    public getBadgeInfo(): MyBadgeInfo {        
        if (this._myInfo === null) {
            throw new Error('my info is null');
        }
        return this._myInfo.badgeInfo;
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new MyInfo();
export default _instance;