/**************************************************************************************************
    FileName  : post.ts
    Description : 
      Post Type Definitions

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import {BadgeType} from "./user";
import {ThreadInfo} from "./space"
import { FileInfo } from "./common";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

export enum EnumPostVote {
    upvote = 'U',
    downvote = 'D',
    neutral = '-'
}

export enum EnumPostState {
    open = "open",
    closed = "closed",
    archived = "archived"
}



export enum EnumPostType {
    default = "default",    
}



export enum EnumPostListSortType {
    popular = 'popular',
    latest = 'latest',
    oldest = 'oldest',
}

export enum EnumCommentListSortType {
    oldest = 'oldest',
    latest = 'latest'
}





export interface PostWriterInfo {
    badgeId: number;
    displayName: string;
    badgeType: BadgeType;
    profileImage : FileInfo;
}




export interface PostListItemData {
    id : number;
    type : EnumPostType;
    threadId : number | null;
    threadInfo : ThreadInfo | null;
    title : string;
    description : string;
    writerInfo : PostWriterInfo;
    upvoteCount : number;
    downvoteCount : number;
    myVote : EnumPostVote | null;
    parentPostId : number | null;
    childCount :  number;        
    state : EnumPostState;
    createTime : number;    // UTC TIME
}

export interface PostDetailData extends PostListItemData {
    
}