/**************************************************************************************************
    FileName  : APIRequester.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import axios, {AxiosInstance, AxiosResponse} from 'axios';
import DataStorage from 'modules/DataStorage';



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export const DOMAIN: string = process.env.REACT_APP_SERVER_API_DOMAIN || "";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum HttpMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete'
};

export interface ServerDefinedError {
    code: number;
    detailMessage: string;
    msg: string;
};



///////////////////////////////////////////////////////////////////////////////////////////////////
//                            APIRequester Class Implementation                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////
class APIRequester {
    private _axiosInst : AxiosInstance;

    constructor() {
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: 10000
        });
        const token = DataStorage.getInstance().get("API", "token", undefined);
        if( token !== undefined )
            this.setToken(token);

        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    private _checkHttpBodyIsHtmlDocument(body: any ) {
        if (typeof body === 'string' && body.includes('<html') === true) {
            throw Error('404 Not Found');
        }
    }

    
    private _isServerDefinedError(data: any): boolean {
        return data !== undefined && data !== null 
            && typeof data.code === 'number'
            && typeof data.msg === 'string'
            && typeof data.detailMessage === 'string'
    }

    
    public setToken(token : string) {        
        DataStorage.getInstance().set("API", "token", token);        
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: 10000,
            headers : {
                common : {
                    'Authorization' : token
                }
            }
        });        
    }

    public clearToken() {        
        DataStorage.getInstance().delete("API", "token");        
        this._axiosInst = axios.create({
            baseURL: DOMAIN,
            timeout: 10000,            
        });        
    }

    


    public async get(url: string): Promise<{item?: any, items?: any[]}> {        
        return await this.request( HttpMethod.get, url);
    }
    public async post(url: string, reqData?: any): Promise<{item?: any, items?: any[]}> {
        return await this.request( HttpMethod.post, url, reqData);
    }
    public async put(url: string, reqData?: any): Promise<{item?: any, items?: any[]}> {
        return await this.request( HttpMethod.put, url, reqData);
    }
    public async delete(url: string): Promise<{item?: any, items?: any[]}> {
        return await this.request( HttpMethod.delete, url );
    }

    public async request(method: HttpMethod, url: string, reqData?: any): Promise<{item?: any, items?: any[]}> {
        let axiosResponse: AxiosResponse;

        if (process.env.REACT_APP_BUILD_MODE !== 'production') {            
            console.log(`\n👉     call - %c${method.toUpperCase()} %c${url}`, 'color: #9772FB; font-weight: bold;', '');
            console.log(`      token - ${this._axiosInst.defaults.headers.common['Authorization']}`);
            console.info(`       body - `, reqData);
            console.log('\n')
        }

        try {
            switch (method) {
                case HttpMethod.get:
                    axiosResponse = await this._axiosInst.get(url);
                    break;

                case HttpMethod.post:
                    axiosResponse = await this._axiosInst.post(url, reqData);
                    break;

                case HttpMethod.put:
                    axiosResponse = await this._axiosInst.put(url, reqData);
                    break;

                case HttpMethod.delete:
                    axiosResponse = await this._axiosInst.delete(url);
                    break;
            }

            // console.info("axiosResponse", axiosResponse);
            this._checkHttpBodyIsHtmlDocument(axiosResponse.data);

            return axiosResponse.data.shsData;

        } catch (error : any) {            
            let err: any = error;
            if (axios.isAxiosError(error)) {
                
                const responseData: any = error.response?.data;

                if (responseData !== undefined && responseData !== null) {
                    let message = '';

                    if (this._isServerDefinedError(responseData)) {
                        const serverDefinedErr: ServerDefinedError = responseData;
                        
                        // 2000 = 세션 만료
                        if (serverDefinedErr.code === 2000) {
                            // 핫 리로드에 의해 아래 조건이 실행되면 개발 환경에서 계속 로그아웃 처리가 되기 때문에 production에서만 실행하도록 함.
                            if (process.env.REACT_APP_BUILD_MODE === 'production') {
                                // source.cancel();
                                window.location.href = '/login';
                                throw Error('로그인이 필요합니다.');
                            } else {
                                throw Error('이 에러 무시하세요 (개발 환경에서만 발생)');
                            }
                        } else {
                            message = `${serverDefinedErr.code}, ${serverDefinedErr.msg}`;
                        }
                        
                    } else {
                        message = err.message;
                    }
                    err = {
                        message,
                        ...responseData
                    };
                }

            } else {
                console.log('💀 unexpected error');
            }                        
            throw err;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const apiInstance = new APIRequester();
export default apiInstance;
