/**************************************************************************************************
    FileName  : PostList.tsx
    Description

    Update History 
	  2023.06     BGKim     Mobile
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./PostList.scss";
// Import for React
import {useState, useEffect} from 'react';
// Import for Route
import { useNavigate } from "react-router-dom";
// Import for Material UI
import {
  Stack, Menu, MenuItem, Box
} from "@mui/material";
// Import for Project Types
import {  
  PostListItemData, DefaultList
} from "types";
// Import for Project Base
import {utils, api, DOMAIN} from "libs/stdlib";


import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CommentIcon from '@mui/icons-material/Comment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SHSTextViewer from "./SHSText/SHSTextViewer";
  


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostList Implementation                                      //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface PostListPros {
  threadName : string;  
  notifyGoDetailPageClick? : (post : PostListItemData)=>void    
}

export default function PostList( props : PostListPros) {
	/////////////////////////////////////////////////////////////////////////////
	// Const
	const [postList, setPostList] = useState<PostListItemData[]>([]);
	const [refreshCount, setRefreshCount] = useState<number>(0);
	const navigate = useNavigate();
	
	// for menu
	const [selectedContextMenuPost, setSelectedContextMenuPost]  = useState<PostListItemData | null>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const contextMenuCssOptions = {minWidth:"90px", justifyContent:"center"};


	/////////////////////////////////////////////////////////////////////////////
	// API
	async function api_getPostList(threadName? : string) {
		const lastItemId = -1;
		const numOfPage = 100;
		const sortType = "latest";

		if( threadName ) {
		await api.get(
			`/api/threads/${threadName}/posts?lastItemId=${lastItemId}&numOfPage=${numOfPage}&sortType=${sortType}`,
			(data : DefaultList)=>{
			setPostList(data.list);
			}, null
		);
		}
		
	}

	async function api_deletePost(postId : number) {    
		await api.delete(`/api/posts/${postId}`, ()=>{
		for( let i = 0;   i < postList.length;  ++i) {
			if( postList[i].id === postId ) {
			postList.splice(i, 1);
			setRefreshCount(refreshCount+1);
			return ;
			}
		}
		});
	}

  


	/////////////////////////////////////////////////////////////////////////////
	// Hooks
	useEffect(()=>{
		(async ()=>{ await api_getPostList(props.threadName); } )();
		//eslint-disable-next-line        
	},[]);


	/////////////////////////////////////////////////////////////////////////////
	// Functions
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, post : PostListItemData) => {
		setAnchorEl(event.currentTarget);
		setSelectedContextMenuPost(post);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setSelectedContextMenuPost(null);
	};

	function onMenuPostEditClick () {
		setAnchorEl(null);
		navigate(`/posts/${selectedContextMenuPost!.id}/edit`);
	}

	async function onMenuPostDeleteClick () {
		await api_deletePost(selectedContextMenuPost!.id);
	}

	function notifyGoDetailPageClick(post : PostListItemData) {
		if(props.notifyGoDetailPageClick)
		props.notifyGoDetailPageClick(post);
	}


	/////////////////////////////////////////////////////////////////////////////
	// UI
	return (
		<Stack className='post-list'>
			{postList && 0 < postList.length && postList.map((post : PostListItemData)=>{
				return (                
					<PostListItem 
					post={post}
					notifyGoDetailPageClick={()=>{notifyGoDetailPageClick(post)}}
					notifyMoreMenuClick={handleClick}
					key={post.id}
					/>
				);
			})}

			<Menu
			className="post-context-menu"
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
			
			>
			<MenuItem onClick={onMenuPostEditClick} sx={contextMenuCssOptions}>수정</MenuItem>
			<MenuItem onClick={onMenuPostDeleteClick} sx={contextMenuCssOptions}>삭제</MenuItem>          
			</Menu>
	
		</Stack>
	);
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostListItem Implementation                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface PostListItemProps {
    post : PostListItemData;
    notifyGoDetailPageClick : (post : PostListItemData)=>void,
    notifyMoreMenuClick : (event: React.MouseEvent<HTMLButtonElement>, post : PostListItemData)=>void
}

function PostListItem(props : PostListItemProps) {      
    
  /////////////////////////////////////////////////////////////////////////////
  // UI  
  return (
      <Card className="post-list-item">
        <CardHeader
          avatar={
            <img className="user-profile-image" src={DOMAIN + props.post.writerInfo.profileImage.sm!} alt="writer-profile"/>
          }
          action={
            <IconButton aria-label="settings" onClick={(e)=>props.notifyMoreMenuClick(e, props.post) }>
              <MoreVertIcon />
            </IconButton>
          }
          title={props.post.writerInfo.displayName}
          subheader={utils.getDateString(props.post.createTime)}
        />        
        <CardContent className="description-container">
          <Typography className="post-title" variant="h6" onClick={()=>props.notifyGoDetailPageClick(props.post)}>{props.post.title}</Typography>          
		  <Box className="post-description" onClick={()=>props.notifyGoDetailPageClick(props.post)}>
		  	<SHSTextViewer description={props.post.description}/>
		  </Box>
		  <Box className="description-overlay"/>
        </CardContent>
        <CardActions disableSpacing>
          <Box className="comment-info-container">
            <CommentIcon />
            <Typography className="comment-count">{props.post.childCount}</Typography>
          </Box>          
        </CardActions>        
      </Card>
  );
}