/**************************************************************************************************
    FileName  : AppNavigator.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { EnumSpaceType } from "types";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                            BaseNavigatorDelegate Class Implementation                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
class BaseNavigatorDelegate {
    private _navigate: NavigateFunction | null = null;

    public setNavigateForAppNavigate(navigate: NavigateFunction) {
        this._navigate = navigate;
    }

    protected navigate(urlOrDelta: string | number, options?: NavigateOptions) {
        if (this._navigate === null) {
            setTimeout(() => this.navigate(urlOrDelta, options), 100);
        } else {
            if (typeof urlOrDelta === 'string') {
                this._navigate(urlOrDelta as string, options);
            } else {
                this._navigate(urlOrDelta as number);
            }
        }
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                            AppNavigator Class Implementation                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AppNavigator extends BaseNavigatorDelegate {
    public diablo4 = new Diablo4Navigator();
    public auth = new AuthNavigator();
    

    public init(navigate: NavigateFunction) {
        this.setNavigateForAppNavigate(navigate);
        this.diablo4.setNavigateForAppNavigate(navigate);
        this.auth.setNavigateForAppNavigate(navigate);
    }
    
    public go(url : string, replace? : boolean) {
        this.navigate(url, { replace });
    }

    public goBack() {
        this.navigate(-1);
    }

    public goHome() {
        this.navigate('/');
    }

    // 로그인 페이지는 자주 쓰이므로 기본 Navigator에 포함한다.
    public goLoginPage() {
        this.navigate('login');
    }

    public goOpenSpaceHome(spaceName : string) {
        this.navigate(`/open-spaces/${spaceName}`);
    }

    public goGroupSpaceHome(spaceName : string) {
        this.navigate(`/group-spaces/${spaceName}`);
    }

    public goPostThread(spaceType : EnumSpaceType, spaceName : string, threadName : string) {        
        const spaceTypetypeUrl = spaceType === EnumSpaceType.open ? "open-spaces" : "group-spaces";
        this.navigate(`/${spaceTypetypeUrl}/${spaceName}/threads/${threadName}`);
    }

    public goPostWrite(spaceType : EnumSpaceType,  spaceName : string, threadName : string) {
        const spaceTypetypeUrl = spaceType === EnumSpaceType.open ? "open-spaces" : "group-spaces";
        this.navigate(`/${spaceTypetypeUrl}/${spaceName}/threads/${threadName}/write`);
    }

    public goUserAgreement() {
        this.navigate(`/user-agreement`);
    }

    public goPrivatePolicy() {
        this.navigate(`/private-policy`);
    }

    public goGuestOrderInqueryPage() {
        this.navigate(`/instance-space/guest-order-inquiry`);
    }

    public goInstanceSpacePage(spaceName : string) {
        this.navigate(`/instance-spaces/${spaceName}`);
    }
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                             Auth Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

class AuthNavigator extends BaseNavigatorDelegate {
    public goRequestSignupPage() {
        this.navigate('/signup/request');
    }    
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                            Diablo4Navigator Class Implementation                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

class Diablo4Navigator extends BaseNavigatorDelegate {
    public goTradeChattingRoom(tradeId : number, roomId : number) {
        this.navigate(`/open-spaces/diablo4/trades/${tradeId}/rooms/${roomId}`);
    }

    public goCreateStore() {
        this.navigate('/open-spaces/diablo4/store');
    }

    public goStore(storeName : string) {
        this.navigate(`/open-spaces/diablo4/stores/${storeName}`);
    }

    public goEditStore(storeName:string) {
        this.navigate(`/open-spaces/diablo4/stores/${storeName}/edit`);
    }

    public goRegisterItem(storeName : string) {
        this.navigate(`/open-spaces/diablo4/stores/${storeName}/trade`);
    }

    public goEditTrade(storeName : string, tradeId : number) {
        this.navigate(`/open-spaces/diablo4/stores/${storeName}/trades/${tradeId}/edit`);
    }

    public goTradeHome() {
        this.navigate(`/open-spaces/diablo4/trades`);
    }
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new AppNavigator();
export default _instance;