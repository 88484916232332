/**************************************************************************************************
    FileName  : OpenSpaceHomePage.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./OpenSpaceHomePage.scss";
// Import for React
import { useEffect } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Box, Typography,
} from "@mui/material";
// Import for Project Types
import { EnumOpenSpace } from "types";
// Import for Project Base
import { appNavigator, DOMAIN, pathTracker } from "libs/stdlib";
import ThreadPostAndDetail from "pages/ThreadPostAndDetail";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpaceHome Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface OpenSpaceHomePageProps  {
    spaceName? : string;
}


function OpenSpaceHomePage(props : OpenSpaceHomePageProps) {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const urlParams = useParams();
    let spaceName : string = EnumOpenSpace.spacehub;
    if( props.spaceName ) {
        spaceName = props.spaceName;
    } else if( urlParams.spaceName ) {
        spaceName = urlParams.spaceName;
    }
    const threadName = spaceName;

    /////////////////////////////////////////////////////////////////////////////
    // API

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        pathTracker.buildStart()
            .add(spaceName, ()=>appNavigator.goOpenSpaceHome(spaceName) )
            .build();
        //eslint-disable-next-line
    }, []);

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function getBannerUrl() {
        return `/resources/space/${spaceName}_banner.png`

    }


    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="openSpaceHomePage-230619">            
            <Box className="space-info-container">
                <img className="banner" src={DOMAIN + getBannerUrl()} alt="banner"></img>                
                <Box className="banner-overlay"></Box>
                <Stack className="space-name-panel">                    
                    <Typography className="space-name" variant="h5">{spaceName}</Typography>
                </Stack>                
            </Box>
            
            <ThreadPostAndDetail threadName={threadName}/>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default OpenSpaceHomePage;
  