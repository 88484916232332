/**************************************************************************************************
    FileName  : Login.tsx
    Description

    Update History 
      2023.06     BGKim     Mobile
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./Login.scss";

import {useState} from 'react';

import { Stack, TextField, Typography, Button } from "@mui/material";
import { appNavigator } from 'libs/stdlib';
import {appToast, utils, api} from "libs/stdlib";
import authManager from "modules/AuthManager"


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Home Implementation                                          //
///////////////////////////////////////////////////////////////////////////////////////////////////
function Login() {
    /////////////////////////////////////////////////////////////////////////////
    // Const
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");


    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_getUserBadges(email : string, password : string) {
        return await api.post(
            `/api/user/auth/badges`,
            {email, password},
            (data)=>data
        )
    }


    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function onLogoClick() {
        appNavigator.goHome();
    }


    async function onLoginClick() {
        const emailParam = email.trim();        
        if( emailParam.length === 0 ) {
            return appToast.error("이메일을 입력해 주십시오");
        } else if( utils.isEmailFormat(email) ===false ) {
            return appToast.error("이메일 형식이 올바르지 않습니다.");
        }

        const passwordParam = password.trim();
        if( passwordParam.length === 0 ) {
            return appToast.error("패스워드를 입력해 주십시오");
        }

        
        const badges = await api_getUserBadges(emailParam, passwordParam);
        if( badges.length === 1) {
            await authManager.login(email, password, badges[0].badgeId);
            appNavigator.goBack();
        } else {
            // 임시 - 추후 Badge 선택 화면이 나와야 함
            console.info("badges>>>>", badges);
            await authManager.login(email, password, badges[0].badgeId);
            appNavigator.goBack();
        }
    }

    function onSignupClick() {
        appNavigator.auth.goRequestSignupPage();
    }

    
    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="loginPage-230531">
            <Typography variant="h3" className="site-title" onClick={onLogoClick}>SpaceHub Society</Typography>
            <Stack className="login-panel">
                <TextField 
                    className="input-email"
                    variant="standard" 
                    label="이메일" 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                />                
                <TextField 
                    variant="standard" 
                    label="비밀번호" 
                    className="input-password" 
                    type="password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                />
                                    
                <Button variant="contained" onClick={onLoginClick}>로그인</Button>
            </Stack>
            <Stack className="signup-panel">
                <Typography variant="body2" className="btn-signup" onClick={onSignupClick}>회원가입</Typography>
            </Stack>
        </Stack>
    );
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default Login;