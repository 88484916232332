export default class Utils {
    public isIncludeSpace(s : string)  : boolean {
        return s.includes(" ");
    }

    public numberWithCommas(x : number) : string {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public isEmailFormat(email : string) {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());            
    }
}



