/**************************************************************************************************
    FileName  : MainAppBar.tsx
    Description

    Update History 
	  2023.06     BGKim     Mobile UI
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./MainAppBar.scss";
// Import for React
import { Fragment, useState } from "react";
// Import for Material UI
import { 
	AppBar, Stack, Box, Toolbar, Button, Typography,
	Menu, MenuItem, IconButton, Hidden
} from '@mui/material';
// Import for Redux
import { useSelector } from 'react-redux'
import ReduxStore from 'redux/store';
import { showSideBar } from 'redux/actions';
// Import for Project Types
import { EnumOpenSpace, EnumOpenThread, MyInfoData } from "types";
// Import for Project Base
import { appNavigator, config, DOMAIN, utils } from "libs/stdlib";
import PathTracker from "modules/PathTracker";
import authManager from "modules/AuthManager";

import MenuIcon from '@mui/icons-material/Menu';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  AppBar Implementation                                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function SpaceHubAppBar() {
	/////////////////////////////////////////////////////////////////////////////
	// Const
	const myInfo : MyInfoData | null = useSelector((state: any) => state.user);
	

	// for menu    
    const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
    const isUserMenuOpen = Boolean(anchorUserMenu);
    const contextMenuCssOptions = {minWidth:"90px", justifyContent:"center"};
	

	/////////////////////////////////////////////////////////////////////////////
  	// Functions
	async function onTempAutoLoginClick() {
		await authManager.login(config.tempLoginEmail, config.tempLoginPassword, config.tempLoginBadgeId);
	}

	
	function onPostWriteClick() {
		const { pathSpaceType, pathSpaceName, pathThreadName } = utils.getSpaceAndThreadInfo();
		const spaceName = pathSpaceName ? pathSpaceName : EnumOpenSpace.spacehub;
		let threadName : string = EnumOpenThread.spacehub;
				
		if( pathThreadName ) {
			threadName = pathThreadName;
		} else if( pathSpaceName ) {
			threadName = pathSpaceName;		
		}

		appNavigator.goPostWrite(pathSpaceType, spaceName, threadName);
	}

	function onUserMenuClick(event: React.MouseEvent<HTMLImageElement>) {		
		setAnchorUserMenu(event.currentTarget);
	}


	function handleCloseUserMenu() {
        setAnchorUserMenu(null);		
    };

	function onLoginClick() {
		appNavigator.goLoginPage();
	}

	function onLogoutClick() {
		authManager.logout();
	}



	/////////////////////////////////////////////////////////////////////////////
	// UI
	return (
		<Box id="spacehubMainAppBar">
			<AppBar position="static">
				<Toolbar>
					<Hidden mdUp>
						<IconButton onClick={()=>ReduxStore.dispatch(showSideBar(true))}><MenuIcon/></IconButton>
					</Hidden>
					<PathTrackerComponent/>		
					<Box className="flex-grow" />

					<Hidden mdDown>						
						{process.env.REACT_APP_BUILD_MODE !== 'production' &&
							<Button onClick={onTempAutoLoginClick}>로그인 BG</Button>
						} 
						{myInfo && <Button sx={{marginRight:"8px"}} onClick={onPostWriteClick}>글쓰기</Button> }
					</Hidden>
					

					{myInfo && <img className="user-profile" alt="user-profile" src={DOMAIN + myInfo.badgeInfo.profileImage.sm!} onClick={(e)=>onUserMenuClick(e)}></img> }
					{!myInfo && <Button onClick={onLoginClick} >Login</Button> }
				</Toolbar>
			</AppBar>
			<Menu
                    anchorEl={anchorUserMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={isUserMenuOpen}
                    onClose={handleCloseUserMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
					sx={{marginTop:"8px"}}
                >
                    <MenuItem sx={contextMenuCssOptions} onClick={onLogoutClick}>로그아웃</MenuItem>
                </Menu>
		</Box>
	);
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                               PathTrackerComponent Implementation                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
function PathTrackerComponent() {
	/////////////////////////////////////////////////////////////////////////////
	// Const
	const pathTrackerTextList : string[] = useSelector((state: any) => { return state.misc.pathTrackerTextList} );

	/////////////////////////////////////////////////////////////////////////////
	// Functions
	function onPathClick(index : number) {    
		if( index < pathTrackerTextList!.length - 1 )
		PathTracker.getInstance().execute(index);
	}

	/////////////////////////////////////////////////////////////////////////////
	// UI
	return (
		<Stack className="path-tracker-container">      
		{pathTrackerTextList && pathTrackerTextList.map((text, index)=>{
			return (
				<Fragment key={index}>
					<Typography className="path-label" onClick={()=>onPathClick(index)}>{text}</Typography>
					{index < pathTrackerTextList.length - 1  &&
						<Typography className="path-symbol">&gt;</Typography>
					}				
				</Fragment>          
			);
		})}
		</Stack>
	)
}

