/**************************************************************************************************
    FileName  : SignupEmail.tsx
    Description

    Update History 
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./SignupEmail.scss";

import {useEffect, useRef, useState} from 'react';

import { Stack, TextField, Typography, Button } from "@mui/material";
import { HttpMethod, appNavigator } from 'libs/stdlib';
import {appToast, api, utils} from "libs/stdlib";
import { useParams } from "react-router-dom";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Home Implementation                                          //
///////////////////////////////////////////////////////////////////////////////////////////////////
function Signup() {
    const urlParam = useParams();    
    const [isValidPage, setIsValidPage] = useState<boolean>(urlParam.signupKey !== undefined);
    const [email, setEmail] = useState<string>("");
    const [displayName, setDisplayName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");
    const isCheckDuplicate = useRef<boolean>(false);


    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_getSignupInfo(signupKey : string) {
        await api.request(
            HttpMethod.get,
            `/api/user/sign-up/${signupKey}`,
            null,
            function onSuccess(data) {
                console.info("data", data);
                setEmail(data.email);
            }, function onError() {
                setIsValidPage(false);
            }
        )         
    }

    async function api_requestSignupEmail(signupKey : string, displayName : string, password : string) {
        await api.post(
            "/api/user/sign-up/email",
            {signupKey, password, displayName},
            ()=>{
                appToast.success("회원 가입에 성공했습니다.");
                appNavigator.goHome();
            }
        );
    }

    async function api_checkDisplayName(displayName : string) {
        await api.post(
            "/api/user/displayname/check",
            {displayName},
            (data)=> {
                if( data.isExists === true ) {
                    appToast.error("이미 존재하는 이름입니다.");
                }
                else{
                    appToast.success("사용 가능한 이름입니다.");
                    isCheckDuplicate.current = true;
                }                
            } 
        )        
    }


    useEffect(()=>{
        (async()=>{
            if( urlParam.signupKey )
                await api_getSignupInfo(urlParam.signupKey);
        })();
    }, [urlParam.signupKey]);



    /////////////////////////////////////////////////////////////////////////////
    // Functions    
    function onDisplayNameChange(event :  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) {
        isCheckDuplicate.current = false;
        setDisplayName(event.target.value);
    }

    async function onCheckDisplayNameClick() {
        if( utils.isIncludeSpace(displayName) === true )
            return appToast.error("표시 이름은 공백을 포함할 수 없습니다.");

        await api_checkDisplayName(displayName);
    }

    async function onSignupClick() {
        if( isCheckDuplicate.current === false )
            return appToast.error("표시이름 중복 확인을 해 주십시오.");
        
        const passwordParam = password.trim();
        if( passwordParam.length === 0 ) {
            return appToast.error("암호를 입력해 주십시오.");
        }

        const passwordRepeatParam = passwordRepeat.trim();
        if( passwordRepeatParam.length === 0 )
            return appToast.error("비밀번호 재입력을 해 주십시오.");
        else if( passwordParam !== passwordRepeatParam ) 
            return appToast.error("비밀번호와 재입력한 비밀번호가 다릅니다.");
        
        
        await api_requestSignupEmail(urlParam.signupKey!, displayName.trim(), passwordParam)
    }



    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="signupPageEmail-230602">
            <Typography variant="h4" className="site-title">회원가입</Typography>

            {isValidPage &&
                <Stack className="signup-panel">
                    <TextField 
                        className="email"
                        variant="standard" 
                        label="이메일"
                        value={email}
                        disabled
                    />



                    <Stack className="nickname-panel">
                        <TextField 
                            className="nickname-input"
                            variant="standard" 
                            label="표시이름"
                            value={displayName}                        
                            onChange={onDisplayNameChange}
                        />
                        <Button className="btn-check" variant="outlined" onClick={onCheckDisplayNameClick}>중복확인</Button>
                    </Stack>
                    
                    <TextField 
                        className="input-password"
                        variant="standard" 
                        label="비밀번호"
                        value={password}
                        type="password"
                        onChange={(e)=>setPassword(e.target.value)}
                    />

                    <TextField 
                        className="input-password-repeat"
                        variant="standard" 
                        label="비밀번호 재입력"
                        value={passwordRepeat}  
                        type="password"
                        onChange={(e)=>setPasswordRepeat(e.target.value)}
                    />
                    
                    <Button className="btn-signup" variant="contained" onClick={onSignupClick}>회원가입</Button>
                </Stack>
            }
            {!isValidPage &&
                <Stack>
                    <Typography variant="h5" mt={4}>페이지가 유효하지 않습니다.</Typography>
                </Stack>
            }
        </Stack>
    );
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default Signup;