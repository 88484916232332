/**************************************************************************************************
    FileName  : AuthManager.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import apiRequester from "libs/api/APIRequester";
import myInfo from "modules/MyInfo";
import SocketManager from "modules/SocketManager";

const WEB_SOCKET_SERVER = process.env.REACT_APP_SERVER_WEBSOCKET || "ws://spacehub.com:42023";

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AuthManager{
    public async login(email : string, password : string, badgeId : number) {
        // Request Login
        const res = await apiRequester.post("/api/user/login", {email, password, badgeId});

        const token = res.item.token;

        // set API Token
        apiRequester.setToken(token);

        // Connect Socket
        SocketManager.getInstance().login(WEB_SOCKET_SERVER, token);

        // Set My Info
        myInfo.setMyInfo(res.item.userInfo);
    }


    public logout() {
        // clear api request
        apiRequester.clearToken();

        // Disconnect socket
        SocketManager.getInstance().logout();

        // Clear My Info
        myInfo.deleteMyInfo();        
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new AuthManager();
export default _instance;