/**************************************************************************************************
    FileName  : SpaceHome.tsx
    Description

    Update History 
	  2023.06     BGKim     Mobile
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./ThreadPostAndDetail.scss";
// Import for React
import { useState } from "react";
// Import for Material UI
import {
    Grid, Stack, Divider, Typography, IconButton
} from "@mui/material";
// Import for Project Types
import { PostListItemData, PostDetailData, EnumOpenThread } from "types";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Project Base
import { apiCommon } from "libs/stdlib";
import PostList from "components/PostList";
import PostDetail from "components/PostDetail";
import CommentList from "components/CommentList";

import CloseIcon from '@mui/icons-material/Close';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                           ThreadPostAndDetail Implementation                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface ThreadPostAndDetailProps {
	spaceName? : string;
	threadName? : string;
}

function ThreadPostAndDetail(props : ThreadPostAndDetailProps) {
	/////////////////////////////////////////////////////////////////////////////
	// const  
	const [postDetail, setPostDetail] = useState<PostListItemData|null>(null);

	const urlParams = useParams();
	let threadName : string = EnumOpenThread.spacehub;
	if( props.threadName ) {
		threadName = props.threadName;
	} else if(urlParams.threadName) {
		threadName = urlParams.threadName;    
	}

	/////////////////////////////////////////////////////////////////////////////
	// API
	async function api_getPostDetail(postId : number) {    
		await apiCommon.post.getPostDetail(postId,
			(data : PostDetailData)=>{				
				setPostDetail(data);
			}, null
		);
	}  

	/////////////////////////////////////////////////////////////////////////////
	// Functions
	async function notifyGoDetailPageClick(post : PostListItemData) {    
		await api_getPostDetail(post.id);
	}

	function onClosePostDetailClick() {
		setPostDetail(null);
	}

	/////////////////////////////////////////////////////////////////////////////
	// UI
	return (
	<Grid className="space-post-and-detail-230512" container>
		<Grid className="post-list-wapper" item xs={12} sm={12} md={4}>
			<PostList threadName={threadName} notifyGoDetailPageClick={notifyGoDetailPageClick} ></PostList>
		</Grid>

		{postDetail &&
			<Grid className="post-detail-wapper" item sm={12} md={8}>
				<PostDetailPage post={postDetail}/>
				<IconButton className="btn-close-detail" onClick={onClosePostDetailClick}><CloseIcon/></IconButton>
			</Grid>
		}
		
	</Grid>
	);
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//  	                         PostDetailPage Implementation                                   //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface PostDetailPros{
	post : PostDetailData;    
}

function PostDetailPage(props : PostDetailPros) {  
	return (
		<Stack>
			<PostDetail post={props.post}/>
			<Divider className="commnet-section-divider"/>
			<Typography variant="h5" >COMMENTS</Typography>
			<CommentList rootPostId={props.post.id} />
		</Stack>
	);
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default ThreadPostAndDetail;