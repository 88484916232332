/**************************************************************************************************
    FileName  : SHSTextViewer.ts
    Description

    Update History 	  
      2023.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { FileInfo } from "types";
import TextViewer from "./ViewerComponents/Viewer";
import { DOMAIN, api } from 'libs/stdlib';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface SHSTextViewerProps {
    description : string;            
    onImageClick? : ()=>void;
    onMentionClick? : ()=>void;
    onLinkClick? : ()=>void;
}

export default function SHSTextViewer(props : SHSTextViewerProps) {
    async function onProvideImageData(fileId : number) : Promise<FileInfo> {
        const res = await api.get(`/api/files/${fileId}`, (data)=>data );        
        return res;
    }
    
    return(
        <TextViewer
            description={props.description}
            domain={DOMAIN}
            onProvideImageData={onProvideImageData}
        />
    );
}
