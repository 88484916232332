/**************************************************************************************************
    File Name   : DataStorage.ts
    Description :
      프로젝트 크기가 커질수록 스토리지에 저장하는 키가 중복되어 의도치 않은 버그가 발생할 수 있다.. 
      이에 각 모듈별로 각자의 저장 공간을 침범하지 않도록 package 이름을 추가로 받아 해당 버그를 방지한다.
      LocalStorage, SessionStorage, Memory 로 구분하여 저장한다.
      
      
    Usage:
      Local Storage :
      DataStorage.getInstace().get("packageName", "Key", null);
      DataStorage.getInstace().set("packageName", "Key", {fcmToken, deviceType});      
      Session Storage : 
      DataStorage.getInstace().session.get("packageName", "Key", null);
      DataStorage.getInstace().session.set("packageName", "Key", {fcmToken, deviceType});
      Memory : 
      DataStorage.getInstace().memory.get("packageName", "Key", null);
      DataStorage.getInstace().memory.set("packageName", "Key", {fcmToken, deviceType});
      
    Update History
      2023.05            BGKim       Create 
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Import Modules                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Constants                                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _memory = new Map();

function _getKey(packName : string, key : string) {
    return packName + "_" + key;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                            DataStorage Class Implementation                                   //
///////////////////////////////////////////////////////////////////////////////////////////////////
// default is using local storage
export default class DataStorage {
    private static _instance : DataStorage | null = null;
    public static getInstance(): DataStorage {
        if (DataStorage._instance === null)
            DataStorage._instance = new DataStorage();
        return DataStorage._instance;            
    }

    memory = {
        set : (packName : string, key : string, value : any) => {
            const pageKey = _getKey(packName,key);
            _memory.set(pageKey, value);
        },
        get : (packName : string, key : string, defaultValue : any) => {
            const pageKey = _getKey(packName, key);
            return _memory.get(pageKey) || defaultValue;
        },
        delete : (packName : string, key : string)=>{
            const pageKey = _getKey(packName, key);
            _memory.delete(pageKey);
        }
    };

    session = {
        set : (packName : string, key : string, value : any) =>{
            const pageKey = _getKey(packName,key);
            let storeValue = JSON.stringify( { type : typeof value, data : value } );
            sessionStorage.setItem(pageKey, storeValue);
        },
        get : (packName : string, key : string, defaultValue : any) => {
            const pageKey = _getKey(packName,key);
            const itemValue = sessionStorage.getItem(pageKey);
            if( !itemValue )
                return defaultValue;
            const valueObject = JSON.parse( itemValue);
            return valueObject.data;
        },
        delete : (packName : string, key : string)=>{
            sessionStorage.removeItem( _getKey(packName,key));
        }
    };


    set(packName : string, key : string, value : any) {
        const pageKey = _getKey(packName,key);

        let storeValue;
        storeValue = JSON.stringify( { type : typeof value, data : value } );

        localStorage.setItem(pageKey, storeValue);
    }

    get(packName : string, key : string, defaultValue : any) {
        const pageKey = _getKey(packName,key);

        const itemValue = localStorage.getItem(pageKey);
        if( !itemValue )
            return defaultValue ? defaultValue : null;

        const valueObject = JSON.parse( itemValue);
        const data = valueObject.data;
        return data;

    }

    delete(packName : string, key : string) {
        localStorage.removeItem( _getKey(packName,key));
    }
}