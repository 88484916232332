/**************************************************************************************************
    FileName  : misc/actions.ts
    Description
      Misc Redux actions

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { UPDATE_PATH_TRACKER_DATA, SHOW_SIDEBAR } from '../action-types';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
export function updatePathTrackerData (data: string[]) {
	return {
		type: UPDATE_PATH_TRACKER_DATA,
		payload: data
	};
}


export function showSideBar (isShow : boolean) {	
	return {
		type: SHOW_SIDEBAR,
		payload: isShow
	};
}