/*

  멘션의 판단
  참고 :
	삼성 Android 에서 Textarea의 Key down 및 Key Up Event가
	software keyboard 에서 입력하면 키코드가 229만 들어온다.
	이에 멘션을 입력받는 방식을 처음에는 keycode 및 key 를 확인하여 판별을 하였으나
	삼성 폰에서 문자 '@' 를 판별할 수 있는 방법이 없다.

	이에 생각할수 있는 방법은 두가지였다.
	1. old 문자열을 저장하고 새 문자열을 비교하여 새롭게 입력하거나 지워진 글자를 판별하여 비교하는 방법
	2. selection이 변경될때 해당 위치에서 앞으로 이동하면서 @를 찾아내는 방법

	2번의 방법으로 멘션및 문자열을 판별한다.
*/

import React  from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import './Editor.scss';
import { ElementType, Rect, TextStyle, SHSElementProps } from "../Define";
import {utils} from "../Utils";


// Must define Special Key code
// Normal character key code is can not detect on andorid virtual keyboard.
const BACKSPACE_KEY_CODE = 8;
const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;
const SPACE_KEY_CODE = 32;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;
const DELETE_KEY_CODE = 46;

enum TextAreaMode {
	MODE_NORMAL = "normal",
	MODE_SUGGESTION = "suggestion"
}



interface SHSTextAreaProps extends SHSElementProps{
	data : any;
	elementKey : number;
	type : ElementType;
	editorStyle : any;
	placeholder? : string;	
	notifyUpdateEditorData : (elementKey : number, type : ElementType, data : any)=>void;
	requestMentionInput : (elementKey : number, type : ElementType, isShow : boolean, clientScreenRect? : Rect, suggestionText? : string)=>void;
	updateSuggestionSpecialKeyCode : (suggestionSpecialKeycode : number)=>void;
	requestDeleteTextAreaWithBackspace : (elementKey : number, text : string)=>void;
	requestInsertYoutubeLinkFromTextArea : (elementKey : number, type : ElementType, elementData : any, url : string)=>void;
	requestInsertLinkPreviewFromTextArea : (elementKey : number, type : ElementType, elementData : any, url : string)=>void;
	requestLineFeed : (elementKey : number, firstText : string, secondText : string)=>void;
	requestKeyUp : (elementKey : number)=>void;
	requestKeyDown : (elementKey : number)=>void;
	requestDeleteTextAreaWithDeleteKey : (elementKey : number, text : string, caretPos : number)=>void;
	updateSuggestionText : (suggestionText : string)=>void;
}

export default class SHSTextArea extends React.Component<SHSTextAreaProps> {
	refText : any;
	mode : TextAreaMode;
	state : {
		updateCount : number,
		text : string,
		mode : TextAreaMode.MODE_NORMAL
	};

    constructor(props : SHSTextAreaProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onSelect = this.onSelect.bind(this);
        this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.getTextStyle = this.getTextStyle.bind(this);
		this.refText = React.createRef();
		this.mode = TextAreaMode.MODE_NORMAL;

		
		let displayText = props.data ? props.data.text : "";
        this.state = {
			text : displayText,
			updateCount : 0,
			mode : TextAreaMode.MODE_NORMAL
		};
	}

	updateScreen() {
		this.setState({updateCount : this.state.updateCount+1});
	}

	componentDidUpdate(willProps : SHSTextAreaProps) {
		if( willProps.data && willProps.data.isCancelSuggestion === true && this.mode === TextAreaMode.MODE_SUGGESTION) {
			this.mode = TextAreaMode.MODE_NORMAL;
			this.props.notifyUpdateEditorData(
				this.props.elementKey,
				this.props.type,
				{ ...this.props.data, isCancelSuggestion : false  }
			);
		}
	}

    componentDidMount() {
        if( this.props.data && this.props.data.isFocus ) {
			if( this.props.data.cursorPos !== undefined && 0 <= this.props.data.cursorPos ) {
				this.refText.current.selectionStart = this.props.data.cursorPos;
				this.refText.current.selectionEnd = this.props.data.cursorPos;
			}
			this.refText.current.focus();
        }
	}

	terminateSuggestionMode() {
		this.mode = TextAreaMode.MODE_NORMAL;
		this.props.requestMentionInput( this.props.elementKey, this.props.type, false );
	}


	// KeyDown 에서는 특수문자를 판별하여 해당 입력을 처리할지 여부를 결정한다.
    onKeyDown(e : any) {
		if( this.mode === TextAreaMode.MODE_SUGGESTION ) {
			// support to mention keyboard control for Desktop and Web (not include mobile web)
			if( e.keyCode === BACKSPACE_KEY_CODE ) {
				// pass
			} else if (e.keyCode === SPACE_KEY_CODE || e.keyCode === ESC_KEY_CODE) {
				this.terminateSuggestionMode();
			}
			else if ( e.keyCode === UP_KEY_CODE || e.keyCode === DOWN_KEY_CODE  ) {
				this.props.updateSuggestionSpecialKeyCode(e.keyCode);
				e.preventDefault();
			} else if( e.keyCode === ENTER_KEY_CODE ) {
				this.updateText();
				this.props.updateSuggestionSpecialKeyCode(e.keyCode);
				e.preventDefault();

			}
			else if (
					e.keyCode < 32 || 110 < e.keyCode ||
					e.keyCode === 33 || // PageUp
					e.keyCode === 34 || // PageDown
					e.keyCode === 35 || // End
					e.keyCode === 36 || // Home
					e.keyCode === 45 || // insert
					e.keyCode === DELETE_KEY_CODE  // Delete
				) {
				e.preventDefault();
			}
			return ;
		} // end MODE_SUGGESTION


		if( e.keyCode === BACKSPACE_KEY_CODE && e.target.selectionStart === 0 && e.target.selectionEnd === 0)  {
			this.props.requestDeleteTextAreaWithBackspace(
				this.props.elementKey,
				e.target.value
			);
			e.preventDefault();
			return ;
		}

		// console.log("onKeyDownNormal....");
		// console.info("e>>>", e.key);
		// console.info("keycode>>>", e.keyCode);
		// console.info("e.which>>>", e.which);
		switch(e.keyCode) {
			case ENTER_KEY_CODE : {
				const inputString = e.target.value;

				if( utils.isUrl(inputString) === true )  {
					if( utils.isUrlYoutube(inputString) === true ) {
						this.props.requestInsertYoutubeLinkFromTextArea(
							this.props.elementKey,
							this.props.type,
							{ text : e.target.value },
							inputString
						);
					}
					else  {
						this.props.requestInsertLinkPreviewFromTextArea(
							this.props.elementKey,
							this.props.type,
							{ text : e.target.value },
							inputString
						);
					}
				}
				else  {
					this.props.data.cursorPos = e.target.selectionStart;
					this.props.requestLineFeed(
						this.props.elementKey,
						inputString.slice(0,e.target.selectionStart),
						inputString.slice(e.target.selectionStart),
					);
				}

				e.preventDefault();
				return ;
			}
			case UP_KEY_CODE: 		this.props.requestKeyUp(this.props.elementKey);  	return;
			case DOWN_KEY_CODE: 	this.props.requestKeyDown(this.props.elementKey);  	return;
			case DELETE_KEY_CODE :  {
				if( e.target.selectionStart === e.target.value.length ) {
					this.props.requestDeleteTextAreaWithDeleteKey(this.props.elementKey, e.target.value, e.target.selectionStart);
					return ;
				}
				break;
			}
			default :
				break;
		}
	}



	onSelect(e : any) {
		if( this.mode === TextAreaMode.MODE_NORMAL ) {
			if ( this.isUserInputMention() === true ) {
				const elementScreenRect = this.refText.current.getBoundingClientRect();
				this.mode = TextAreaMode.MODE_SUGGESTION;
				const mentionText = this.getMentionText();
				this.props.requestMentionInput( this.props.elementKey, this.props.type, true, elementScreenRect, mentionText );
				return ;
			}
		} else if ( this.mode === TextAreaMode.MODE_SUGGESTION ) {
			const isMention = this.isUserInputMention();
			if( isMention === false ) {
				this.terminateSuggestionMode();
				return ;
			}
			const mentionText = this.getMentionText();
			this.props.updateSuggestionText( mentionText );
		}
	}


	getMentionText() {
		const idxCurStart = this.refText.current.selectionStart;
		const sentence = this.refText.current.value;
		const mentionText = [];
		let char = '';

		if( sentence[idxCurStart-1] === '@' ||  sentence[idxCurStart-1] === ' ' )
			return '';

		// fill char before text from current cursor
		for( let idxCur = idxCurStart-1; 		0<= idxCur;		--idxCur ) {
			char = sentence[idxCur];
			if( char === "@")
				break;
			mentionText.unshift(char);
		}

		// fill char after text to current cursor
		for( let idxCur = idxCurStart; 		idxCur < sentence.length;		++idxCur ) {
			char = sentence[idxCur];
			if( char === ' ' )
				break;
			mentionText.push(char);
		}

		const mentionString = mentionText.join('');
		return mentionString;
	}

	// 안드로이드 모바일의 가상 키보드 문제로 인해 멘션의 판단을 키코드로 하지 않는다.
	isUserInputMention() {
		let idxCur = this.refText.current.selectionStart;
		// User가 문자열을 선택한 상태라면 복사, 붙여넣기 기능등을
		// 원할 수 있으므로 멘션 입력이라고 판단하지 않는다.
		if( idxCur !== this.refText.current.selectionEnd)
			return ;

		const inputString = [];
		const sentence = this.refText.current.value;

		let char = '';
		--idxCur;
		while( 0 <= idxCur  ) {
			char = sentence[idxCur];
			if( char === ' ' )
				break;
			inputString.unshift(char);
			--idxCur;
		}
		return  inputString[0] === '@';
	}

    onChange(e : any) {
        this.setState({text :  e.target.value});
	}

	updateText() {
		if( 0 <= this.refText.current.value.length  )  {
			this.props.notifyUpdateEditorData(
				this.props.elementKey,
				this.props.type,
				{ ...this.props.data, text : this.refText.current.value, cursorPos : this.refText.current.selectionStart   }
			);
		}
	}
    onBlur() {
		this.updateText();
	}
    onFocus() {
		this.props.notifySelectedItem( this.props.elementKey );
	}

	getTextStyle() {
		const textStyle = this.props.data.textStyle;
        let textElementStyle : any = {}
        if( this.props.editorStyle)
            textElementStyle.color = this.props.editorStyle.textColor;

		if(textStyle === undefined || textStyle === null )
			return textElementStyle;

		if(textStyle === TextStyle.large) {
            textElementStyle.fontWeight = "bold";
            textElementStyle.fontSize = 16;
        } else if( textStyle === TextStyle.extraLarge ) {
            textElementStyle.fontWeight = "bold";
            textElementStyle.fontSize = 18;
        } else if ( textStyle === TextStyle.quotation ) {
            textElementStyle.fontSize = 16;
            textElementStyle.marginLeft = 8;
        } else {
            textElementStyle.fontSize = 16;
        }
        return textElementStyle;
	}


    render() {
		const textStyle = this.props.data.textStyle;


        return (
			<div className={"text-area-container"}  >
				{textStyle===TextStyle.bullet &&
					<div style={{
                        marginRight:4,
                        fontSize:16,
                        display : "flex", alignItems : "center", height:"100%", marginTop : 2,
                        color : this.props.editorStyle ? this.props.editorStyle.textColor : "inherit"
                    }}>•</div>
				}

				{textStyle===TextStyle.numberedList &&
					<div style={{
                        marginRight:4,
                        fontSize:16,
                        display : "flex",
                        alignItems : "center", height:"100%", marginTop : 2,
                        color : this.props.editorStyle ? this.props.editorStyle.textColor : "inherit"
                    }}>{this.props.data.listNumber}.</div>
				}

				{textStyle===TextStyle.quotation &&
					<div style={{backgroundColor:"#c4c4c4", width : 1, height:"100%", position:"absolute"}}> </div>
				}

				<TextareaAutosize
					className="spacehub-textarea"
                    ref={this.refText}
                    onChange={this.onChange}
					onKeyDown={this.onKeyDown}
					onSelect={this.onSelect}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					value={this.state.text}
					style={this.getTextStyle()}
                    placeholder={this.props.placeholder}
				/>
			</div>

        );
    }
}
