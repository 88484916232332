/**************************************************************************************************
    FileName  : post.ts
    Description : 
      Post Type Definitions

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { FileInfo } from "./common"


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Space Types                                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum EnumOpenSpace {
    spacehub = "shs",
    diablo4 = "diablo4",
    programming = "programming"
}

export enum EnumSpecialGroupSpace {
    bgProgramming =  'bgprogramming',
    hireDiversity = "hirediversity",    
}

export enum EnumSpaceType {
    open = "open",
    group = "group",    
}

export interface SpaceInfo {
    id : number;    
    name : string;
    displayName : string;
    introduce : string;
    banner : FileInfo;
    thumbnail : FileInfo;
    type : EnumSpaceType;
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Thread Types                                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
export enum EnumOpenThread {
    // Space Hub
    spacehub = "shs",    
    programming = 'programming',

    // Diablo4
    diablo4 = "diablo4"
}

export interface ThreadInfo {
    spaceName: string;
    spaceDisplayName: string;
    spaceType: EnumSpaceType;
    name: string;
    displayName: string;
}

export enum EnumGroupSpaceSpecialTabName {
    home = "home",
    diablo4Trade = "diablo4Trade"
}
