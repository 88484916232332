/**************************************************************************************************
    FileName  : DefaultLayout.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import './DefaultLayout.scss';
import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainAppBar from "components/MainAppBar";
import Sidebar from "components/Sidebar";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Class Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////
function DefaultLayout() {
    return (
        <div id="mainLayout">            
            <Sidebar/>
            <Stack className="main-content-container">
                <MainAppBar/>
                <Box id="mainContent">
                    <Outlet/>
                </Box>
            </Stack>
        </div>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default DefaultLayout;
