/**************************************************************************************************
    FileName  : RegisterOrEditItem.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./RegisterOrEditItem.scss";
// Import for React
import { SyntheticEvent, useEffect, useState } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Typography, Button,TextField, FormControlLabel, RadioGroup, Radio, 
    Input, Checkbox, Autocomplete
} from "@mui/material";
// Import for Project Base
import { api, appToast, appNavigator, pathTracker, shsAssert } from "libs/stdlib";
import { EnumOpenSpace, EnumDiablo4Rarity, EnumDiablo4EquipmentType, Diablo4ItemOption, TradeInfo } from "types";
import D4ItemDatabase from "./D4ItemDatabase";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  RegisterItem Implementation                                  //
///////////////////////////////////////////////////////////////////////////////////////////////////

interface Diablo4APIItemOption {
    optionId : number;
    val : number;
}


function RegisterOrEditItem() {    
    /////////////////////////////////////////////////////////////////////////////
    // Const
    const urlParams = useParams();
    shsAssert(urlParams.storeName !== undefined);
    const storeName = urlParams.storeName!;
    const isEditMode = urlParams.tradeId ? true : false; 

    const d4Database = D4ItemDatabase.getInstance();    
    const [rarity, setRarity] = useState<EnumDiablo4Rarity | null>(null);
    const [equipmentType, setEquipmentType] = useState<EnumDiablo4EquipmentType | null>(null);
    const [itemOptions, setItemOptions] = useState<Diablo4ItemOption[]>([]);
    const [requiredLevel, setRequiredLevel] = useState<string>("");
    const [isLadder, setIsLadder] = useState<boolean>(true);
    const [isHardcore, setIsHardcore] = useState<boolean>(false);
    const [price, setPrice] = useState<string>("");
    const [newItemOption, ] = useState<Diablo4ItemOption|null>(null);
    const [currentItemOptionList, setCurrentItemOptionList] = useState<Diablo4ItemOption[]>(d4Database.getItemOptions());
    const [refreshCount, setRefreshCount] = useState<number>(0);
    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_registerItem(
        storeName : string,
        itemId : number,
        itemOptions : Diablo4APIItemOption[],
        isLadder : boolean,
        isHardcore : boolean,
        price : number,
        reqLevel? : number
    ) {
        shsAssert(0 < itemId);

        const apiDataParams = {
            itemId, 
            itemOptions,
            amount : 1,
            type : "price",
            isLadder, isHardcore,
            priceAmount : price,
            reqLevel
        };
        console.info("itemId>>>>", itemId);

        if( isEditMode === true ) {
            await api.put(
                `/api/diablo4/stores/${storeName}/trades/${urlParams.tradeId}`,
                apiDataParams,
                ()=>{
                    appToast.success("아이템 수정이 완료되었습니다.");
                    appNavigator.diablo4.goStore(storeName);
                }
            );
        } else {
            await api.post(
                `/api/diablo4/stores/${storeName}/trade`,
                apiDataParams,
                ()=>{
                    appToast.success("아이템 등록이 완료되었습니다.");
                    appNavigator.diablo4.goStore(storeName);
                }
            );
        }
    }

    async function api_getTradeInfo(tradeId : number) {
        await api.get(
            `/api/diablo4/trades/${tradeId}`,
            (tradeInfo : TradeInfo)=>{                
                const itemInfo = d4Database.getItem(tradeInfo.itemId);                
                shsAssert(itemInfo!==undefined);                
                setRarity(itemInfo!.rarity);
                setEquipmentType(itemInfo!.equipmentType);

                for( const option of tradeInfo.itemOptions ) {
                    option.label = option.optionIdTxt;
                    option.val = option.val.toString();
                    for ( let i = 0;        i < currentItemOptionList.length;   ++i ) {
                        if( currentItemOptionList[i].optionId === option.optionId ) {
                            currentItemOptionList.splice(i, 1);
                            break;
                        }
                    }
                }
                setCurrentItemOptionList([...currentItemOptionList]);
                setItemOptions(tradeInfo.itemOptions);
                setIsHardcore(tradeInfo.isHardcore);
                setIsLadder(tradeInfo.isLadder);
                if( tradeInfo.reqLevel )
                    setRequiredLevel(tradeInfo.reqLevel.toString());
                setPrice(tradeInfo.priceAmount!.toString());
            }
        );

    }


    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        pathTracker.buildStart()
            .add("다이블로4", ()=>{ appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) })
            .add("내상점", ()=>appNavigator.diablo4.goStore(storeName) );
        if( isEditMode === true ) {
            pathTracker.add("아이템 수정");
        } else {
            pathTracker.add("아이템 등록");
        }            
        pathTracker.build();

        if( isEditMode === true ) {
            (async()=>{
                await api_getTradeInfo(parseInt(urlParams.tradeId!));
            })();
        }
        //eslint-disable-next-line
    },[]);



    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function refresh() {
        setRefreshCount(refreshCount+1);
    }

    function onRarityChange (event: React.ChangeEvent<HTMLInputElement>) {
        const rarity = (event.target as HTMLInputElement).value as EnumDiablo4Rarity;
        setRarity(rarity);
        if( rarity === EnumDiablo4Rarity.legend )
            setEquipmentType(EnumDiablo4EquipmentType.essence);
        else {
            if( equipmentType === EnumDiablo4EquipmentType.essence )
                setEquipmentType(null);
        }
    }

    function onEquipmentTypeChange (event: React.ChangeEvent<HTMLInputElement>) {
        const equipmentType = (event.target as HTMLInputElement).value as EnumDiablo4EquipmentType;
        setEquipmentType(equipmentType);
        if( equipmentType === EnumDiablo4EquipmentType.essence && rarity === null )
            setRarity(EnumDiablo4Rarity.legend);
    }
    
    function onNewItemOptionChange(_: SyntheticEvent, newValue : Diablo4ItemOption) {        
        for(let i = 0;  i < itemOptions.length;     ++i) {
            if( itemOptions[i].optionId === newValue.optionId)                 
                return ;            
        }


        setItemOptions([
            ...itemOptions,
            newValue
        ]);
        setCurrentItemOptionList(
            currentItemOptionList.filter((option)=>option.optionId !== newValue.optionId )
        );
    }

    function onRemoveItemOptionClick(option : Diablo4ItemOption) {        
        setItemOptions(
            itemOptions.filter((opt)=>opt.optionId !== option.optionId )
        );
        setCurrentItemOptionList([
            option,
            ...currentItemOptionList
        ]);
        
    }


    async function onItemRegisterClick() {
        if( rarity === null )
            return appToast.error("아이템 희귀도를 입력해 주십시오.");

        if( equipmentType === null)
            return appToast.error("장비 타입을 입력해 주십시오.");
        

        if( itemOptions.length === 0 )  {
            return appToast.error("아이템 옵션이 없습니다.");
        } else {
            for( let i=0;    i < itemOptions.length;     ++i) {                
                if( itemOptions[i].val.trim().length === 0 ) {
                    return appToast.error(`${i+1}번 옵션의 값을 입력해 주십시오.`);
                }
            }
        }

        if(price.trim().length === 0){
            return appToast.error(`가격을 책정해 주십시오.`);
        }
        
        const itemId = d4Database.getItemId(rarity, equipmentType);
        let reqLevel : number | undefined;
        if( 0 < requiredLevel.trim().length ) {
            reqLevel  = parseInt(requiredLevel);
        }
        const itemOptionParams : Diablo4APIItemOption[] = [];
        itemOptions.forEach((option)=>{
            itemOptionParams.push({
                optionId : option.optionId,
                val : parseInt(option.val)
            })
        })
        

        await api_registerItem(storeName, itemId, itemOptionParams, isLadder, isHardcore, parseInt(price), reqLevel);
        
    }


    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="registerItem-230518">
            <Typography variant="h5">디아블로4 : 아이템 {isEditMode ? "수정" : "등록"}</Typography>

            <Typography variant="h6" mt={4}>희귀도 *</Typography>
            <RadioGroup 
                name="radio-group-rarity"  
                row
                value={rarity}
                onChange={onRarityChange}
            >
                <FormControlLabel value={EnumDiablo4Rarity.magic} control={<Radio />} label="매직" />
                <FormControlLabel value={EnumDiablo4Rarity.rare} control={<Radio />} label="레어" />
                <FormControlLabel value={EnumDiablo4Rarity.legend} control={<Radio />} label="전설" />                
            </RadioGroup>

            <Typography variant="h6" mt={2}>장비타입 *</Typography>
            <RadioGroup 
                name="radio-group-equipment-type" 
                row
                value={equipmentType}
                onChange={onEquipmentTypeChange}
            >
                <FormControlLabel value={EnumDiablo4EquipmentType.helm} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend}  label="투구" />
                <FormControlLabel value={EnumDiablo4EquipmentType.amulet} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="목걸이" />
                <FormControlLabel value={EnumDiablo4EquipmentType.weapon} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="무기" />
                <FormControlLabel value={EnumDiablo4EquipmentType.shield} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="방패" />
                <FormControlLabel value={EnumDiablo4EquipmentType.torso} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="상의" />
                <FormControlLabel value={EnumDiablo4EquipmentType.legs} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="하의" />
                <FormControlLabel value={EnumDiablo4EquipmentType.gloves} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="장갑" />
                <FormControlLabel value={EnumDiablo4EquipmentType.boots} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="신발" />
                <FormControlLabel value={EnumDiablo4EquipmentType.ring} control={<Radio />} disabled={rarity === EnumDiablo4Rarity.legend} label="반지" />
                <FormControlLabel value={EnumDiablo4EquipmentType.essence} control={<Radio />} disabled={ rarity !== null && rarity !== EnumDiablo4Rarity.legend  } label="정수" />
            </RadioGroup>

            <Stack className="item-option-panel">
                <Typography variant="h6" >아이템 옵션 *</Typography>

                {itemOptions.map((option : Diablo4ItemOption, index)=>{
                    return (
                        <Stack className="option-row" key={index}>
                            <Typography>{option.label}</Typography>
                            <Input 
                                className="input-option-value" 
                                type="number" 
                                value={option.val} 
                                onChange={(e)=>{
                                    option.val = e.target.value;
                                    refresh();
                                }} 
                                autoFocus 
                            />
                            <Button onClick={()=>onRemoveItemOptionClick(option)}>X</Button>
                        </Stack>

                    );
                })}
                
                <Stack className="option-row">
                    <Autocomplete
                        options={currentItemOptionList}
                        sx={{ width: 300 }}
                        value={newItemOption}
                        onChange={(e, newValue)=>onNewItemOptionChange(e, newValue as Diablo4ItemOption)}
                        renderInput={(params) => <TextField {...params} label="옵션명" />}
                    />                    
                </Stack>
                
            </Stack>

            
            <Stack className="required-level-panel" >
                <Typography variant="h6" mr={3}>요구레벨</Typography>
                <Input className="input-req-level" type="number" value={requiredLevel} onChange={(e)=>setRequiredLevel(e.target.value)} />
            </Stack>
            
            <Stack className="checkbox-panel">
                <FormControlLabel control={<Checkbox checked={isLadder} onChange={(e)=>setIsLadder(e.target.checked)} />} label="레더" />
                <FormControlLabel control={<Checkbox checked={isHardcore} onChange={(e)=>setIsHardcore(e.target.checked)} />} label="하드코어" />
            </Stack>
            

            <Typography variant="h6" mt={2}>가격 *</Typography>
            <Stack className="price-panel">
                <Input type="number" value={price} onChange={(e)=>setPrice(e.target.value) }/>
                <Typography ml={1} variant="body1">골드</Typography>
            </Stack>
            

            
            <Button className="btn-register" variant="outlined" onClick={onItemRegisterClick}>아이템 등록</Button>

    
        </Stack>
    );
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default RegisterOrEditItem;
  