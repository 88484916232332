/**************************************************************************************************
    FileName  : Editor.ts
    Description

    Update History 	  
      2023.07     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';

import {Divider, IconButton} from '@mui/material';
import {parse, TagStartSymbol, TagEndSymbol} from '../SHSParser';
import {utils} from "../Utils";

import SHSTextArea  from './SHSTextArea';
import SHSImage  from './SHSImage';
import SHSYoutube  from './SHSYoutube';
import SHSLink  from './SHSLink';
import SHSSuggestion  from './SHSSuggestion';

import { TextStyle, ElementType, ParseElement, TextElement, SHSElementProps, Rect } from "../Define";

import {
	FormatSizeOutlined as FormatSizeOutlinedIcon,
	ImageOutlined as ImageOutlinedIcon,
	FormatListNumberedOutlined as FormatListNumberedOutlinedIcon,
	FormatListBulletedOutlined as FormatListBulletedOutlinedIcon,
	FormatQuoteOutlined as FormatQuoteOutlinedIcon,
	YouTube as YouTubeIcon,
	InsertLink as InsertLinkIcon
} from '@mui/icons-material';



import shsAssert from 'libs/shsAssert';
import { BadgeInfo, FileInfo } from 'types';
import { appAlert, appToast } from 'libs/stdlib';


export interface EditorEncodeResult {
	encodedText : string;
	relativeFiles : number[];
	mentionUsers : number[];
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////

const ElementNewText = "new-text";
type TextEditorElementType = ElementType | typeof ElementNewText;

interface TextEditorBuildData {
	type : ElementType;
	elementKey : number;
	data : any;
}

interface TextEditorElementProps extends SHSElementProps {
	editorStyle? : Object;	
	type : TextEditorElementType;
	siteUrl : string;

	youtubeApiKey? : string;
	notifyUpdateEditorData : (elementKey : number, elementType : ElementType | typeof ElementNewText, elementData : any)=>void;	
	notifyObjectLoaded : (elementKey : number, elementType : ElementType)=>void;
	requestDeleteElement : (elementKey : number)=>void;
	requestDeleteTextAreaWithDeleteKey : (elementKey : number, text : string, caretPos : number)=>void;
	notifyUpdateDataInfo : (elementKey : number, newValue : any)=>void;	
	// for text
	placeholder? : string;
	requestDeleteTextAreaWithBackspace? : (elementKey : number, text : string)=>void;
	requestInsertYoutubeLinkFromTextArea? : (elementKey : number, type : TextEditorElementType, elementData : any, url : string)=>void;
	requestInsertLinkPreviewFromTextArea? : (elementKey : number, type : TextEditorElementType, elementData : any, url : string)=>void;
	requestKeyUp? : (elementKey : number)=>void;
	requestKeyDown? : (elementKey : number)=>void;
	requestLineFeed? : (elementKey : number, firstText : string, secondText : string)=>void;
	requestMentionInput? : (elementKey : number, type : TextEditorElementType, isShow : boolean, clientScreenRect? : Rect, suggestionText? : string)=>void;
	updateSuggestionText? : (suggestionText : string)=>void;
	updateSuggestionSpecialKeyCode? : (suggestionSpecialKeycode : number)=>void;
	// for link
	requestLinkMetaTagInfo? : (url : string, cb : (info : any)=>void )=>void;
	// for image
	requestUploadImage? : (file : File)=>Promise<any>;
	onProvideImageData? : (imageId : number)=>Promise<FileInfo>;	
}



/*
textEditorData
    type  : element type
    elementKey : element key
    data  : element data

    type : "textarea"
    elementKey : element key
    data : {
        text
    }

    type : "image"
    elementKey : element key
    data : {
        location : "local", "web"
        src : string
    }

    type : "gif"
*/

// properties
// youtubeApiKey : for display youtube title
// isShowToolbar
let _elementKey = 0;
interface SHSTextEditorProps {		
	description? : string;
	requestMentionUserInfo? : (suggestionText : string, callback : (suggestionUserList : any[])=>void )=>void;	
	requestLinkMetaTagInfo? : (url : string, cb : (info : any)=>void)=>void;
	friendList? : [],
	editorStyle? : any;
	requestUploadImage : (file : File)=> Promise<any>;
	onProvideImageData? : (imageId : number)=>Promise<FileInfo>;
	siteUrl : string;
	requestMentionListUserInfo? : (mentionUserList : string[])=>{list : {userName : string, id : number}[]};
	onFocus? : ()=>void;
	placeholder? : string;
	isShowToolbar? : boolean;
	youtubeApiKey? : string;
}

interface SHSTextEditorState {
	textEditorData : TextEditorBuildData[];
	isOpenMentionSuggestion : boolean;
	suggestionText : string;
	suggestionUserList : [];
	suggestionInputClientScreenRect : Rect | null;
	suggestionSpecialKeycode : number;
	updateCount : number;
}

interface MentionData {
	userName : string;
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                              SHSTextEditor Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////

export default class SHSTextEditor extends React.Component<SHSTextEditorProps> {
	currentElementKey : number;
	refImageFiles : any;
	refEditor : any;

    state : SHSTextEditorState = {
		textEditorData : [this.createNewTextData("", false)],

		isOpenMentionSuggestion : false,
		suggestionText : "",
		suggestionUserList : [],
		suggestionInputClientScreenRect : null,
		suggestionSpecialKeycode : 0,

		updateCount : 0,
    } ;

    // SHSTextEditor event interface
	// props.requestLinkMetaTagInfo
	// props.requestMentionUserInfo
    constructor(props : SHSTextEditorProps) {
		super(props);

        // element -> SHSTextEditor event
        this.notifyUpdateEditorData = this.notifyUpdateEditorData.bind(this);
		this.notifySelectedItem = this.notifySelectedItem.bind(this);
		this.notifyObjectLoaded = this.notifyObjectLoaded.bind(this);
		// element -> SHSTextEditor request
		this.requestDeleteElement = this.requestDeleteElement.bind(this);
		// for update element data
		this.notifyUpdateDataInfo = this.notifyUpdateDataInfo.bind(this);

		// textarea -> SHSTextEditor request
		this.requestLineFeed = this.requestLineFeed.bind(this);
		this.requestDeleteTextAreaWithBackspace = this.requestDeleteTextAreaWithBackspace.bind(this);
		this.requestDeleteTextAreaWithDeleteKey = this.requestDeleteTextAreaWithDeleteKey.bind(this);
        this.requestInsertYoutubeLinkFromTextArea = this.requestInsertYoutubeLinkFromTextArea.bind(this);
		this.requestInsertLinkPreviewFromTextArea = this.requestInsertLinkPreviewFromTextArea.bind(this);
		this.requestKeyUp = this.requestKeyUp.bind(this);
		this.requestKeyDown = this.requestKeyDown.bind(this);
		// suggestion popup
		this.requestMentionInput = this.requestMentionInput.bind(this);
		this.updateSuggestionText = this.updateSuggestionText.bind(this);
		this.updateSuggestionSpecialKeyCode = this.updateSuggestionSpecialKeyCode.bind(this);
		this.initSuggestionSpecialKeyCode = this.initSuggestionSpecialKeyCode.bind(this);
		this.onResponseSuggestionResult = this.onResponseSuggestionResult.bind(this);
		this.onResponseSuggestionCancel = this.onResponseSuggestionCancel.bind(this);
        // link preview -> SHSTextEditor request
        this.requestLinkMetaTagInfo = this.requestLinkMetaTagInfo.bind(this);

		this.onClickTextSize = this.onClickTextSize.bind(this);
		this.onClickQuotation = this.onClickQuotation.bind(this);
		this.onClickBullet = this.onClickBullet.bind(this);
		this.onClickNumberedList = this.onClickNumberedList.bind(this);
		this.onYoutubeInsertClick = this.onYoutubeInsertClick.bind(this);
		this.onLinkInsertClick = this.onLinkInsertClick.bind(this);
        this.onClickEmptyArea = this.onClickEmptyArea.bind(this);


		this.currentElementKey = 0;



		// for images
		this.onImageInsertClick = this.onImageInsertClick.bind(this);
		this.refImageFiles = React.createRef();
		this.onImagesInsert = this.onImagesInsert.bind(this);

        // focus
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        // load data
        if( props.description )
            this.state.textEditorData = this.decodeText(props.description);
	}

    loadData(description : string) {
        this.setState({textEditorData : this.decodeText(description)});
    }



    decodeText(encodedText : string) : TextEditorBuildData[]{
        const _this = this;
        function build(parsedItems : ParseElement[]) {
            let parsedItem : ParseElement | null = null;
            let prevItem = null;
            let nextItem = null;
            let items : TextEditorBuildData[] = [];
            let item : TextEditorBuildData | null = null;

            // text 타입에 개행이 포함되어 있다면 텍스트를 분리한다.
            const newParsedItems = [];
            for( let i = 0;     i < parsedItems.length;  ++i ) {
                parsedItem = parsedItems[i];
                if( parsedItem.type === ElementType.text ) {
					const textElement = parsedItem as TextElement;

                    const arrLines = textElement.text.split("\n");
                    arrLines.forEach( s => {
                        newParsedItems.push({type : ElementType.text, text : s});
                    });
                } else {
                    newParsedItems.push(parsedItem);
                }
            }
            parsedItems = newParsedItems;


            // 만일 element에 inline-mention 이 포함되어 있다면 앞뒤 문자열을 병합한다.
            // 앞에서 순회한다면 index가 변경되므로 뒤에서부터 검색하여 변경한다.
            // 이때 뒤의 문자열을 살펴보고 뒷문장과 합쳐 text 타입을 유지한다.
            // 그후 앞의 요소가 text라면 합치고 아니라면 합지지 않는다.
            // 병합시는 displayName로 병합한다.
            for( let i = parsedItems.length-1;      0 <= i;     --i ) {
                parsedItem = parsedItems[i];

                if( parsedItem.type === ElementType.inlineMention ) {
                    nextItem = parsedItems[i+1];
                    prevItem = parsedItems[i-1];
                    if( nextItem && nextItem.type === ElementType.text  ) {
                        (nextItem as TextElement).text =  "@" + (parsedItem as TextElement).userName  + (nextItem as TextElement).text;
                        parsedItems.splice(i,1);
                    }

                    if( prevItem && prevItem.type === ElementType.text  ) {
                        (prevItem as TextElement).text = (prevItem as TextElement).text + (nextItem as TextElement).text;
                        parsedItems.splice(i,1);
                    }
                }
            }

            for( let i=0;  i < parsedItems.length; ++i ) {
                parsedItem = parsedItems[i];
                item = {
                    type : parsedItem.type,
                    elementKey : _elementKey++,
                    data : {
						...parsedItem,
						isFocus : false
					}
                };
                items.push(item);
            }

            const lastElement = items[items.length-1];
            if( lastElement.type !== ElementType.text  )
                items.push(_this.createNewTextData(""));
            items[items.length-1].data.isFocus = true;
            return items;
        }

        const parsedItems = parse(encodedText);
        console.info("parsedItems", parsedItems);
        return build(parsedItems);

        // return [this.createNewTextData(encodedText, true)]
    }



	updateScreen() {
		this.setState( {updateCount : this.state.updateCount+1} );
	}

    notifyUpdateEditorData( elementKey : number, elementType : ElementType | typeof ElementNewText, elementData : any ) {
        if( elementType === ElementNewText ) {
            this.setState({
                textEditorData : [
                    ...this.state.textEditorData,
                    { type : ElementType.text, elementKey : _elementKey++, data : elementData }
                ]
            });
        } else {
            const newTextEditData = [ ...this.state.textEditorData ];
            const itemIndex = this.getItemIndexWithElementKey(elementKey);
            newTextEditData[itemIndex].data =  elementData;
            this.setState({
                textEditorData : newTextEditData
            });
        }
	}

    notifySelectedItem(elementKey : number) {
		this.setCurrentElementKey(elementKey);
	}

	// check last element type is text or not, if not text element then add a text eleemt at last
	notifyObjectLoaded(elementKey : number, elementType : ElementType) {
		if( elementType === ElementType.text)
			return ;

		const lastElement = this.state.textEditorData[ this.state.textEditorData.length - 1 ];

		if( lastElement.elementKey === elementKey )  {
			this.setState({
				textEditorData : [
					...this.state.textEditorData,
					this.createNewTextData("", true)
				]
			})
		}
	}
	createNewTextData(text : string, isFocus? : boolean, textStyle? : string, listNumber? : number) : TextEditorBuildData {
		if( textStyle === undefined || textStyle === null )
			textStyle = TextStyle.normal;

		//textStyle = "bullet";
		return {
			type : ElementType.text,
			elementKey : _elementKey++,
			data : { text,  isFocus, textStyle, listNumber, cursorPos : 0 }
		};
	}



	///////////////////////////////////////////////////////////////////////////////////////////////
	getElement(elementKey : number) {
		let element;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			element =  this.state.textEditorData[i];
			if( elementKey ===  element.elementKey )
				return element;
		}
		return null;
	}
	getElementIndex(elementKey : number) {
		let element;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			element =  this.state.textEditorData[i];
			if( elementKey ===  element.elementKey )
				return i;
		}
		return -1;
	}

	getPreviousElement() {
		let prevElement = null;
		let element = null;
		for( let i = 0;		i < this.state.textEditorData.length;	++i  ) {
			prevElement = element;
			element = this.state.textEditorData[i];;
			if( element.elementKey ===  this.currentElementKey )
				return prevElement;
		}
		return null;
	}
	getNextElement(elementKey : number) {
		for( let i = 0;		i < this.state.textEditorData.length-1;		++i  ) {
			if( this.state.textEditorData[i].elementKey ===  elementKey )
				return this.state.textEditorData[i+1];
		}
		return null;
	}



	getCurrentElementProperty(property : string) {
		return this.getElementProperty(this.currentElementKey, property);
	}
	getElementProperty(elementKey : number, property : string) {
		const element = this.getElement(elementKey);
		if( element === null || element.data === null || element.data === undefined )
			return null;
		return element.data[property];
	}

	updateElementUI(elementKey : number) {
		let element = this.getElement(elementKey);
		if( this.currentElementKey === elementKey) {
			this.setCurrentElementKey(_elementKey);
		}
		shsAssert(element!==null);
		element!.elementKey = _elementKey++;
	}

	updateElement(elementKey : number, newValue : any) {
		let element = this.updateElementData(elementKey, newValue);
		shsAssert(element !== undefined);

		// 업데이트 하려고하는 엘리먼트가 현재 선택된 엘리먼트라면 현재 선택된 엘리먼트 키또한 업데이트 한다.
		if( element!.elementKey === this.currentElementKey )
			this.setCurrentElementKey(_elementKey);
		element!.elementKey = _elementKey++;

		this.updateScreen();
	}
	updateElementData(elementKey : number, newValue : any) {
		const element = this.getElement(elementKey);
		if( element === null )
			return ;

		const newData = {
			...element.data,
			...newValue
		};
		element["data"] = newData;
		return element;
	}


	deleteElement(elementKey : number) {
		let deleteElement = null;
		let deleteElementIndex = -1;
		let textInputCount = 0;
		for(let i = 0;	i < this.state.textEditorData.length;	++i ) {
			if( this.state.textEditorData[i].elementKey === elementKey ) {
				deleteElement = this.state.textEditorData[i];
				deleteElementIndex = i;
			}
			if( this.state.textEditorData[i].type === ElementType.text )
				++textInputCount;
		}

		if( deleteElement === null )
			return ;

		// Text Editor has at least one text input
		if( textInputCount === 1 &&  deleteElement.type === ElementType.text  )
			return ;

		this.state.textEditorData.splice(deleteElementIndex, 1);
		this.updateScreen();
	}
	setCurrentElementKey(elementKey : number) {
		if( elementKey === this.currentElementKey )
			return ;

		if( this.state.isOpenMentionSuggestion === true ) {
			this.updateElementData(this.currentElementKey, {isCancelSuggestion : true});
			this.setState({isOpenMentionSuggestion : false}) ;
		}
		this.currentElementKey = elementKey;
	}


	requestDeleteElement(elementKey : number) {
		if( 1 < this.state.textEditorData.length)
			this.deleteElement(elementKey);
	}


	requestLineFeed(elementKey : number, firstText : string, secondText : string) {

		const newTextEditData = [ ...this.state.textEditorData ];
		const itemIndex = this.getItemIndexWithElementKey(elementKey);

		if( newTextEditData[itemIndex].data )
			newTextEditData[itemIndex].data.text =  firstText;
		else
			newTextEditData[itemIndex].data =  {text : firstText};

		const firstTextStyle = newTextEditData[itemIndex].data.textStyle;
		let secondTextStyle = TextStyle.normal;
		let secondListNumber = undefined;
		if  ( firstTextStyle === TextStyle.bullet ||
			  firstTextStyle === TextStyle.numberedList ||
			  firstTextStyle === TextStyle.quotation
			) {
			// 빈 텍스트 라인에서 bullut 혹은 numbered list 라면 스타일만 normal 로 교체한다.
			if( firstTextStyle === TextStyle.numberedList ) {
				secondListNumber  = newTextEditData[itemIndex].data.listNumber+1;
			}

			if( firstText.length === 0  && secondText.length === 0  ) {
				newTextEditData[itemIndex].data.textStyle = TextStyle.normal;
				newTextEditData[itemIndex].elementKey = _elementKey++;
				this.updateScreen();
				return ;
			}

			// 빈 텍스트가 아니라면 다음 라인도 이전 라인 스타일을 따른다.
			secondTextStyle = firstTextStyle;
		}


		newTextEditData[itemIndex].elementKey = _elementKey++;
		newTextEditData.splice(itemIndex+1, 0, this.createNewTextData(secondText, true, secondTextStyle, secondListNumber) );
		this.setState({
			textEditorData : newTextEditData
		});
	}

    requestDeleteTextAreaWithBackspace(elementKey : number, text : string) {
        let itemIndex = this.getItemIndexWithElementKey(elementKey);
        if( 0 < itemIndex && this.state.textEditorData[itemIndex-1].type === ElementType.text  ) {
            const editorData = [...this.state.textEditorData];
            editorData[itemIndex-1].data.isFocus = true;
            editorData[itemIndex-1].data.cursorPos = editorData[itemIndex-1].data.text.length;
            editorData[itemIndex-1].data.text += text;
            editorData[itemIndex-1].elementKey = _elementKey++;
            editorData.splice(itemIndex, 1);
            this.setState({ textEditorData : editorData});
        } else if ( text.length === 0 ) {
			this.deleteElement(elementKey);
		}
	}

	// Check next element, if next element is text then concat two element
	requestDeleteTextAreaWithDeleteKey(elementKey : number, text : string, caretPos : number) {
		const nextElement = this.getNextElement(elementKey);
		if( nextElement && nextElement.type === ElementType.text) {
			const currentElement = this.getElement(elementKey);
			shsAssert(currentElement!==null);
			currentElement!.data.text = text.concat( nextElement.data.text );
			currentElement!.data.cursorPos = caretPos;
			currentElement!.elementKey = _elementKey++;
			this.deleteElement(nextElement.elementKey);
		}
	}


    updateTextAreaAndInserObject(elementKey : number, type : TextEditorElementType, textareaData : any, newObjectData : any) {
        const indexItem = 0 < this.state.textEditorData.length ? this.getItemIndexWithElementKey(this.currentElementKey) : 0;
        const newEditorData = [...this.state.textEditorData];

        if( type === ElementNewText ) {
            newEditorData.splice(indexItem, 0, { type : ElementType.text, elementKey, data : textareaData  });
            newEditorData.splice(indexItem+1, 0, newObjectData);
        }
        else {
             newEditorData[indexItem].elementKey = _elementKey++;
             newEditorData[indexItem].data = textareaData;
             newEditorData.splice(indexItem+1, 0, newObjectData);
        }
        this.setState({textEditorData : newEditorData});
	}
	deleteTextAndInserObject(elementKey : number, type : TextEditorElementType, textareaData : any, newObjectData : any) {
        const indexItem = 0 < this.state.textEditorData.length ? this.getItemIndexWithElementKey(this.currentElementKey) : 0;
		const newEditorData = [...this.state.textEditorData];
		newEditorData[indexItem].elementKey = _elementKey++;
		newEditorData.splice(indexItem, 1, newObjectData);
        this.setState({textEditorData : newEditorData});
    }	
    requestInsertYoutubeLinkFromTextArea(elementKey : number, type : TextEditorElementType, elementData : any, url : string) {
    	this.deleteTextAndInserObject(elementKey, type, elementData,
    		{ type : ElementType.youtube, elementKey : _elementKey++, data : { youtubeLink : url} }
		);
    }
    requestInsertLinkPreviewFromTextArea(elementKey : number, type : TextEditorElementType, elementData : any, url : string) {
    	this.deleteTextAndInserObject(elementKey, type, elementData,
    		{ type : ElementType.link, elementKey : _elementKey++, data : { url } }
		);
	}


	requestKeyUp(elementKey : number) {
		let idxCurrent =  this.getElementIndex(elementKey);
		if( idxCurrent === 0 )
			return ;

		for( let i = idxCurrent-1;	0 <= i;   --i ) {
			if( this.state.textEditorData[i].type === ElementType.text ) {
				this.updateElement( this.state.textEditorData[i].elementKey, {isFocus : true} )
				break;
			}
		}
	}
	requestKeyDown(elementKey : number) {
		let idxCurrent =  this.getElementIndex(elementKey);
		for( let i = idxCurrent+1;	i < this.state.textEditorData.length;   ++i ) {
			if( this.state.textEditorData[i].type === ElementType.text ) {
				this.updateElement( this.state.textEditorData[i].elementKey, {isFocus : true});
				break;
			}
		}
	}

	requestMentionInput(elementKey : number, type : TextEditorElementType, isShow : boolean, clientScreenRect? : Rect, suggestionText? : string) {
		if( suggestionText && this.props.requestMentionUserInfo ) {
			this.props.requestMentionUserInfo(suggestionText, (suggestionUserList)=>{
				this.setState({
					isOpenMentionSuggestion : isShow,
					suggestionInputClientScreenRect : clientScreenRect,
					suggestionText,
					suggestionUserList
				});
			});

		} else {
			this.setState({
				isOpenMentionSuggestion : isShow,
				suggestionInputClientScreenRect : clientScreenRect,
				suggestionText : "",
				suggestionUserList : this.props.friendList
			});
		}
	}
	updateSuggestionText(suggestionText : string) {
		if( 0 < suggestionText.length  ) {
            if( this.props.requestMentionUserInfo) {
                this.props.requestMentionUserInfo(suggestionText, (suggestionUserList)=>{
    				this.setState({suggestionText, suggestionUserList});
    			});
            }
		} else  {
			this.setState({suggestionText : "", suggestionUserList : this.props.friendList});
		}
	}
	updateSuggestionSpecialKeyCode(suggestionSpecialKeycode : number) {
		this.setState({suggestionSpecialKeycode});
	}
	initSuggestionSpecialKeyCode() {
		this.setState({suggestionSpecialKeycode : 0});
	}
	onResponseSuggestionResult(badgeInfo : BadgeInfo | null) {
		// detect @ start position from current cursor position
		// detect @ end position from current cursor position
		// delete @ text
		// insert @ text with username for display
		//
		// Example)
		// const orgText = "hello @bg and @bgbg";
		// console.log( replaceMarkerText(orgText, 8, "@", "@bgkim") );
		// console.log( replaceMarkerText(orgText, 18, "@", "@김병건") );
		function replaceMarkerText(text : string, cursorPos : number, marker : string, replaceText : string) {
			// detect @ start position from cursor position
			let startPosition = -1;
			for( let i = cursorPos;    0 <= i;    --i  ) {
				if( text[i] === marker ) {
					startPosition = i;
					break;
				}
			}

			let endPosition = text.length;
			for( let i = cursorPos;    i < text.length;    ++i  ) {
				if( text[i] === ' ' ) {
					endPosition = i-1;
					break;
				}
			}

			let resultString = "";
			for( let i = 0;      i < text.length;    ++i ) {
				if( i < startPosition || endPosition < i)
					resultString = resultString + text[i];
				else if( i === startPosition )
					resultString = resultString + replaceText;
			}

			// if maker is last charactor, then insert space
			let newCursorPos = startPosition + replaceText.length;
			if(endPosition === text.length)
				resultString += " ";

			++newCursorPos;
			return {
				replacedText  : resultString,
				newCursorPos  : newCursorPos
			};
		}


		const element = this.getElement(this.currentElementKey);
		shsAssert(element!==null);

		// Data text : hello !/%obj%/! and hello !/%obj%/!
		// Object array : [ object_data1, object_data2 ]
		// Display text : Hello @bg and hello @bgbg
		const displayText = element!.data.text;
		const originCursorPos = element!.data.cursorPos;


        if( badgeInfo ) {
            const resultDisplayText = replaceMarkerText(displayText, originCursorPos, "@", "@" + badgeInfo.displayName );
    		element!.data.text = resultDisplayText.replacedText;
    		element!.data.cursorPos = resultDisplayText.newCursorPos;
        } else {
            // if cursor is last position then add space
            if( element!.data.text.length === element!.data.cursorPos )
                element!.data.text +=  " ";
            ++element!.data.cursorPos;
        }



		element!.elementKey  = _elementKey++;
		this.setState({isOpenMentionSuggestion : false});
	}
	onResponseSuggestionCancel() {
		this.updateElementData(this.currentElementKey, {isCancelSuggestion : true});
		this.setState({isOpenMentionSuggestion : false}) ;
	}


    requestLinkMetaTagInfo(url : string, cb : (info:any)=>void) {
        if( this.props.requestLinkMetaTagInfo )
    	   this.props.requestLinkMetaTagInfo(url, cb);
    }






    insertDataCurrentLine(type : ElementType, elementKey : number, elementData : any) {
        const indexItem = this.getItemIndexWithElementKey(this.currentElementKey);
        const newEditorData = [...this.state.textEditorData];
		newEditorData.splice(indexItem+1, 0, { type, elementKey, data : elementData  } );
		this.setState({textEditorData : newEditorData});
		console.info("newEditorData", newEditorData);
	}

    getItemIndexWithElementKey(elementKey : number) {
        if( elementKey === -1 )
            return this.state.textEditorData.length;


        let itemIndex;
        for( itemIndex = 0;    itemIndex < this.state.textEditorData.length;    ++itemIndex   ) {
            if( this.state.textEditorData[itemIndex].elementKey === elementKey )
                return itemIndex;
        }
        return this.state.textEditorData.length;

    }


	async onYoutubeInsertClick() {
		const result = await appAlert.showInputDialog("Youtube URL");
		if( result.isConfirmed === true ) {
			const youtubeUrl = result.value.trim();
			if( youtubeUrl.length === 0 || utils.isUrlYoutube(youtubeUrl) === false )
				return appToast.error("유튜브 URL이 아닙니다.");;
			
			this.insertDataCurrentLine( ElementType.youtube, _elementKey++, { youtubeLink : youtubeUrl});
		}
	}

    onImageInsertClick() {
        this.refImageFiles.current.click();
    }

	async onLinkInsertClick() {
		const result = await appAlert.showInputDialog("Link URL");		
		if( result.isConfirmed === true ) {
			const linkUrl = result.value.trim();
			console.info("linkUrl>>>", linkUrl);
			if( linkUrl.length === 0 || utils.isUrl(linkUrl) === false )
				return appToast.error("URL 형식이 아닙니다.");			
			this.insertDataCurrentLine( ElementType.link, _elementKey++, { url : linkUrl});
		}
	}

    
    getElementProps(key : number, type : TextEditorElementType, data : any) {
        let elementProps : TextEditorElementProps = {
            notifyUpdateEditorData :  this.notifyUpdateEditorData,
			notifySelectedItem : this.notifySelectedItem,
			notifyObjectLoaded : this.notifyObjectLoaded,
			requestDeleteElement : this.requestDeleteElement,
			requestDeleteTextAreaWithDeleteKey : this.requestDeleteTextAreaWithDeleteKey,
			notifyUpdateDataInfo : this.notifyUpdateDataInfo,
            editorStyle : this.props.editorStyle,
            data : data,
            elementKey : key,
            type,
			siteUrl : this.props.siteUrl
        };

        if( type === ElementType.text || type === ElementNewText ) {
            elementProps.requestDeleteTextAreaWithBackspace = this.requestDeleteTextAreaWithBackspace;
            elementProps.requestInsertYoutubeLinkFromTextArea = this.requestInsertYoutubeLinkFromTextArea;
			elementProps.requestInsertLinkPreviewFromTextArea = this.requestInsertLinkPreviewFromTextArea;
			elementProps.requestKeyUp = this.requestKeyUp;
			elementProps.requestKeyDown = this.requestKeyDown;
			elementProps.requestLineFeed = this.requestLineFeed;
			// for suggestion
			elementProps.requestMentionInput = this.requestMentionInput;
			elementProps.updateSuggestionText = this.updateSuggestionText;
			elementProps.updateSuggestionSpecialKeyCode = this.updateSuggestionSpecialKeyCode;
        } else if( type === ElementType.link ) {
        	elementProps.requestLinkMetaTagInfo = this.requestLinkMetaTagInfo;
        } else if( type === ElementType.image ) {
        	elementProps.requestUploadImage = this.props.requestUploadImage;
            elementProps.onProvideImageData = this.props.onProvideImageData;            
        }

        return elementProps;
    }


    onClickTextSize() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		switch(currentTextStyle ) {
			case TextStyle.normal :
				nextTextStyle = TextStyle.large;
				break;
			case TextStyle.large:
				nextTextStyle = TextStyle.extraLarge;
				break;
			case TextStyle.extraLarge:
				nextTextStyle = TextStyle.normal;
				break;
			default :
				nextTextStyle = TextStyle.normal;
				break;
		}
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}

	onClickBullet() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		if( currentTextStyle !== TextStyle.bullet )
			nextTextStyle = TextStyle.bullet;
		else
			nextTextStyle = TextStyle.normal;
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}

	onClickQuotation() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		if( currentTextStyle !== TextStyle.quotation )
			nextTextStyle = TextStyle.quotation;
		else
			nextTextStyle = TextStyle.normal;
		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true})
	}


	onClickNumberedList() {
		const currentTextStyle = this.getCurrentElementProperty("textStyle");
		let nextTextStyle;
		let listNumber = 1;
		if( currentTextStyle !== TextStyle.numberedList )  {
			nextTextStyle = TextStyle.numberedList;
			const prevElement = this.getPreviousElement();
			if( prevElement  && prevElement.type === ElementType.text &&  prevElement.data.textStyle === TextStyle.numberedList ) {
				listNumber = prevElement.data.listNumber + 1;
			}
		}
		else
			nextTextStyle = TextStyle.normal;

		this.updateElement(this.currentElementKey, {textStyle:nextTextStyle, isFocus:true, listNumber})
	}

	async onImagesInsert(event : any) {
		const files = event.target.files;
		if ( !(FileReader && files && files.length) )
			return ;

		const indexItem = this.getItemIndexWithElementKey(this.currentElementKey);
		const newEditorData = [...this.state.textEditorData];

		// 현재라인에서 거꾸로 입력해야 입력한 순서대로 나오게 된다.
		for( let i = files.length-1;		0 <= i;		--i)  {
			newEditorData.splice(
				indexItem+1, 0,
				{ type: ElementType.image, elementKey :  _elementKey++, data : { file : files[i] } }
			);
		}

		this.setState({
			textEditorData : newEditorData
		});
	}

	notifyUpdateDataInfo(elementKey : number, newValue : any) {		
		this.updateElementData(elementKey, newValue);
	}


	async encode() : Promise<EditorEncodeResult> {
		function encodeElement(json : any) {
			return TagStartSymbol + JSON.stringify(json)  + TagEndSymbol;
		}
		function isMentiontext(text : string) {
			return 0 <= text.indexOf("@");
		}
		function extractMentions(text : string, outArray : MentionData[]) {
			let mention = [];
			let isMentionStart = false;
			for( let i = 0;		i < text.length;	++i ) {
				if( text[i] === "@"  ) {
					isMentionStart = true;
				} else if ( isMentionStart === true && text[i] === ' ' ) {
					isMentionStart = false;
					if( 0 < mention.length)  {
						outArray.push({ userName : mention.join('') });
						mention = [];
					}

				} else if( isMentionStart === true ) {
					mention.push(text[i]);
				}
			}

			if( isMentionStart === true ) {
				outArray.push({ userName : mention.join('') });
			}

		}

		const textEditorData = this.state.textEditorData;

		/*
			멘션이 포함되어 있는지 확인하고 서버에 요청할 멘션 정보리스트를 만든다.
			멘션 정보를 얻었다면 정보가 있는 위치의 텍스트를 멘션 정보로 대체한다.
			mentionInfo {
				element : {...},
				mentions : [{
					username : bg
					info : {...}
			}]}
		*/
		// 요청할 멘션 리스트 및 멘션 정보를 만든다
		const mentionInfoList = [];
		let element;
		let mentionUserList : string[] = [];
		for( let i = 0;		i < textEditorData.length;	++i ) {
			element = textEditorData[i];
			if( element.type === ElementType.text ) {
				if( isMentiontext(element.data.text) ) {
					let mentions : MentionData[] = [];
					extractMentions(element.data.text, mentions);
					for( let j = 0;		j < mentions.length;	++j )
						mentionUserList.push( mentions[j].userName );
					mentionInfoList.push({element, mentions});
				}
			}
		}

        const mentionUsers : number[] = [];
        if( this.props.requestMentionListUserInfo && 0 < mentionUserList.length) {
            // console.info("mentionUserList >>>>", mentionUserList);

            // 받은 데이터를 이용해서 정보 map 을 만든다.
    		const responseMentionInfo = (await this.props.requestMentionListUserInfo(mentionUserList)).list;
    		const mapMentionInfo = new Map();
    		for( let i = 0;		i < responseMentionInfo.length;		++i ) {
    			mapMentionInfo.set(responseMentionInfo[i].userName, responseMentionInfo[i]);
                mentionUsers.push(responseMentionInfo[i].id);
            }

    			// console.info("mentionInfoList", mentionInfoList);
    		// mentino element 의 멘션 위치에 멘션 정보를 대체한다.
    		let mentionUser;
    		for( let i = 0;		i < mentionInfoList.length;		++i ) {
    			for( let j = 0;		j < mentionInfoList[i].mentions.length;		++j ) {
    				mentionUser = mentionInfoList[i].mentions[j].userName;

                    let encodeData = encodeElement({
                        ...mapMentionInfo.get(mentionUser),
                        type : ElementType.inlineMention
                    });


    				mentionInfoList[i].element.data.text = mentionInfoList[i].element.data.text.replace(
    					"@" +  mentionUser,
    					encodeData
    				);
    			}
    		}
        }

		let encodedText = "";
        let relativeFiles = [];
		for( let i = 0;		i < textEditorData.length;	++i ) {
			element = textEditorData[i];
			if( element.type === ElementType.text ) {
				switch(element.data.textStyle) {
					case TextStyle.normal :
						encodedText += element.data.text + "\n";
						break;
					case TextStyle.large :
					case TextStyle.extraLarge :
					case TextStyle.quotation :
					case TextStyle.bullet :
						encodedText += encodeElement({ type : element.type,  textStyle : element.data.textStyle, text : element.data.text });
						break;
					case TextStyle.numberedList :
						encodedText += encodeElement({ type : element.type,  textStyle : element.data.textStyle, text : element.data.text, listNumber : element.data.listNumber  });
						break;
					default :
						encodedText += element.data.text + "\n";
						break;
				}
			} else if (element.type === ElementType.image) {
				console.info("image data>>>", element.data);
				const fileInfo : FileInfo = element.data;
                relativeFiles.push(element.data.id);
				encodedText += encodeElement({
                    type : element.type,
                    id : fileInfo.id,
					fileName : fileInfo.name,
					fileType : fileInfo.fileType,
					mdWidth : fileInfo.mdWidth,
					mdHeight : fileInfo.mdHeight
				});
			} else if (element.type === ElementType.link) {
				encodedText += encodeElement({
                    type : element.type,
                    preview : element.data.preview,
					url : element.data.url,
				});
			} else if (element.type === ElementType.youtube) {
                // 기존 데이터는 id로 저장하기에 기존 데이터의 호환을 위해 id로도 저장한다.
				encodedText += encodeElement({
					type : element.type,
                    id : element.data.youtubeId,					
					youtubeLink : element.data.youtubeLink,					
				});
			}
		}
		return {encodedText, relativeFiles, mentionUsers};
	}

    test() {
        alert("teest");
    }

    onClickEmptyArea() {
        // focus last element and last position
        const element = this.state.textEditorData[this.state.textEditorData.length-1];
        element.data = {...element.data, isFocus : true, cursorPos : element.data.text.length };
        element.elementKey = _elementKey++;
        this.updateScreen();
    }

    onBlur() {

    }

    onFocus() {
        if( this.props.onFocus )
            this.props.onFocus();
    }



    render() {
		console.info("this.state.textEditorData>>>", this.state.textEditorData);

        return (
            <div className="spacehub-text-editor" tabIndex={0} onBlur={this.onBlur} onFocus={this.onFocus}  ref={this.refEditor}>
				<div className="spacehub-text-body-container">

					<div className="toolbar-panel"
						style={{
							display: this.props.isShowToolbar ? "flex" : "none",
							backgroundColor : this.props.editorStyle?.toolbarBacground
						}}
					>
						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onYoutubeInsertClick} >
							<YouTubeIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onImageInsertClick} >
							<ImageOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="add-image" onClick={this.onLinkInsertClick} >
							<InsertLinkIcon/>
						</IconButton>
						

						<Divider orientation='vertical'/>

						<IconButton className="toolbar-icon" aria-label="text-size" onClick={this.onClickTextSize}>
							<FormatSizeOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="numbered-list" onClick={this.onClickNumberedList}>
							<FormatListNumberedOutlinedIcon/>
						</IconButton>
						<IconButton className="toolbar-icon" aria-label="bullet" onClick={this.onClickBullet}>
							<FormatListBulletedOutlinedIcon/>
						</IconButton>

						<IconButton className="toolbar-icon" aria-label="quotation" onClick={this.onClickQuotation}>
							<FormatQuoteOutlinedIcon/>
						</IconButton>
						
						
					</div>

					<div className="spacehub-text-body" >
    					{
    						this.state.textEditorData.map((data, index)=>{
    							const elementProps = this.getElementProps(data.elementKey, data.type, data.data);
    							switch( data.type ) {
    								case ElementType.text : {
                                        if( index === 0 && this.state.textEditorData.length === 1)
                                            elementProps.placeholder = this.props.placeholder;
    									let component =  React.createElement(SHSTextArea, elementProps as any);
    									return <React.Fragment key={data.elementKey}>{component}</React.Fragment>
    								}
									
    								case ElementType.image :  {
										console.info("elementProps>>>>############", elementProps);
    									let component =  React.createElement(SHSImage, elementProps as any);
    									return <React.Fragment key={data.elementKey}>{component}</React.Fragment>
    								}
    								case ElementType.youtube : {
    									elementProps.youtubeApiKey = this.props.youtubeApiKey;
    									let component =  React.createElement(SHSYoutube, elementProps as any);
    									return <div key={data.elementKey}>{component}</div>
    								}
    								case ElementType.link : {
    									let component =  React.createElement(SHSLink, elementProps as any);
    									return <div key={data.elementKey}>{component}</div>
    								}

    								default: {
    									return <div key="-1"></div>;
    								}
    							}

    						})
    					}
                        <div className="empty-area" onClick={this.onClickEmptyArea}/>
					</div>
				</div>



				<SHSSuggestion
					open={this.state.isOpenMentionSuggestion}					
					userList = {this.state.suggestionUserList}
					inputScreenRect = {this.state.suggestionInputClientScreenRect}
					specialKey = {this.state.suggestionSpecialKeycode}
					initSpecialKey = {this.initSuggestionSpecialKeyCode}
					onResult = {this.onResponseSuggestionResult}
					onCancel = {this.onResponseSuggestionCancel}
                    siteUrl = {this.props.siteUrl}
				/>

				<input
					ref={this.refImageFiles}
					type="file"
					onChange={this.onImagesInsert}
					style={{display:"none"}}
					accept="image/*"
					multiple
					>
				</input>

            </div>
        );
    }
}
