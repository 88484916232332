/**************************************************************************************************
    FileName  : ClassApplication.tsx
    Description

    Update History 
      2023.07    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import {useEffect, useState} from "react";
// Import for Css
import "./ClassApplication.scss";
// Import for Material UI
import {
    Stack, Typography, TextField, Button, Divider,
} from "@mui/material";
// Import for Project Base
import { api, appAlert, appToast, pathTracker } from "libs/stdlib";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface ApplicantInfo {
    name : string;
    phoneNumber : string;
    email? : string;
    language : string;
    time1 : string;
    time2 : string;
    time3? : string;
    memo? : string;
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////

function ClassApplication() {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const [name, setName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [language, setLanguage] = useState<string>("");
    const [time1, setTime1] = useState<string>("");
    const [time2, setTime2] = useState<string>("");
    const [time3, setTime3] = useState<string>("");
    const [memo, setMemo] = useState<string>("");



    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_applyClass(info : ApplicantInfo) {        
        await api.post(`/api/spaces/bgprogramming/class-application`, info )
    }

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        pathTracker.buildStart().add("BGProgramming").build();

    })
    

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function _checkRequiredString(s : string, errorString : string) {
        if( s.trim().length === 0 )
            throw errorString;
    }


    async function onApplyClick() {
        try {
            _checkRequiredString(name, "이름은 필수값입니다.");
            _checkRequiredString(phoneNumber, "연락처를 입력해 주십시오.");
            _checkRequiredString(language, "개발 언어를 입력해 주십시오.");
            _checkRequiredString(time1, "첫번째 시간을 알려주세요.");
            _checkRequiredString(time2, "두번째 시간을 입력해 주십시오.");
        } catch(e : unknown) {
            return appToast.error( e as string);
        }


        const result = await appAlert.showQuestionDialog("BG Programming", "BGProgramming 온라인 수강을 신청하시겠습니까?");
        if( result.isConfirmed === false )  return ;
                
        await api_applyClass({
            name, phoneNumber, email, language, time1,
            time2, time3, memo
        });
        appToast.success("BG Programming 수강을 신청했습니다.");
        setName("");
        setPhoneNumber("");
        setEmail("");
        setLanguage("");
        setTime1("");
        setTime2("");
        setTime3("");
        setMemo("");
        
    }
    


    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack>
            <Typography variant="h4">BG Programming 수강생 모집</Typography>

            <Typography mt={4}>
                BGProgramming 채널에서 온라인 수강생을 모집합니다.<br/>
                대상은 회사에 다니면서 회사 업무가 버겁다거나 회사에 사수가 없다거나 하시는 분들이면 좋습니다.<br/>
                혹은 기본적인 개발에 대한 사항은 배웠으나 그 다음 단계를 어디로 가야 할지 모르시는분도 좋습니다.<br/>
                아무래도 평일 밤이나 주말정도가 수업이 많이 있지 않을까 예상하고 있습니다.<br/>
                시간과 진행하고자 하는 언어를 한번 요청주시면 감사하겠습니다.<br/>
                제가 최근 5년이상은 주로 NodeJS와 React를 메인 프로젝트를 하다 보니 해당 언어와 프레임워크라면 상용 프로그램 수준으로 해드릴 수 있습니다.<br/>
                기간은 3개월 정도로 생각하고 있습니다.<br/>
                그리고 수업 주제도 자신이 목적을 가지고 오시는것이 좋습니다.<br/>
                특정 사이트나 소프트웨어를 만드는데 도와드릴 수도 있고 아니면 클라이언트 개발자인데 서버 개발을 배워 보실수도 있습니다.<br/>
                제가 강점인 사항은 딥러닝등의 특정 전분 분야가 아니라 소프트웨어 개발 자체에 있습니다.<br/>
                클라이언트나 서버 혹은 소프트웨어 프로그래밍을 잘하고 싶은 분이면 환영합니다.<br/>
                그리고 맞춤형으로 진행이 될 수 있기 때문에 1:1 수업이 되지 않을까 합니다.<br/>
                이에 많이 모집하지는 못하고 3명 ~ 5명 정도로 예상하고 있습니다. <br/>
                감사합니다.
            </Typography>
            
            <Typography mt={4}>수강료 : 월 60만원</Typography>
            <Typography >수업시간 : 일주일 1~2시간, 2회</Typography>
            <Typography mb={1} >기간 : 3개월</Typography>

            <Typography mb={4} >* 참고 : 기간은 3개월이라고 적었습니다만 한 템포를 쉬어가기위한 시간입니다. 내용에 따라 다음 기간으로 넘어갈 수 있습니다.</Typography>

            <Divider></Divider>
            
            <Typography variant="h6"  mt={5} mb={2}>신청자 정보</Typography>
            <TextField variant="standard" sx={{"maxWidth" : "200px" }} label="이름 *" value={name} onChange={(e)=>setName(e.target.value)} />
            <TextField variant="standard" sx={{"maxWidth" : "200px", "marginTop":"12px" }} label="폰번호 *"  value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} />
            <TextField variant="standard" sx={{"maxWidth" : "200px", "marginTop":"12px" }} label="이메일" value={email} onChange={(e)=>setEmail(e.target.value)}/>
            

            <TextField variant="standard" sx={{"maxWidth" : "480px", "marginTop":"36px" }} label="개발언어 *" placeholder="ex) Type Script, C++, Java, Kotlin" helperText="진행하고자 하는 언어를 적어주세요. 프레임워크도 적어주시면 좋습니다" value={language} onChange={(e)=>setLanguage(e.target.value)}/>
            
            <TextField variant="standard" sx={{"maxWidth" : "480px", "marginTop":"36px" }} label="신청시간 1 *" placeholder="ex) 목요일 20:00 ~ 22:00" helperText="신청 시간이 겹칠경우 시간이 조율될 수 있습니다." value={time1} onChange={(e)=>setTime1(e.target.value)}/>
            <TextField variant="standard" sx={{"maxWidth" : "480px", "marginTop":"12px" }} label="신청시간 2 *" placeholder="ex) 일요일 10:00 ~ 12:00" value={time2} onChange={(e)=>setTime2(e.target.value)}/>
            <TextField variant="standard" sx={{"maxWidth" : "480px", "marginTop":"12px" }} label="신청시간 후보" placeholder="ex) 월요일 22:00 ~ 23:00" value={time3} onChange={(e)=>setTime3(e.target.value)}/>

            <TextField 
                variant="standard" 
                sx={{"maxWidth" : "480px", "marginTop":"36px" }}
                label="수업목적 및 요청사항" 
                placeholder="수업을 통해서 얻어가고자 하는 목적은 무엇인가요?
요청하고 싶은 사항이 있거나 메모 사항을 남겨주세요." 
                value={memo} 
                onChange={(e)=>setMemo(e.target.value)}
                multiline
                minRows={2}
            />
            
            <Button sx={{"maxWidth" : "480px", "marginTop":"24px"}} variant="outlined" onClick={onApplyClick}>제출</Button>
            <Typography mt={1}>수업 신청 접수 후 연락을 드려 시간을 조율한 이후에 입금을 진행하면 수업이 진행됩니다.</Typography>            
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                11                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default ClassApplication;
  