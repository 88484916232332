/**************************************************************************************************
    ItemDBDetail : utilsEx.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { EnumOpenSpace, EnumSpaceType } from 'types';
import Utils from './utils';
import shsAssert from './shsAssert';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    UtilsEx Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
class UtilsEx extends Utils {
    public getDateString(utcTime : number) : string {
        const dt : Date = new Date(utcTime * 1000);
        return `${dt.getFullYear()}.${dt.getMonth()+1}.${dt.getDate()}`;
    }

	public getSpaceAndThreadInfo() {
		// pathName : /open-spaces/diablo4/threads/diablo4
		// pathName : /group-spaces/hire-diversity

		let spaceType : EnumSpaceType | null = null;
		const arPath = window.location.pathname.split("/");        
		let spaceName : string | null = null;
		if( arPath[1] === "open-spaces"  && arPath[2] ) {
			spaceType = EnumSpaceType.open;
			spaceName = arPath[2];
		} else if( arPath[1] === "group-spaces"  && arPath[2] ) {
			spaceType = EnumSpaceType.group;
			spaceName = arPath[2];
		} else {
			spaceType = EnumSpaceType.open;
			spaceName = EnumOpenSpace.spacehub;
		}

		let threadName : string | null = null;
		if( arPath[3] && arPath[3] === "threads" && arPath[4] ) 
			threadName = arPath[4];
			
		shsAssert( spaceType !== null);
		return { 
			pathSpaceType : spaceType!,
			pathSpaceName : spaceName,
			pathThreadName : threadName
		};
	}
}




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Exports                                                    //
///////////////////////////////////////////////////////////////////////////////////////////////////
const _instance = new UtilsEx();
export default _instance;
