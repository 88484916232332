import { TradeChattingRoomState, ChattingRoomMessage } from "./chatting";
import { BadgeInfo } from "./user";

export enum EnumDiablo4Rarity {
	magic = "magic",
    rare = "rare",
    legend = "legend"
}

export enum EnumDiablo4EquipmentType {
	helm = "helm",
    amulet = "amulet",
    weapon = "weapon",
    shield = "shield",
    torso = "torso",
    legs = "legs",
    gloves = "gloves",    
    boots = "boots",
    ring = "ring",
    essence = "essence"
}



export interface Diablo4Store {
	id : number;
    badgeId : number;
    name : string;
    displayName : string;
    introduce : string;
    rating : string;
    ratingCount : number;
}

export enum EnumDiablo4TradeCategory {
    d4Item = "d4-item"
}

export enum EnumTradeState {
    onSale = "on-sale",
    soldOut = 'sold-out',
    archived = 'archived'
}

export enum EnumTradeType {
    price = "on-price",
    auction = 'auction',    
}

export interface TradeBaseInfo{
    category : EnumDiablo4TradeCategory;
    createTime : number;
    state : EnumTradeState;
    type : EnumTradeType;
}

export interface TradeInfo {
    tradeBaseInfo : TradeBaseInfo;
    storeInfo : Diablo4Store,
    tradeId : number;    
    itemId: number;        
    amount : number;
    isHardcore : boolean;
    isLadder : boolean;
    itemOptions : Diablo4ItemOption[],
    priceAmount? : number,
    priceItemId? : number,
    reqLevel? : number,    
}

export interface Diablo4Item {
    itemId: number;
    name: string;    
    rarity: EnumDiablo4Rarity;
    equipmentType: EnumDiablo4EquipmentType | null;        
    description: string;    
    imageUrl: string;
    localName?: string;    
}
  

export interface Diablo4ItemOption {
	optionId : number;
    optionIdTxt : string;
    label : string;     // useing with Client
    val : string;
}



export interface Diablo4TradeChattingRoomInfo {
    chattingRoomInfo : {
        tradeInfo : TradeInfo;
        sellerInfo : BadgeInfo,
        buyerInfo : BadgeInfo,
        state : TradeChattingRoomState;
    };
    messages : ChattingRoomMessage[];


}

