/**************************************************************************************************
    FileName  : spaceRouter.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// import Home from "./Home";
import App from "./App";
import DefaultLayout from "./DefaultLayout";
import ErrorPage from "pages/ErrorPage";
import spaceRouter from "./space/spaceRouter";
import authRouter from "./auth/authRouter";
import postRouter from "./post/postRouter";
import PrivacyPolicy from "./PrivacyPolicy";
import UserAgreement from "./UserAgreement";
import OpenSpaceHomePage from "./space/OpenSpaceHomePage";
import { EnumOpenSpace } from "types";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Routes                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////  
const route = [{
	element: <App/>,
	errorElement : <ErrorPage />,
	children:
	[
		{
			element: <DefaultLayout/>,
			children :
			[
				{
					path: "/",
					element: <OpenSpaceHomePage spaceName={EnumOpenSpace.spacehub}/>,
				},
				{
					path: "/private-policy",
					element: <PrivacyPolicy/>,
				},
				{
					path: "/user-agreement",
					element: <UserAgreement/>,
				},	
				...postRouter,
				...spaceRouter,
			]
		},
		...authRouter
	]
}];


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default route;