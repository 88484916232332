/**************************************************************************************************
    FileName  : CreateAndEditStorePage.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./CreateAndEditStorePage.scss";
// Import for React
import { useEffect, useState } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Typography, Button,TextField
} from "@mui/material";
// Import for Project Base
import { api, appToast, apiCommon, appNavigator, pathTracker } from "libs/stdlib";
import { Diablo4Store, EnumOpenSpace } from "types";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpaceHome Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
function CreateAndEditStore() {
    /////////////////////////////////////////////////////////////////////////////
    // Const
    const [storeName, setStoreName] = useState<string>("");
    const [isAvailableStoreName, setAvailableStoreName] = useState<boolean>(false);    
    const [displayName, setDisplayName] = useState<string>("");
    const [introduce, setIntroduce] = useState<string>("");
    const urlParams = useParams();
    const isEditMode = urlParams.storeName ? true : false;

    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_checkStoreName(storeName : string) {
        await api.post(
            `/api/diablo4/store/check`,
            {name : storeName},
            (data : {isAvailable : boolean})=>{                
                setAvailableStoreName(data.isAvailable)
                if( data.isAvailable ) {
                    appToast.success("사용 가능한 이름입니다.");
                } else {
                    appToast.error("상점 이름이 중복 되었습니다.");
                }
            }
        );
    }

    async function api_createDiablo4Store(storeName : string, displayName : string, introduce? : string) {
        await api.post(
            `/api/diablo4/store`,
            {name : storeName, displayName, introduce},
            ()=>{ 
                appNavigator.diablo4.goStore(storeName);
                appToast.success("상점을 생성했습니다.");
            }
        );
    }

    async function api_updateDiablo4Store(storeName : string, displayName? : string, introduce? : string) {
        await api.put(
            `/api/diablo4/stores/${storeName}`,
            {displayName, introduce},
            ()=>{ 
                appNavigator.diablo4.goStore(storeName); 
                appToast.success("상점 정보를 수정했습니다.");
            }
        );
    }


    async function api_getStoreInfo(storeName : string) {
        await apiCommon.diablo4.getStoreInfo(storeName, (storeInfo : Diablo4Store)=>{
            console.info("storeInfo>>>", storeInfo);
            setStoreName(storeInfo.name);
            setDisplayName(storeInfo.displayName);
            setIntroduce(storeInfo.introduce);            
        });
    }


    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        if( isEditMode ) {
            (async ()=>{
                await api_getStoreInfo(urlParams.storeName!);
            })();
        }

        if( isEditMode) {
            pathTracker.buildStart()
                .add("다이블로4", ()=>appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) )
                .add("내상점", ()=>appNavigator.diablo4.goStore(urlParams.storeName!) )
                .add("정보수정")
                .build();
        } else {
            pathTracker.buildStart()
                .add("다이블로4", ()=>appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) )                
                .add("상점생성")
                .build();
        }
        
            
        //eslint-disable-next-line        
    },[]);

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    async function checkStoreName() {
        await api_checkStoreName(storeName);
    }

    function onStoreNameChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setStoreName(event.target.value);
        setAvailableStoreName(false);
    }

    function isDisableCreateButton() {
        return !isAvailableStoreName || displayName.trim().length === 0;
    }

    async function onCreateStoreClick() {
        await api_createDiablo4Store(storeName, displayName, introduce);
    }

    async function onUpdateStoreClick() {
        await api_updateDiablo4Store(storeName, displayName, introduce);
    }



    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="createAndEditStorePage-230516">
            <Typography variant="h5">디아블로4 상점 {isEditMode ? "정보수정" : "생성"}</Typography>

            <Typography mt={4}>상점이름 *</Typography>
            <Typography variant="body2" color="red">상점이름은 URL에 표시되며 한번 설정하면 변경할 수 없습니다.</Typography>
            <Stack className="store-input-panel">
                <TextField 
                    className="store-name"  
                    variant="standard" 
                    value = {storeName}
                    onChange={onStoreNameChange}
                    disabled={isEditMode}
                />
                <Button className="btn-check"  variant="outlined" disabled={isEditMode} onClick={checkStoreName}>중복확인</Button>
            </Stack>
            

            <Typography mt={4}>표시이름 *</Typography>
            <Typography variant="body2">일반 유저에게 표시하는 이름입니다.</Typography>
            <TextField  
                variant="standard" 
                value={displayName}
                onChange={(e)=>setDisplayName(e.target.value)}
            />

            <TextField 
                className="introduce" 
                label="상점 소개" 
                multiline 
                minRows={4}
                variant="standard"
                value={introduce}
                onChange={(e)=>setIntroduce(e.target.value)}
            />

            {!isEditMode && <Button className="btn-create" variant="outlined" disabled={ isDisableCreateButton() }  onClick={onCreateStoreClick}>상점생성</Button> }
            {isEditMode && <Button className="btn-create" variant="outlined" disabled={ 0 === displayName.trim().length }  onClick={onUpdateStoreClick}>정보수정</Button> }

           
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default CreateAndEditStore;
  