/**************************************************************************************************
    FileName  : SHSLink.ts
    Description : 
	  링크를 생성시 바로 정보를 요청한다.
	  서버에서는 해당 링크의 정보를 받아서 클라이언트에 돌려주고,
	  이는 시간안에 클라이언트의 Object가 생성되는데 충분한 시간이다.
	  그리고 callback 함수가 받아 해당 정보를 업데이트한다.

    Update History 	  
      2023.06     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

import React  from 'react';
import './Editor.scss';
import { IconButton, Typography } from '@mui/material';
import {SHSElementProps, ElementType} from "../Define";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

interface SHSLinkProps extends SHSElementProps {
	requestLinkMetaTagInfo : (url : string, cb : (info : any)=>void)=>void;
	notifyUpdateDataInfo : (elementKey : number, newValue : any)=>void;
	notifyObjectLoaded : (elementKey : number, elementType : ElementType)=>void;
	requestDeleteElement : (elementKey : number)=>void;
}


export default class SHSLink extends React.Component<SHSLinkProps> {
	state : {
		title? : string;
		isSelect : boolean;
		url : string;
		image? : any;
		src? : string;
	};

	constructor(props : SHSLinkProps) {
        super(props);
		this.onFocus = this.onFocus.bind(this);
		this.extractSite = this.extractSite.bind(this);
		this.onDeleteElementClick = this.onDeleteElementClick.bind(this);

		this.state = {
            isSelect : false,
			url : props.data.url,
        };

        const _this = this;
        this.props.requestLinkMetaTagInfo(props.data.url, async (info)=>{
			console.info("SHSLink", info);			
			_this.props.notifyUpdateDataInfo( this.props.elementKey, {preview : info} );			
            _this.setState({...info});
        });        
	}

	



    onFocus() {
        this.props.notifySelectedItem(this.props.elementKey);
	}

	componentDidMount() {		
		this.props.notifyObjectLoaded(this.props.elementKey, ElementType.link);
	}

	extractSite(url : string)  {
		if( url === undefined || url === null )
			return ;

		const removedProtocolUrl = url.split("://")[1];
		const siteName = removedProtocolUrl.split("/")[0];
		return siteName.replace("www.", "").toLowerCase();
	}

	onDeleteElementClick() {
		this.props.requestDeleteElement(this.props.elementKey);
	}

    render() {		
        return (
			<div className="link-preview-container" tabIndex={0} onFocus={this.onFocus}>
				{this.state.image &&
	                <img
						className="image"
	                    src={this.state.image}
	                    alt={this.state.src}
	                />
				}

				<div className="title-desc-container">
					<Typography className="title">{this.state.title}</Typography>
					<Typography className="url">{this.extractSite(this.state.url)}</Typography>
				</div>

				<IconButton className="delete-icon" onClick={this.onDeleteElementClick}>
					<HighlightOffOutlinedIcon/>
				</IconButton>
            </div>
        );
    }
}
