/**************************************************************************************************
    FileName  : SocketManager.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////

import {
    MessageEventName, SystemEventCategory, SystemEventSubCategory, EventCategoryData
} from 'types';
import {shsAssert} from "libs/stdlib";
  

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                       Type Definitions                                     	 //
///////////////////////////////////////////////////////////////////////////////////////////////////
type SocketEventFunction = (data: any) => void; // eslint-disable-line
type AppEventFunction = (data: any, appEvent: any) => void; // eslint-disable-line
type CallbackEventCategory = (data: EventCategoryData) => void;

enum SocketEvent {
	chatting = "chatting"
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  AppSocket Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
class AppSocket {
    private _accessToken : string;
    private _socket : WebSocket
    private _clientMessageId : number;
    private _serverMessageId : number;
    // key : message id, value : callback function
    private _reponseCallbacks : Map<number, SocketEventFunction> = new Map<number, SocketEventFunction>();
    // key : event name, value : callback function
    private _eventCallbacks : Map<string, AppEventFunction> = new Map<string, AppEventFunction>();
    // key : getEventCategoryKey(), value : callback function 
    private _eventCategoryCallbacks : Map<string, CallbackEventCategory> = new Map<string, CallbackEventCategory>();
  
    public constructor(URL: string, accessToken: string) {
		this._accessToken = accessToken;
		const socket = new WebSocket(URL);
		this._socket = socket;
		this._clientMessageId = 0;
		this._serverMessageId = 0;
		this._handleEventCategory = this._handleEventCategory.bind(this);
		this.setCallbackEventCategory = this.setCallbackEventCategory.bind(this);
		this._eventCallbacks.set(MessageEventName.eventCategory, this._handleEventCategory);
	
		const that = this; // eslint-disable-line
		socket.onopen = function () {
			console.log("socket onopen>>>>>>>>");
			that.login();        
		};
	
		socket.onmessage = function (socketEvent: MessageEvent) {        
			const shsEvent = JSON.parse(socketEvent.data);
			console.info("event>>>", shsEvent);
	
			if (shsEvent.messageType === "response") {
			const fn = that._reponseCallbacks.get(shsEvent.messageId);
			if (fn) {
				fn(shsEvent.data);
				that._reponseCallbacks.delete(shsEvent.messageId);
			}
			} else {
				const fn = that._eventCallbacks.get(shsEvent.eventName);
				if (fn) {
					fn(shsEvent.data, shsEvent);
				}
			}
		}
	
		socket.onclose = function (e) {        
			console.log('Socket closed.', e);
		}
	
		socket.onerror = function (error) {        
			console.error('Socket error.', error);
		}
    }
  
    public setCallbackEvent(eventName: string, cb: AppEventFunction) {
      	this._eventCallbacks.set(eventName, cb);
    }
  
    public setCallbackEventCategory(eventCategory: SystemEventCategory, eventSubCategory: SystemEventSubCategory, callback: CallbackEventCategory) {
		const key = this._getEventCategoryKey(eventCategory, eventSubCategory);
		this._eventCategoryCallbacks.set(key, callback);
    }
  
    public login() {
		const jsonMessage = {
			messageId : this._clientMessageId++,
			accessToken : this._accessToken,
			asi : "/asi/user/login",
		};      
		this._socket.send(JSON.stringify(jsonMessage));
    }
  
    private _requestMessage(messageId: number, data: string) {
		const that = this; // eslint-disable-line
		return new Promise((resolve)=>{
			that._reponseCallbacks.set(messageId, (data) => {
				resolve(data);
			});
			that._socket.send(data);        
		})
    }
  
    private _handleEventCategory(data: EventCategoryData) {
		const key = this._getEventCategoryKey(data.eventCategory, data.eventSubCategory);
		const fn = this._eventCategoryCallbacks.get(key);
		if (fn) {
			fn({
			eventCategory : data.eventCategory,
			eventSubCategory : data.eventSubCategory,
			fromUser : data.fromUser,
			isFromWithGroup : data.isFromWithGroup,
			message : data.message,
			info : data.info,
			imageUrl : data.imageUrl,
			imageShape : data.imageShape
			});
		}
    }
  
    private _getEventCategoryKey(eventCategory: SystemEventCategory, eventSubCategory: SystemEventSubCategory): string {
      	return `${eventCategory}-${eventSubCategory }`;
    }
  
    async chatD4TradeRoom(roomId: number, message: string): Promise<string> {
		const socketMessageId = this._clientMessageId++;
		const jsonChat = {
			messageId: socketMessageId,
			accessToken: this._accessToken,
			asi: "/asi/diablo4/trade/chat/message",
			params: { roomId, message }
		};
		const res = await this._requestMessage(socketMessageId, JSON.stringify(jsonChat)) as { messageId: string };      
		return res.messageId;
    }
  
    public close() {
		this._socket.close();
    }
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SocketManager Implementation                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
class SocketManager {
	public static _instance : SocketManager | null = null;
	public static getInstance() : SocketManager {
		if( SocketManager._instance === null )
			SocketManager._instance = new SocketManager();
		return SocketManager._instance;
	}

	private _appSocket : AppSocket | null = null;
	public login(url : string, token : string) {		
		console.info("connetct with >>>>>", url);
		this._appSocket = new AppSocket(url, token);		
	}

	public logout() {
		this._appSocket?.close();
	}

	async chatD4TradeRoom(roomId: number, message: string): Promise<string> {
		shsAssert(this._appSocket !== null);
		return await this._appSocket!.chatD4TradeRoom(roomId, message);
	}

	public setCallbackEventCategory(eventCategory: SystemEventCategory, eventSubCategory: SystemEventSubCategory, callback: CallbackEventCategory) {
		this._appSocket?.setCallbackEventCategory(eventCategory, eventSubCategory, callback);
	}

	public setCallbackEvent(eventName: string, cb: AppEventFunction) {
		this._appSocket?.setCallbackEvent(eventName, cb);
	}
	
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export {SocketEvent}
export default SocketManager;