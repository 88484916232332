/**************************************************************************************************
    FileName  : PostAPI.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import api, {CallbackSuccess} from "./index";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  PostAPI Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class Diablo4API {
    public async getStoreInfo(storeName : string, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) {
        await api.get(
            `/api/diablo4/stores/${storeName}`, fnCallbackSuccess, errorMessage
        );        
    }

    public async createChattingRoom(tradeId : number, fnCallbackSuccess? : CallbackSuccess, errorMessage? : string | null) {
        await api.post(
            `/api/diablo4/trades/${tradeId}/room`, {}, fnCallbackSuccess, errorMessage
        );        
    }
}