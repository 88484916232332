/**************************************************************************************************
    ItemDBDetail : CommentList.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for CSS
import "./CommentList.scss";
// Import for React
import { useState, useEffect } from 'react';
// Import for Material UI
import {
  	Stack, Box, Typography, TextField, Button, InputAdornment, 
   	Drawer
} from '@mui/material';
// Import for Material UI Icons
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// Import for Project Types
import { EnumCommentListSortType,  EnumPostListSortType, DefaultList, PostListItemData } from 'types';
// Import for Project Base
import { appToast, api, utils } from 'libs/stdlib';
// Import for Project Components
import InfinityList, { InfinityListData } from "components/InfinityList";
import PostVoteButtonGroup from "./PostVoteButtonGroup";



import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Type Definition                                           //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface CommentInfo extends PostListItemData{  
  isShowReplyPanel: boolean;
  isShowWriteReply: boolean;
  replyPanelRefreshCount: number;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Implementation                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface CommentListPros {
  rootPostId : number;
}


export default function CommentList(props : CommentListPros) {
	const theme = useTheme()
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const buttonSize = isMdDown ? "small" : "medium";
	const buttonFontStyle = isMdDown ? { fontSize: "11px" } : {};
		
	const [sortType, ] = useState<EnumPostListSortType>(EnumPostListSortType.latest);
	const [newComment, setNewComment] = useState<string>("");
	// TODO type check	
	const [commentList, setCommentList] = useState<InfinityListData<CommentInfo>>();
	const [refreshCount, setRefreshCount] = useState<number>(0);	
	const [, setCommentMenuPost] = useState<PostListItemData | null>(null);
	const [isOpenEditCommentDrawer, setOpenEditCommentDrawer] = useState<boolean>(false);
	const [editCommentText, setEditCommentText] = useState<string>("");
	

	async function api_getPostList(_sortType: string) {    
		await api.get(
		`/api/posts/${props.rootPostId}/children?sortType=${_sortType}`,
		(data: DefaultList) => {        
			const list: CommentInfo[] = [];
			for (const post of data.list) {
			list.push({
				...post,
				isShowReplyPanel: false,
				isShowWriteReply: false,
				replyPanelRefreshCount : 0
			});
			}

			setCommentList({
			lastItemIdInList: data.lastItemIdInList,
			isLast: data.isLast,
			list
			});
		}
		);
	}

	async function api_replyPost(postId : number, description : string) {
		await api.post(`/api/posts/${postId}/reply`, {description});
	}


	
	useEffect(() => {
		(async () => {
		await api_getPostList(sortType);
		})();
		//eslint-disable-next-line
	}, [props.rootPostId]);


	// async function onRefreshClick() {
	// 	await api_getPostList(sortType);
	// }

	// async function onChangeSortType(commentSortType : EnumPostListSortType) {
	// 	setSortType(commentSortType);
	// 	await api_getPostList(commentSortType);
	// }


	async function onCommentClick() {    
		if (0 < newComment.trim().length) {
		await api_replyPost(props.rootPostId, newComment);
		setNewComment("");
		await api_getPostList(sortType);
		appToast.success("댓글을 남겼습니다.");
		}
	}

	function refreshList() {
		setRefreshCount(refreshCount + 1);
	}

	function onReplyCommentClick(comment : CommentInfo) {
		comment.isShowWriteReply = !comment.isShowWriteReply;
		refreshList();  

		const inputId = getReplyInputId(comment.id);
		const elInput = window.document.getElementById(inputId) as HTMLInputElement;
		setTimeout(() => elInput.focus(), 100);
	}

	function onCancelEditCommentClick() {
		setOpenEditCommentDrawer(false);
		setCommentMenuPost(null);
	}

	async function onEditCommentClick() {
		// shsAssert(commentMenuPost != null);
		// // TODO type check
		// // eslint-disable-next-line
		// // await api_updatePost(commentMenuPost!.id, editCommentText);

		// // TODO type check
		// // eslint-disable-next-line
		// commentMenuPost!.description = editCommentText;
		// setOpenEditCommentDrawer(false);
		// setCommentMenuPost(null);
		// refreshList();

		// // appToast.showSuccessToast(ft.common("TOAST_MESSAGE_EDIT_COMMENT_SUCCESS"));
	}

	function getReplyInputId(postId : number) {
		return postId.toString() + "-comment-input";
	}
	
	async function onReplyClick(post: CommentInfo) {
		const postId = post.id;
		const inputId = getReplyInputId(postId);
		const elInput = window.document.getElementById(inputId) as HTMLInputElement;
		const comment = elInput.value.trim();
		if (comment.length === 0)
		return;      
			
		await api_replyPost(postId, comment);
		elInput.value = "";
		post.childCount = post.childCount + 1;

		// console.info("post.isShowReplyPanel>>", post.isShowReplyPanel);

		if (post.isShowReplyPanel === false)
		post.isShowReplyPanel = true;
		else
		post.replyPanelRefreshCount++;
		
		post.isShowWriteReply = false;
		refreshList();
		// appToast.showSuccessToast(ft.common("TOAST_MESSAGE_COMMENT_SUCCESS"));    
	}

	function toggleReplyPanel(post: CommentInfo) {
		if (post.childCount === 0)
		return;
		post.isShowReplyPanel = !post.isShowReplyPanel;
		refreshList();
	}

	return (
		<Stack className="comment-section-230510">
			<TextField
			className="new-comment"          
			multiline 
			minRows={3}          
			placeholder="댓글을 작성해 주세요"
			InputProps={{
				endAdornment: <InputAdornment position="start"><Button>댓글</Button></InputAdornment>,
			}}
			value={newComment}
			onChange={(e)=>setNewComment(e.target.value)}
			onClick={onCommentClick}
		/>
		
		
			<InfinityList
			className="comment-list"
			listData={commentList}
			item={(post: CommentInfo) => {            
				return (
				<Stack className="comment-and-reply-container" key={post.id}>
					<Stack className="comment-card" >
					<Stack className="comment-cotent-container">
						<AccountCircleOutlinedIcon className="writer-profile"/>
						<Stack className="comment-info">
						<Stack className="comment-writer-container">
							<Typography className="writer-name">{post.writerInfo.displayName}</Typography>                        
							<Typography className="create-time">{utils.getDateString(post.createTime)}</Typography>
						</Stack>
						<pre className="description">{post.description}</pre>
						</Stack>                
					</Stack>
					<Stack className="action-container">
						<Button className="btn-write-reply"
						size="small"
						style={buttonFontStyle}
						onClick={() => onReplyCommentClick(post)}>
						댓글
						</Button>
						<Button
						size="small"
						onClick={() => toggleReplyPanel(post)}
						style={buttonFontStyle}
						>댓글 {post.childCount}</Button>
						<Box className="flex-grow"></Box>                  
						<PostVoteButtonGroup post={ post } />
					</Stack>
					</Stack>

					{/*******************   Replay Input    ***********************/}
					<Stack className="reply-input-container" sx={{display : post.isShowWriteReply ? "inherit" :"none"}}>
					<Stack className="comment-input-content">
						<AccountCircleOutlinedIcon className="user-icon" />          
						<TextField
							id={getReplyInputId(post.id)}
							className="new-comment-input"
							multiline placeholder="댓글을 작성해 주세요"
							rows={3}
							fullWidth                        
						/>
					</Stack>
					<Box className="btn-comment-container">
						<Button
						className="btn-comment"
						onClick={() => { onReplyClick(post) }}
						size="small"
						style={buttonFontStyle}
						>
						댓글
						</Button>
					</Box>
					</Stack>

					{/*******************   Replay Panel    ***********************/}
					{post.isShowReplyPanel && <ReplyPanel post={post} refreshCount={ post.replyPanelRefreshCount } /> }              
				</Stack>
				)
			}}
			/>

		

		<Drawer        
			anchor="bottom"
			open={isOpenEditCommentDrawer}
			onClose={()=>setOpenEditCommentDrawer(false)}
			id="FlaboVilageCommentUpdatePlanel"
		>
				<Stack className="comment-update-panel">
				<Typography className="panel-title">댓글수정</Typography>              
				<TextField multiline minRows={3} maxRows={10}  value={editCommentText} onChange={(e)=>setEditCommentText(e.target.value)} />
				<Stack className="action-button-container">
					<Button
					className="cancel-button"
					onClick={onCancelEditCommentClick}
					size={buttonSize}
					style={buttonFontStyle}
					>취소</Button>
					<Button className="confirm-button" onClick={onEditCommentClick} style={buttonFontStyle} size={buttonSize}>확인</Button>
				</Stack>
				</Stack>
		</Drawer>
		</Stack>

	)
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Reply Panel Implementation                                //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface ReplyInfo extends PostListItemData{    
	isShowWriteReply: boolean;
}

function ReplyPanel( props : { post: ReplyInfo, refreshCount : number }) {  
	const theme = useTheme()
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const buttonSize = isMdDown ? "small" : "medium";
	const buttonFontStyle = isMdDown ? {fontSize:"11px"} : {};
	const [replyList, setReplyList] = useState<ReplyInfo[]>([]);
	const [refreshCount, setRefreshCount] = useState<number>(0);	
	const [, setCommentMenuPost] = useState<PostListItemData | null>(null);
	const [isOpenEditCommentDrawer, setOpenEditCommentDrawer] = useState<boolean>(false);
	const [editCommentText, setEditCommentText] = useState<string>("");

	
	async function api_writeReply(postId : number, description : string) {
		await api.post(
		`/api/posts/${postId}/reply`,
		{ description,  notificationMessage : description }
		);
	}
	
	async function api_getReplyList(postId : number) {    
		await api.get(
		`/api/posts/${postId}/children?sortType=${EnumCommentListSortType.oldest}`,
		(data: DefaultList) => {
			console.log(data);
			const list: ReplyInfo[] = [];
			for (const post of data.list) {
			list.push({
				...post,
				isShowWriteReply : false
			});
			}
			setReplyList(list);
		}
		);
	}
	
	useEffect(()=>{
		(async ()=>{
		await api_getReplyList(props.post.id);
		})();    
	}, [props.post.id]);

	useEffect(()=>{
		(async ()=>{
		await api_getReplyList(props.post.id);
		})();
	}, [props.post.id, props.refreshCount]);

	
	function refreshList() {
		setRefreshCount(refreshCount + 1);
	}

	// reply 리스트에서 reply 버튼을 눌렀을 경우
	function onReplyClick(reply : ReplyInfo) {
		reply.isShowWriteReply = !reply.isShowWriteReply;
		if (reply.isShowWriteReply === true) {
		const inputId = getReplyInputId(reply.id);
		const elInput = window.document.getElementById(inputId) as HTMLInputElement;
		const comment = elInput.value.trim();
		if (comment.length === 0) {
			elInput.value = "@" + reply.writerInfo.displayName + " ";         
		}

		setTimeout(() => elInput.focus(), 100);      
		}

		refreshList();  
	}

	function getReplyInputId(postId : number) {
		return postId.toString() + "-reply-input";
	}

	
	// reply 글을 쓰고 댓글쓰기 버튼을 눌렀을 경우
	async function onWriteReplyClick(reply: ReplyInfo) {      
		const inputId = getReplyInputId(reply.id);
		const elInput = window.document.getElementById(inputId) as HTMLInputElement;
		const comment = elInput.value.trim();    
		if (comment.length === 0)
		return;
		// *** Remark
		// 현재 1-depth 이기에 reply id가 아닌 post id를 적어야 한다.
		await api_writeReply(props.post.id, comment);
		//////////////////////////////////////////////////////////
		await api_getReplyList(props.post.id);
		elInput.value = "";
		// appToast.showSuccessToast(ft.common("TOAST_MESSAGE_REPLRY_SUCCESS"));
	}

	// function onMoreButtonClick(el: HTMLButtonElement, post: PostListItemData) {
	// 	setCommentMenu(el);
	// 	setCommentMenuPost(post);
	// }


	/////////////////////////////////////////////////////////////////////////////
	// Comment Menus
	// function onCommentMenuCloseClick() {
	// 	setCommentMenu(null);
	// }

	// function onEditCommentMenuClick() {
	// 	setCommentMenu(null);
	// 	shsAssert(commentMenuPost != null);
	// 	// TODO type check
	// // eslint-disable-next-line
	// 	setEditCommentText(commentMenuPost!.description);    
	// 	setOpenEditCommentDrawer(true);
	// }


	/////////////////////////////////////////////////////////////////////////////
	// Edit Post
	function onCancelEditCommentClick() {
		setOpenEditCommentDrawer(false);
		setCommentMenuPost(null);
	}

	async function onEditCommentClick() {
		/*
		shsAssert(commentMenuPost != null);    
		// TODO type check
		// eslint-disable-next-line
		await apiCommon.post.updateSimplePost(commentMenuPost!.id, editCommentText);    
		// TODO type check
		// eslint-disable-next-line
		commentMenuPost!.description = editCommentText;
		setOpenEditCommentDrawer(false);
		setCommentMenuPost(null);
		refreshList();
		appToast.showSuccessToast(ft.common("TOAST_MESSAGE_EDIT_COMMENT_SUCCESS"));
		*/
	}

	
	/////////////////////////////////////////////////////////////////////////////
	// Reply List Panel UI
	return (
		<Stack className="reply-list-panel">
		{replyList.map((reply) => {        
			return (          
			<Stack className="reply-card" key={ reply.id} >
				<Stack className="comment-cotent-container">
				<AccountCircleOutlinedIcon className="writer-profile"/>
				<Stack className="comment-info">
					<Stack className="comment-writer-container">
					<Typography className="writer-name">{reply.writerInfo.displayName}</Typography>                  
					<Typography className="create-time">{utils.getDateString(reply.createTime)}</Typography>
					</Stack>
					<pre className="description">{reply.description}</pre>
				</Stack>                
				</Stack>
				<Stack className="action-container">
				<Button className="btn-write-reply" size="small" style={buttonFontStyle} onClick={ ()=>onReplyClick(reply)}>답글쓰기</Button>              
				<Box className="flex-grow"></Box>
				<PostVoteButtonGroup post={ reply } />
				</Stack>              
				{/*******************   Replay Input    ***********************/}
				<Stack className="reply-input-container" sx={{display : reply.isShowWriteReply ? "inherit" :"none"}}>
				<Stack className="comment-input-content">
					<AccountCircleOutlinedIcon className="user-icon" />          
					<TextField
						id={getReplyInputId(reply.id)}
						className="new-comment-input"
						multiline placeholder="답글쓰기"
						rows={3}
						fullWidth                    
					/>
				</Stack>
				<Box className="btn-comment-container">
					<Button
					className="btn-comment"
					onClick={() => onWriteReplyClick(reply)}
					size={buttonSize}
					style={buttonFontStyle} 
					>
					답글쓰기
					</Button>
				</Box>
				</Stack>
			</Stack>
			);
			
		})}
			
			
		<Drawer
			anchor="bottom"
			open={isOpenEditCommentDrawer}
			onClose={()=>setOpenEditCommentDrawer(false)}
			id="FlaboVilageCommentUpdatePlanel"
		>
				<Stack className="comment-update-panel">
				<Typography className="panel-title">답글수정</Typography>              
			<TextField multiline minRows={3} maxRows={10}  value={editCommentText} onChange={(e)=>setEditCommentText(e.target.value)} />
				<Stack className="action-button-container">
					<Button className="cancel-button" onClick={onCancelEditCommentClick} style={buttonFontStyle}  size={buttonSize}>취소</Button>
					<Button className="confirm-button" onClick={onEditCommentClick} style={buttonFontStyle}  size={buttonSize}>확인</Button>
				</Stack>
				</Stack>
		</Drawer>
		</Stack>
	);
}