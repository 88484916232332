/**************************************************************************************************
    FileName  : UserAPI.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  UserAPI Implementation                                       //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default class UserAPI {
    
}