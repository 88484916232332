/**************************************************************************************************
    ItemDBDetail : PostVoteButtonGroup.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for React
import { useState, useRef } from "react";
// Import for Material UI
import { Stack, Button } from "@mui/material";
// Import for Icons
import {
    ThumbUpOutlined as ThumbUpOutlinedIcon,
    ThumbDownOutlined as ThumbDownOutlinedIcon
} from '@mui/icons-material';
// Import for Project Types
import { EnumPostVote, PostListItemData } from "types";
// Import for Project Base
import { api, shsAssert } from "libs/stdlib";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                     Constants                                                 //
///////////////////////////////////////////////////////////////////////////////////////////////////
const DEBOUNCE_TIME_MS = 700;

const BUTTON_STYLE = {
    border: "1px solid #EBEBEB",
    borderRadius: "100px",    
};

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    Implementation                                             //
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function PostVoteButtonGroup({ post }: { post: PostListItemData }) {
    const theme = useTheme()
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const refMyVote = useRef<EnumPostVote>(post.myVote ? post.myVote : EnumPostVote.neutral);
    const [myVote, setMyVote] = useState<EnumPostVote>(post.myVote ? post.myVote : EnumPostVote.neutral);
    const lastClickTime = useRef<number>(0);   
    const lastMyVote = useRef<EnumPostVote>(post.myVote ? post.myVote : EnumPostVote.neutral);  
    const dynamicButtonStyle = isMdDown ? {
        minWidth: "56px",
        fontSize: "11px",
        "& svg": {fontSize:"14px !important"} 
    } : {};

     
    

    async function api_votePost(postId: number, vote: EnumPostVote) {        
        await api.post(
            `/api/posts/${postId}/vote`,
            {vote},
            ()=>{
                lastMyVote.current = vote;
            }
        )
    }
    

    function getUpVoteCount() {
        switch (post.myVote || EnumPostVote.neutral) {
            case EnumPostVote.upvote:
                if (myVote === EnumPostVote.upvote)
                    return post.upvoteCount;
                else 
                    return post.upvoteCount - 1;            
            case EnumPostVote.neutral:
                if (myVote === EnumPostVote.upvote)
                    return post.upvoteCount+1;
                else 
                    return post.upvoteCount;
            case  EnumPostVote.downvote:
                if (myVote === EnumPostVote.upvote)
                    return post.upvoteCount+1;
                else 
                    return post.upvoteCount;
            default:                
                shsAssert(false);
        }
    }

    function getDownVoteCount() {
        switch (post.myVote || EnumPostVote.neutral) {
            case EnumPostVote.upvote:
                if (myVote === EnumPostVote.downvote)
                    return post.downvoteCount + 1;
                else 
                    return post.downvoteCount;
            case EnumPostVote.neutral:
                if (myVote === EnumPostVote.downvote)
                    return post.downvoteCount+1;
                else 
                    return post.downvoteCount;
            case  EnumPostVote.downvote:
                if (myVote === EnumPostVote.downvote)
                    return post.downvoteCount;
                else 
                    return post.downvoteCount-1;
            default:
                shsAssert(false);
        }
    }


    function updateVoteWithDebounce() {
        lastClickTime.current = Date.now();        
        setTimeout(async () => {
            if (DEBOUNCE_TIME_MS <= Date.now() - lastClickTime.current) {                
                if (refMyVote.current !== lastMyVote.current) {
                    await api_votePost(post.id, refMyVote.current);
                }
            }
        }, DEBOUNCE_TIME_MS);
    }


    function onClickUpvote() {
        switch (myVote) {
            case EnumPostVote.upvote:
                refMyVote.current = EnumPostVote.neutral;
                setMyVote(refMyVote.current);                
                break;
            case EnumPostVote.neutral:                
                refMyVote.current = EnumPostVote.upvote;
                setMyVote(refMyVote.current);
                break;
            case EnumPostVote.downvote:
                refMyVote.current = EnumPostVote.upvote;
                setMyVote(refMyVote.current);
                break;
            default:
                shsAssert(false);
                break;
        }
        updateVoteWithDebounce();
    }

    function onClickDownvote() {
        switch (myVote) {
            case EnumPostVote.upvote:
                refMyVote.current = EnumPostVote.downvote;
                setMyVote(refMyVote.current);
                break;
            case EnumPostVote.neutral:
                refMyVote.current = EnumPostVote.downvote;
                setMyVote(refMyVote.current);
                break;
            case EnumPostVote.downvote:
                refMyVote.current = EnumPostVote.neutral;
                setMyVote(refMyVote.current);
                break;
            default:
                shsAssert(false);
                break;
        }
        updateVoteWithDebounce();
    }

    return (
        <Stack sx={{ flexDirection: "row" }}>
            <Button
                size="small"
                startIcon={<ThumbUpOutlinedIcon />}                
                sx={{ ...BUTTON_STYLE, ...dynamicButtonStyle }}
                onClick={onClickUpvote}
            >{ getUpVoteCount() }</Button>
            <Button
                size="small"
                startIcon={<ThumbDownOutlinedIcon />}                
                sx={{ ...BUTTON_STYLE, ...dynamicButtonStyle, marginLeft: isMdDown ? "8px" : "12px" }}
                onClick={onClickDownvote}
            >{ getDownVoteCount() }</Button>
        </Stack>
    );
}