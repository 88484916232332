/**************************************************************************************************
    FileName  : GroupSpaceThreadPage.tsx
    Description

    Update History 
      2023.06    BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./GroupSpaceThreadPage.scss";
// Import for React
import { useEffect, useState } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Tabs, Tab
} from "@mui/material";
// Import for Project Types
import { EnumOpenSpace, EnumGroupSpaceSpecialTabName } from "types";
// Import for Project Base
import { appNavigator, pathTracker } from "libs/stdlib";
import ThreadPostAndDetail from "pages/ThreadPostAndDetail";




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Types                                                //
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  SpaceHome Implementation                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface GroupSpaceThreadPageProps  {
    spaceName? : string;
}


function GroupSpaceThreadPage(props : GroupSpaceThreadPageProps) {
    /////////////////////////////////////////////////////////////////////////////
    // const
    const urlParams = useParams();
    let spaceName : string = EnumOpenSpace.spacehub;
    if( props.spaceName ) {
        spaceName = props.spaceName;
    } else if( urlParams.spaceName ) {
        spaceName = urlParams.spaceName;
    }
    const threadName = spaceName;
    const [activeTab, ] = useState<string>(spaceName);

    /////////////////////////////////////////////////////////////////////////////
    // API

    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        pathTracker.buildStart()
            .add(spaceName, ()=>appNavigator.goOpenSpaceHome(spaceName) )
            .build();
        //eslint-disable-next-line
    }, []);

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    function goGroupSpaceHome() {
        appNavigator.goGroupSpaceHome(spaceName);
    }


    ///////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="groupSpaceThreadPage-230619">                        
            <Tabs className="menu-panel" value={activeTab} >
                <Tab label="홈" value={EnumGroupSpaceSpecialTabName.home} onClick={goGroupSpaceHome}/>
                <Tab label="일반게시판" value={spaceName}/>
            </Tabs>
            
            <ThreadPostAndDetail threadName={threadName}/>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default GroupSpaceThreadPage;