/**************************************************************************************************
    FileName  : StorePage.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import for Css
import "./StorePage.scss";
// Import for React
import { useEffect, useState } from "react";
// Import for React Router
import { useParams } from "react-router-dom";
// Import for Material UI
import {
    Stack, Typography, Button, Grid, IconButton,
    Menu, MenuItem
} from "@mui/material";
// Import for Project Base
import { appNavigator, shsAssert, myInfo, api, appToast } from "libs/stdlib";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Diablo4Store } from "types/diablo4";
import PathTracker from "modules/PathTracker";
import { EnumOpenSpace, TradeInfo } from "types";
import D4ItemDatabase from "./D4ItemDatabase";

import TradeItem from "./components/TradeItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
function StorePage() {
    const urlParams = useParams();
    shsAssert(urlParams.storeName !== undefined);
    const storeName = urlParams.storeName!;
    
    const [storeInfo, setStoreInfo] = useState<Diablo4Store|null>(null);
    const [tradeList, setTradeList] = useState<TradeInfo[] | null>(null);
    const pathTracker = PathTracker.getInstance();
    const itemDatabase = D4ItemDatabase.getInstance();

    // for menu
    const [selectedContextMenuTrade, setSelectedContextMenuTrade]  = useState<TradeInfo | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const contextMenuCssOptions = {minWidth:"90px", justifyContent:"center"};

    
    /////////////////////////////////////////////////////////////////////////////
    // API
    async function api_getStoreInfo(storeName : string) {
        await api.get(`/api/diablo4/stores/${storeName}/home`, (data)=>{
            setStoreInfo(data.store);
            setTradeList(data.items);
        });
    }

    async function api_deleteTradeItem(storeName : string, tradeId : number) {
        await api.delete(`/api/diablo4/stores/${storeName}/trades/${tradeId}`, (data)=>{            
            const removedItemList = tradeList!.filter((trade)=>{
                return trade.tradeId !== tradeId;
            });
            setTradeList(removedItemList);
            appToast.success("아이템을 삭제하였습니다.");
        });
    }
    
    /////////////////////////////////////////////////////////////////////////////
    // Hooks
    useEffect(()=>{
        if( urlParams.storeName ) {
            (async ()=>{                
                await api_getStoreInfo(storeName); 
            } )();
        }

        pathTracker.buildStart()
            .add("다이블로4", ()=>{ appNavigator.goOpenSpaceHome(EnumOpenSpace.diablo4) })
            .add("내상점")
            .build();
        //eslint-disable-next-line
    },[]);

    if( !urlParams.storeName )
        return <></>;

    /////////////////////////////////////////////////////////////////////////////
    // Functions
    
    function onEditStoreClick() {        
        appNavigator.diablo4.goEditStore(storeName);
    }

    function onRegisterItemClick() {
        appNavigator.diablo4.goRegisterItem(storeName);
    }

    function onMoreButtonClick (event: React.MouseEvent<HTMLButtonElement>, trade : TradeInfo) {
        setAnchorEl(event.currentTarget);
        setSelectedContextMenuTrade(trade);
    };

    const handleCloseContextMenu = () => {
        setAnchorEl(null);
        setSelectedContextMenuTrade(null);
    };

    function onMenuTradeEditClick () {
        setAnchorEl(null);        
        shsAssert(selectedContextMenuTrade !== null);
        appNavigator.diablo4.goEditTrade(storeName, selectedContextMenuTrade!.tradeId );        
    }
    
    async function onMenuTradeDeleteClick () {
        shsAssert(selectedContextMenuTrade !== null);
        await api_deleteTradeItem(storeName, selectedContextMenuTrade!.tradeId);
    }

    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="storePage-230516">
            {storeInfo &&
                <Stack className="store-info-panel">
                    <Typography className="store-display-name" variant="h6">{storeInfo.displayName}</Typography>
                    {storeInfo.introduce && <Typography className="store-introduce">{storeInfo.introduce}</Typography>}

                    {myInfo && myInfo.getBadgeInfo().diablo4Store && myInfo.getBadgeInfo().diablo4Store.id === storeInfo.id && 
                        <IconButton className="edit-store-btn" onClick={onEditStoreClick}>
                            <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                    }
                </Stack>
            }
            <Button className="btn-register-item" variant="contained" onClick={onRegisterItemClick}>아이템 등록</Button>            
            <Grid container className="item-list" spacing={1} >
                {tradeList && tradeList.map((trade)=>{
                    const itemInfo = itemDatabase.getItem(trade.itemId);
                    console.info("tradeInfo>>>", trade);
                    console.info("itemInfo>>>", itemInfo);
                    return (
                        <Grid xs={12} md={6} lg={3} item key={trade.itemId} >
                            <Stack className="trade-container">

                                <TradeItem trade={trade}/>
                                <IconButton className="btn-more" onClick={(e)=>onMoreButtonClick(e, trade) }>
                                    <MoreVertIcon />
                                </IconButton>

                                <Stack className="action-panel">
                                    <Button variant="outlined">거래 요청</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    );
                })}
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={isMenuOpen}
                    onClose={handleCloseContextMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}                    
                >
                    <MenuItem onClick={onMenuTradeEditClick} sx={contextMenuCssOptions}>수정</MenuItem>
                    <MenuItem onClick={onMenuTradeDeleteClick} sx={contextMenuCssOptions}>삭제</MenuItem>          
                </Menu>
            </Grid>
        </Stack>
    );
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default StorePage;