import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
// import logger from 'redux-logger';

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
        if (process.env.REACT_APP_BUILD_MODE === 'production') {
            return getDefaultMiddleware();
        } else {
            // return getDefaultMiddleware().concat(logger);
            return getDefaultMiddleware();
        }
    }
});

export default store;