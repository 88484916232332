/**************************************************************************************************
    FileName  : TradeItem.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import "./TradeItem.scss";
import {
    Stack, Typography, Chip, Box
} from "@mui/material";
// Import for Project Base
import { utils } from "libs/stdlib";

import { TradeInfo } from "types";
import D4ItemDatabase from "../D4ItemDatabase";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Class Implementation                                         //
///////////////////////////////////////////////////////////////////////////////////////////////////
interface Diablo4TradeItemProps {
    trade : TradeInfo
}

function TradeItem(props : Diablo4TradeItemProps)  {
    /////////////////////////////////////////////////////////////////////////////
    // Const
    const trade = props.trade;
    const itemInfo = D4ItemDatabase.getInstance().getItem(trade.itemId);

    /////////////////////////////////////////////////////////////////////////////
    // UI
    return (
        <Stack id="trade-item-container-230528">
            <Stack className="item-info-panel">
                {trade.isHardcore && <Chip label={"하드코어"} size="small"/>}
                {trade.isLadder && <Chip label={"레더"} size="small"/>}
                <Chip label={itemInfo?.rarity} size="small"/>
                <Chip className="chip-equipment-type" label={itemInfo?.equipmentType} size="small"/>                                    
            </Stack>

            <Typography variant="h5">{itemInfo?.localName}</Typography>

            <Typography variant="body2" mt={1} color="text.secondary">아이템 옵션</Typography>
            <Stack className="item-option-list">
                {trade.itemOptions && trade.itemOptions.map((option, index)=>{
                    return (
                        <Stack className="option-container" key={index}>
                            <Typography>{option.optionIdTxt}</Typography>
                            <Typography className="value-label">{option.val}</Typography>
                        </Stack>
                    );
                })}
            </Stack>

            <Box className="flex-grow"/>
            <Typography variant="body2" mt={1} color="text.secondary">가격</Typography>
            <Stack className="price-info-panel">
                <Typography variant="h5">{utils.numberWithCommas(trade.priceAmount!)}</Typography>
                <Typography variant="body1" ml={1}>Gold</Typography>
            </Stack>
        </Stack>
    )
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////  
export default TradeItem;