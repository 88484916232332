/**************************************************************************************************
    FileName  : stdlib.ts
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import myInfo from "../modules/MyInfo";
import api, {HttpMethod, APIError, DOMAIN} from "./api";
import apiCommon from "./api/apiCommon";
import DataStorage from "modules/DataStorage";
import utils from "./utilsEx";
import define from "./define";
import config from "./config";
import appToast from "components/AppToast";
import appAlert, {EnumAlertIcon} from "components/AppAlert";
import appNavigator from "modules/AppNavigator";
import shsAssert from "./shsAssert"
import PathTracker from "modules/PathTracker";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Constants                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////
const dataStorage = DataStorage.getInstance();
const pathTracker = PathTracker.getInstance();


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Exports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
export {
    myInfo,
    api,
    HttpMethod,
    dataStorage,
    DOMAIN,
    apiCommon,
    utils,
    define,
    config,
    appToast,
    appAlert, EnumAlertIcon,
    appNavigator,
    shsAssert,
    pathTracker
}
export type { APIError}