/**************************************************************************************************
    FileName  : index.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.scss';
import rootRouter from "./routes/rootRouter";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
// for Toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFF',
    },    
  },
});



const router = createBrowserRouter(rootRouter);
const root = ReactDOM.createRoot(
  document.getElementById('spacehub') as HTMLElement
);

root.render(  
    <ThemeProvider theme={darkTheme}>
      <ReduxProvider store={store}>
        <RouterProvider router={router} />
        <ToastContainer/>
      </ReduxProvider>
    </ThemeProvider>
  
);

