/**************************************************************************************************
    FileName  : PathTracker.ts
    Description
      PathTracker Singleton Class

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import ReduxStore from 'redux/store';
import {PathTrackerData} from "types";
import {shsAssert} from "libs/stdlib";
import { updatePathTrackerData } from 'redux/actions';

export default class PathTracker {
    private static _instance : PathTracker | null = null;
    public static getInstance() : PathTracker {
        if( PathTracker._instance === null )
            PathTracker._instance = new PathTracker();
        return PathTracker._instance;
    }

    private _list : PathTrackerData[] = [];
    public buildStart() : PathTracker {
        this._list = [];
        shsAssert(PathTracker._instance !== null);
        return PathTracker._instance!;
    }

    public add(text : string, fnExecute? : ()=>void ) : PathTracker {
        this._list.push({
            text, execute : fnExecute
        });
        shsAssert(PathTracker._instance !== null);
        return PathTracker._instance!;
    }

    public build() {
        const textList : string[] = [];
        this._list.forEach((value)=>{
            textList.push(value.text);
        });
        ReduxStore.dispatch(updatePathTrackerData(textList));
    }

    public execute(index : number) {
        if( this._list[index].execute )
            this._list[index].execute!();
    }

}