/**************************************************************************************************
    FileName  : D4ItemDatabase.tsx
    Description

    Update History 
      2023.05     BGKim     Create
**************************************************************************************************/

///////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Imports                                              //
///////////////////////////////////////////////////////////////////////////////////////////////////
import { shsAssert, api } from 'libs/stdlib';
import {
    Diablo4Item, EnumDiablo4Rarity, EnumDiablo4EquipmentType, Diablo4ItemOption
} from "types";


///////////////////////////////////////////////////////////////////////////////////////////////////
//                                    D2ItemDatabase Class Implementation                        //
///////////////////////////////////////////////////////////////////////////////////////////////////
export interface D4ItemDatabaseFilter {
  isPopular?: boolean | null;  
  equipmentType? : EnumDiablo4EquipmentType | null;  
  equipmentTypes? : EnumDiablo4EquipmentType[] | null;
  keyword?: string;
  rarity?: EnumDiablo4Rarity;
  name?: string;
}

export interface D2CurrencyDatabaseFilter {  
  keyword?: string;
}

export default class D4ItemDatabase {
  private static _instance: D4ItemDatabase | null = null;
  public static getInstance(): D4ItemDatabase {
    if (D4ItemDatabase._instance === null) {
      D4ItemDatabase._instance = new D4ItemDatabase();
    }
    return D4ItemDatabase._instance;
  }

  private _items: Diablo4Item[] = [];
  private _itemOptions: Diablo4ItemOption[] = [];
  private _isInitComplete = false;

  private _compareName(item1: Diablo4Item, item2: Diablo4Item) {
    if (!item1.localName || !item2.localName) return -1;
    return item1.localName.localeCompare(item2.localName);
  }

  private async api_getDiablo4ItemsInfo() : Promise<Diablo4Item[]> {
    return await api.get(
      `/api/diablo4/items`,
      (items: Diablo4Item[]) => {
        // console.info("items>>", items);
        for (const item of items) {
          item.localName = item.name;
        }
        items.sort(this._compareName);        
        return items;
      }
    );
  }


  private async api_getDiablo4OptionsInfo(): Promise<Diablo4ItemOption[]> {
    return api.get(
      '/api/diablo4/item-options',
      (options: Diablo4ItemOption[]) => {
        // console.info("options>>", options);
        for (const option of options) {
        	option.label = option.optionIdTxt;
          	option.val = "";
        }
        return options;
      }
    );
  }


  private _wait(resolve: (value: unknown) => void) {
    const that = this; // eslint-disable-line    
    setTimeout(() => {
      if (that._isInitComplete === true) {            
        resolve({});
      } else {
        that._wait(resolve);
      }
    }, 10);
  }

  public async waitInitialize() {
    if (this._isInitComplete === false) {
      const that = this; // eslint-disable-line      
      await new Promise((resolve) => {
        that._wait(resolve);
      });
    }
  }

  constructor() {
    (async () => {
      this._items = await this.api_getDiablo4ItemsInfo();
      this._itemOptions = await this.api_getDiablo4OptionsInfo();
      this._isInitComplete = true;
    })();

    const that = this; // eslint-disable-line    
  }

  public getItem(itemId: number): Diablo4Item | null {
    for (const item of this._items) {
      if (item.itemId === itemId)
        return item;
    }
    return null;
  }

  public filter(filter?: D4ItemDatabaseFilter): Diablo4Item[] {
    if (filter === undefined)
      return this._items;
    
    
    let filteredItems: Diablo4Item[] = this._items;
    if (filter.name) {
      for (const item of filteredItems) {
        if (item.name === filter.name)
          return [item];
      }
    }
        
    if (filter.equipmentType) {
      filteredItems = filteredItems.filter((item) => {
        return item.equipmentType === filter.equipmentType;
      });
    }
    
    if (filter.equipmentTypes) {
      filteredItems = filteredItems.filter((item) => {
        // TODO type check
        // eslint-disable-next-line
        for (const equipmentType of filter.equipmentTypes!) {
          if (equipmentType === item.equipmentType)
            return item.equipmentType;
        }
        return false;
      });
    }


    if (filter.rarity) {
      filteredItems = filteredItems.filter((item) => {
        return item.rarity === filter.rarity;
      });
    }

    if (filter.keyword && 0 < filter.keyword.length) {
      filteredItems = filteredItems.filter((item) => {
        shsAssert(item.localName !== undefined);
        shsAssert(filter.keyword !== undefined);
        // TODO type check
        // eslint-disable-next-line
        return 0 <= item.localName!.toLowerCase().indexOf(filter.keyword!.toLowerCase()) ;
      });
      
    }

    // sort by name        
    filteredItems.sort(this._compareName);
    // console.info("sort by name    >>>", filteredItems);
    return filteredItems;
  }

  public getItemId(rarity : EnumDiablo4Rarity, equipmentType : EnumDiablo4EquipmentType) : number {
    for(let i = 0;    i < this._items.length;   ++i ) {
      if( this._items[i].rarity === rarity && this._items[i].equipmentType === equipmentType )
        return this._items[i].itemId;
    }
    return -1;
  }


  public getItemOptions() {
    return this._itemOptions;
  }

  
  public getItemOption(optionId: number): Diablo4ItemOption | null {
    for (const item of this._itemOptions) {
      if (item.optionId === optionId)
        return item;
    }
    return null;
  }

  public filterItemOptions(keyword?: string): Diablo4ItemOption[] {
    if (keyword === undefined) {
      return this._itemOptions;
    }

    let filteredOptions: Diablo4ItemOption[] = this._itemOptions;

    if (keyword && 0 < keyword.length) {
      filteredOptions = filteredOptions.filter(item => {
        shsAssert(item.label !== undefined);
        shsAssert(keyword !== undefined);
        // TODO type check
        // eslint-disable-next-line
        return 0 <= item.label!.toLowerCase().indexOf(keyword!.toLowerCase()) ;
      });
    }

    return filteredOptions;
  }

  // 클라이언트에서 아이템, 화폐, 옵션에 대한 정보가 절대적으로 필요한 경우
  // 반환값이 null일때는 오류이므로 fassert를 사용하고 특정 정보를 절대적으로 반환한다.
  public getItemAbsolutely(itemId: number): Diablo4Item {
    const data = this.getItem(itemId);
    shsAssert(data !== null);
    if (data === null) throw new Error('invalid diablo2 item id');
    return data;
  }
    
  public getItemOptionAbsolutely(optionId: number): Diablo4ItemOption {
    const data = this.getItemOption(optionId);
    shsAssert(data !== null);
    if (data === null) throw new Error('invalid diablo2 option id');
    return data;
  }
}


